import { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import HttpHelper from '../../helpers/HttpHelper';
import PageLayout from '../../components/pageLayout/pageLayout';
import AccountTeam from './accountTeam';
import MyTeam from './myTeam';
//import ShowError from './error/ShowError';
import Loader from '../../components/common/Loader';
import { Reset } from '../../store/slices/userSlice';
//import { useErrorBoundary } from 'react-error-boundary';
//import { SnackBarMsgType } from '../../helpers/constants';
//import Snackbar from '../../components/common/snackBar/SnackBar';
//import SnackbarContext from '../../components/common/snack';
import './style.scss';

const get = require('get-value');

const ClientTeam = props => {
	//const snackbarCtx = useContext(SnackbarContext);
	//const { showBoundary } = useErrorBoundary();
	//console.log('props.userSelectedlanguage.toLowerCase()', props.userSelectedlanguage.toLowerCase());
	const [accountTeamData, setAccountTeamData] = useState(null),
		[clientTeamData, setClientTeamData] = useState(null),
		[pageData, setPageData] = useState(null),
		payload = {
			categoryname: 'Default',
			pagename: 'ClientTeam',
			language: props.userSelectedlanguage.toLowerCase() || 'en'
		},
		http = new HttpHelper();
	//const [error, setError] = useState(null);

	let myTeamPageData;

	useEffect(() => {
		document.title = 'Teams';
		getPageData().catch(error => {
			if (error.response.status != 200) {
				//showBoundary(error);
				//snackbarCtx && snackbarCtx.displayMsg('Something went wrong ', SnackBarMsgType.Error);
			}
		});

		getClientTeamData().catch(error => {
			if (error.response.status != 200) {
				//showBoundary(error);
				//snackbarCtx && snackbarCtx.displayMsg('Something went wrong ', SnackBarMsgType.Error);
			}
		});
	}, []);

	useEffect(() => {
		getAccountTeamData().catch(error => {
			if (error.response.status != 200) {
				//showBoundary(error);
				//snackbarCtx && snackbarCtx.displayMsg('Something went wrong ', SnackBarMsgType.Error);
			}
		});
	}, [props.userSelectedlanguage]);

	useEffect(() => {
		if (props.isLangChanged) {
			getPageData();
			props.reset();
		}
	}, [props.isLangChanged, props.userSelectedlanguage]);

	const getPageData = async () => {
		const { data } = await http.CMSpost('PageApi/GetPageDetails', payload);
		setPageData(data.Pages[0]);
	};

	const getAccountTeamData = async () => {
		let userId = window.sessionStorage.getItem('UserId');
		const userSelectedlanguage = props.userSelectedlanguage || 'en';
		const { data } = await http.Get('User-Profile/account/' + userId + '/' + userSelectedlanguage + '/team');
		setAccountTeamData(data);
	};

	const getClientTeamData = async () => {
		let userId = window.sessionStorage.getItem('UserId');
		//try {
		const { data } = await http.Get('User-Profile/client/' + userId + '/team');
		setClientTeamData(sortClientTeamData(data));
		//} catch (err) {
		//if (err) setError(err.message);
		//}
	};

	const sortClientTeamData = clientTeamData => {
		const activeMembers = [],
			deletedMembers = [];

		clientTeamData?.forEach(element => {
			if (['Inactive', 'Removed', 'Pending'].includes(element.StatusTypeDescription)) {
				deletedMembers.push(element);
			} else {
				activeMembers.push(element);
			}
		});

		return [...activeMembers, ...deletedMembers];
	};

	const accountTeamPageData = useMemo(
		() =>
			pageData
				? {
						pageDataAccountTeam: get(pageData, 'Components.ClientTeam.AccountTeam'),
						pageDataMeeting: get(pageData, 'Components.ClientTeam.Meeting'),
						common: get(pageData, 'Components.Common.Component')
				  }
				: null,
		[pageData]
	);

	if (pageData) {
		myTeamPageData = {
			myTeam: get(pageData, 'Components.ClientTeam.MyTeam'),
			manageUser: get(pageData, 'Components.ClientTeam.ManageUser'),
			common: get(pageData, 'Components.Common.Component')
		};
	}
	// if (error) return <ShowError error={error} />;
	return (
		<>
			{pageData ? (
				<PageLayout containerClass="client-team" bannerTitle={pageData?.Title} data={pageData}>
					<div className="container-fluid container-lg client-team__container p-md-1 vs-comp">
						<div className="tabs p-0 p-sm-0 p-md-2 p-lg-1">
							<ul className="nav nav-tabs" id="myTab" role="tablist">
								<li className="nav-item" role="presentation">
									<button
										className="nav-link active"
										id="home-tab"
										data-bs-toggle="tab"
										data-bs-target="#home"
										type="button"
										role="tab"
										aria-controls="home"
										aria-selected="true">
										{accountTeamPageData?.pageDataAccountTeam?.Title}
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button
										className="nav-link"
										id="profile-tab"
										data-bs-toggle="tab"
										data-bs-target="#profile"
										type="button"
										role="tab"
										aria-controls="profile"
										aria-selected="false">
										{myTeamPageData?.myTeam?.Title}
									</button>
								</li>
							</ul>
							<div className="tab-content teamsTab" id="myTabContent">
								<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
									<AccountTeam accountTeamData={accountTeamData} accountTeamPageData={accountTeamPageData} />
								</div>
								<div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
									<MyTeam
										clientTeamData={clientTeamData}
										myTeamPageData={myTeamPageData}
										getClientTeamData={getClientTeamData}
									/>
								</div>
							</div>
						</div>
					</div>
				</PageLayout>
			) : (
				<Loader />
			)}
			{/* {snackbarCtx && snackbarCtx.isDisplayed && <Snackbar />} */}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientTeam);
