import { createSlice } from '@reduxjs/toolkit';
import { NotificationActions } from '../../components/common/Notification/NotificationActions';
import { defaultNotificationCount } from '../../helpers/constants';
const initialState = {
	notificationList: undefined,
	isLoading: false,
	isRefreshToken: false,
	error: undefined,
	unreadNotificationsCount: 0,
	notificationsCount: 0
};

//reducers
const notificationSlice = createSlice({
	name: 'notificationSlice',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setNotificationList: (state, action) => {
			state.notificationList = action.payload;
			state.isLoading = false;
			state.error = undefined;
		},
		setUnreadNotificationCount: (state, action) => {
			state.unreadNotificationsCount = action.payload;
			state.isLoading = false;
			state.error = undefined;
		},
		setNotificationCount: (state, action) => {
			state.notificationsCount = action.payload;
			state.isLoading = false;
			state.error = undefined;
		},
		reset: (state, action) => {
			state.isLangChanged = action.payload;
		}
	}
});

const { reducer, actions } = notificationSlice;

const { setNotificationList, setUnreadNotificationCount, setNotificationCount, reset } = actions;
export default reducer;

//actions
export const GetAllNotifications = payload => async dispatch => {
	try {
		await NotificationActions.GetNotificationList(payload)
			.then(res => {
				if (res?.status == 200) {
					dispatch(setNotificationList(res.data.NotificationDetails));
					dispatch(setUnreadNotificationCount(res.data.UnreadNotificationsCount));
					dispatch(setNotificationCount(res.data.NotificationsCount));
				}
			})
			.catch(err => {
				return err;
			});
	} catch (error) {
		dispatch(setNotificationList(error));
	}
};

export const MarkAllAsRead = payload => async dispatch => {
	try {
		await NotificationActions.MarkAllasRead(JSON.stringify(payload))
			.then(res => {
				if (res) {
					let data = {};
					data.offset = 1;
					data.limit = defaultNotificationCount;
					data.locale = localStorage.getItem('userLanguage') || 'en';
					dispatch(GetAllNotifications(data));
				}
			})
			.catch(err => {
				return err;
			});
	} catch (error) {
		dispatch(setNotificationList(error));
	}
};

export const Reset = () => dispatch => {
	dispatch(reset(false));
};
