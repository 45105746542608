import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginHeader.scss';
import { connect } from 'react-redux';
import HttpHelper from '../../helpers/HttpHelper';
import { pageUrls } from '../../helpers/constants';
import { SwitchLanguage, GetHeader } from '../../store/slices/userSlice';
//import Image from '../common/image';
const get = require('get-value');

const LoginHeader = props => {
	const shouldLog = useRef(true);
	const navigate = useNavigate();
	const http = new HttpHelper();
	const [languages, setLanguages] = useState('');
	const [displayLang, setDisplayLang] = useState('');
	const [headerLogo, setHeaderLogo] = useState(null);
	const [defaultLogo, setDefaultLogo] = useState(null);
	const getData = async () => {
		//console.log('headerPageContent', headerPageContent);
		const { Components } = props.headerPageContent.Pages[0];
		const languages = get(Components, 'Header.Language.LabelWithTitle');
		const imageData = {
			smallImage: get(props.headerPageContent.Pages[0], 'SmallImage'),
			mediumImage: get(props.headerPageContent.Pages[0], 'MediumImage'),
			largeImage: get(props.headerPageContent.Pages[0], 'LargeImage')
		};
		//setHeaderLogo(imageData);
		setDefaultLogo(imageData);
		setLanguages(languages);
		if (languages?.length > 0) {
			let filteredLang = languages.filter(lang => lang.Title === props.userSelectedlanguage.toUpperCase());
			setDisplayLang(filteredLang[0]['Phrase']);
		}
	};

	const getLogo = async () => {
		let userId = window.sessionStorage.getItem('UserId');
		await http
			.Get('User-Profile/client/' + userId + '/logo')
			.then(res => {
				setHeaderLogo(res.data.Logo);
			})
			.catch(error => {
				return error;
			});

		//const link = document.querySelector("link[rel*='icon']");
		//link.href = data.Logo;
	};

	const userLanguageHandler = (phrase, title) => {
		setDisplayLang(phrase);
		localStorage.setItem('userLanguage', title);
		props.switchLanguage(title.toLowerCase());
	};

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;
			props.getHeader(props.userSelectedlanguage);
		} else if (props.headerPageContent) {
			getData();
			getLogo();
		}
	}, [props.headerPageContent]);

	return (
		<>
			<div className="loginHeader">
				<div className="row p-sm-3 p-md-2 p-lg-4 px-xl-5">
					<div className="col-6 col-lg-8">
						{headerLogo ? (
							<div
								role="button"
								className="logo_img-container "
								onClick={() => navigate(pageUrls[0]['Header.Home'])}
								onKeyDown={e => {
									if (e.key === 'Enter') navigate(pageUrls[0]['Header.Home']);
								}}
								tabIndex={0}
								aria-label="Logo">
								<img src={headerLogo} alt="Logo" className="img-fluid fromapi" />
							</div>
						) : (
							<div
								role="button"
								className="logo_img-container"
								onClick={() => navigate(pageUrls[0]['Header.Home'])}
								onKeyDown={e => {
									if (e.key === 'Enter') navigate(pageUrls[0]['Header.Home']);
								}}
								tabIndex={0}
								aria-label="Logo">
								<img
									src={defaultLogo?.mediumImage?.ImageUrl}
									alt="Logo"
									className="d-inline-block align-text-top img-fluid defaultLogo"
								/>
							</div>
						)}
					</div>
					{props.backButton && (
						<div className="col-6 col-lg-4 mt-2">
							<div className="m-1 m-md-3 text-end">
								<button
									className="btn btn-secondary rounded-pill logbackbtn"
									type="button"
									id="backButton"
									onClick={() => navigate(-1)}>
									Back
								</button>
							</div>
						</div>
					)}
					{languages && !props.backButton && (
						<div className="col-6 col-lg-4">
							<div className="dropdown dalign mt-2">
								<button
									className="btn btn-secondary dropdown-toggle rounded-pill langDrop"
									type="button"
									id="dropdownMenuButton1"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									{displayLang}
								</button>
								<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
									{/* {Object.values(languages).map((language, index) => (
										<li key={index}>
											<span
												onClick={() =>
													userLanguageHandler(language.LanguageDescription, language.LanguageCode.toLowerCase())
												}
												className="dropdown-item">
												{language.LanguageDescription}
											</span>
										</li>
									))} */}
									{Object.values(languages).map((language, index) => (
										<li key={index}>
											<span
												onClick={() => userLanguageHandler(language.Phrase, language.Title.toLowerCase())}
												className="dropdown-item">
												{language.Phrase}
											</span>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		headerPageContent: state.userSlice.headerPageContent
	};
};

const mapDispatchToProps = dispatch => {
	return {
		switchLanguage: shortLang => dispatch(SwitchLanguage(shortLang)),
		getHeader: shortLang => dispatch(GetHeader(shortLang))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginHeader);
