//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import axios from 'axios';
import Interceptor from './Interceptor';
import { handleError } from './constants';
//import config from '../config/config.json';
//import { createBrowserHistory } from "history";
//import { returnCountryLanguagePersona } from './CountryLanguage';

class HttpHelper {
	constructor() {
		this.ApiHostUrl = process.env.REACT_APP_API_URL;
		this.CMSApiHostUrl = process.env.REACT_APP_CMS_API_URL;
		this.CMSapiKey = process.env.REACT_APP_CMS_API_KEY;
	}

	async CMSpost(url, data = {}) {
		//console.log('ENV TEST', process.env.REACT_APP_API_URL);
		//let genericData = returnCountryLanguagePersona();
		//let datas = { ...data, ...genericData };
		let datas = data;

		let res = axios.post(this.CMSApiHostUrl + url, datas, {
			headers: {
				sc_apikey: this.CMSapiKey,
				'Content-Type': 'application/json'
			}
		});
		res.catch(err => {
			handleError(err);
			return err;
		});
		return res;
	}

	async Get(url, data = {}) {
		// let accessToken = sessionStorage.getItem('AccessToken');
		// //console.log('accessToken', data);
		// let res = axios.get(this.ApiHostUrl + url, data, {
		// 	responseType: 'blob',
		// 	headers: {
		// 		Authorization: `Bearer ${accessToken}`,
		// 		'Content-Type': 'application/json'
		// 	}
		// });
		// res.catch(err => {
		// 	return err;
		// });
		// return res;
		let _instance = new Interceptor().getInstanse();
		return _instance.get(this.ApiHostUrl + url, data);
		//const headers = { Authorization: `Bearer ${accessToken}` };
		//axios.get(this.ApiHostUrl + url, { headers }).then(response => console.log(response));
	}

	async put(url, data = {}) {
		let _instance = new Interceptor().getInstanse();
		return _instance.put(this.ApiHostUrl + url, data);
	}

	async post(url, data = {}, dataContentType) {
		let accessToken = sessionStorage.getItem('AccessToken');
		const contentType = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'content-type': `${dataContentType ? dataContentType : 'multipart/form-data'}`,
				TokenType: 'ADB2C'
			}
		};
		let res = axios.post(this.ApiHostUrl + url, data, contentType);
		res.catch(err => {
			//console.log('ERROR aa', err.message);
			handleError(err);
			return err.message;
		});
		return res;
	}

	async delete(url, data = {}) {
		let _instance = new Interceptor().getInstanse();
		return _instance.delete(this.ApiHostUrl + url, data);
	}

	async documentsDownloadGet(url) {
		let accessToken = window.sessionStorage.getItem('AccessToken');
		let res = axios.get(this.ApiHostUrl + url, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'multipart/form-data',
				TokenType: 'ADB2C'
			}
		});

		res.catch(err => {
			handleError(err);
			return err;
		});

		return res;
	}

	async templateDownloadGet(url) {
		let accessToken = window.sessionStorage.getItem('AccessToken');
		let res = axios.get(url, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'multipart/form-data',
				TokenType: 'ADB2C'
			}
		});
		res.catch(err => {
			handleError(err);
			return err;
		});
		return res;
	}
}

export default HttpHelper;
