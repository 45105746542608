import React from 'react';
import { useEffect, useState } from 'react';
import htmlParser from 'html-react-parser';
import { FooterActions } from '../FooterActions';
import Loader from '../../common/Loader';
import Footer from '../footer';
import './policyPrivacy.scss';
import Header from '../header/Header';

const PolicyPrivacy = () => {
	const [data, setData] = useState();
	const [screenLoader, setScreenLoader] = useState(true);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const languagecode = params.get('languagecode');

	useEffect(() => {
		document.title = 'Privacy Policy';
		window.scrollTo(0, 0);
		getPolicyData();
	}, []);

	let auth = {
		token: sessionStorage.getItem('setIdTokenClaims') ? true : false
	};

	const getPolicyData = async () => {
		await FooterActions.GetCookieNPolicies({
			categoryname: 'Default',
			pagename: 'PrivacyPolicy',
			language: localStorage.getItem('userLanguage') || languagecode || 'en'
		}).then(
			res => {
				if (res.status == 200) {
					setData(res?.data?.Pages[0]);
					setScreenLoader(false);
				} else {
					setScreenLoader(false);
				}
			} //,
			// err => {
			// 	console.log(err);
			// 	setScreenLoader(false);
			// }
		);
	};

	return (
		<>
			<div className="ourPolicy privacy-policy">
				{screenLoader === true ? (
					<Loader />
				) : (
					<>
						<Header />
						<div className="privacy-content">
							<h3>{data && data?.Title}</h3>
							{htmlParser(data ? data?.Description : '')}
						</div>
					</>
				)}
				{auth.token ? <Footer isLoggedIn={true} /> : <Footer isLoggedIn={false} />}
			</div>
		</>
	);
};

export default PolicyPrivacy;
