import Button from '../../common/button';
import { useMsal } from '@azure/msal-react';
const get = require('get-value');

const MyAccountSummary = ({ data }) => {
	const { myAccount } = data;
	const labels = {};
	get(myAccount, 'Components.MyAccount.AccountDetails.Labels').forEach(ele => {
		labels[ele.Key] = ele.Phrase;
	});

	const { instance } = useMsal();
	const logOut = () => {
		localStorage.removeItem('adminMessages');
		localStorage.removeItem('userLanguage');
		localStorage.clear();
		sessionStorage.clear();
		instance.logoutRedirect({
			postLogoutRedirectUri: '/'
		});
	};
	return (
		<div className="container-fluid my-account__summary">
			<div className="container-fluid container-lg p-lg-1 p-lg-1 p-xl-0">
				<div className="row">
					<div className="col-12 col-md-9">
						<h3 className="my-account__summary-title">{myAccount?.Description}</h3>
						<p className="my-account__summary-desc mt-3">
							{get(myAccount, 'Components.MyAccount.AccountDetails.Description')}
						</p>
					</div>
					<div className="col-12 col-md-3 text-md-end">
						<Button buttonClass="button--black" icon="logout" onClick={() => logOut()}>
							{labels['AccountDetails.Logout']}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyAccountSummary;
