import { useId, forwardRef } from 'react';
import Label from '../label';

import './style.scss';

const TextArea = ({ labelText, ...restProps }, ref) => {
	const inputID = useId();

	return (
		<div className="text-area__container">
			{labelText && <Label htmlFor={`text-area${inputID}`}>{labelText}</Label>}
			<textarea
				className="text-area form-control"
				id={`text-area${inputID}`}
				ref={ref}
				rows="4"
				{...restProps}></textarea>
		</div>
	);
};

export default forwardRef(TextArea);
