import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HttpHelper from '../../helpers/HttpHelper';

import PageLayout from '../../components/pageLayout/pageLayout';
import HomeDashboard from '../../components/home/homeDashboard';
import AdminMessage from '../../components/home/adminMessage';
//import GetStarted from '../../components/home/getStarted';
import environment from '../../components/common/environments/environments';
import RecentReports from '../../components/home/recentReports';
import YourReferrals from '../../components/home/yourReferrals';
import Loader from '../../components/common/Loader';
import { Reset } from '../../store/slices/userSlice';
import { reportsNotAllowed, referralsNotAllowed } from '../../helpers/constants';

import './home.scss';

const Home = props => {
	const [data, setData] = useState(null);
	const [refCmsData, setRefCmsData] = useState({});
	const http = new HttpHelper();
	const [permissionCode, setPermissionCode] = useState('');
	const [messages, setMessages] = useState([]);
	const [groupHomeConfig, setGroupHomeConfig] = useState(null);
	const [groupAConfig, setGroupAConfig] = useState(null);
	const [groupBConfig, setGroupBConfig] = useState(null);

	const getData = async () => {
		console.log('User Selected Language Home::', props?.userSelectedlanguage); // eslint-disable-line
		let payload = {
			categoryname: 'Default',
			pagename: 'Home',
			language: props?.userSelectedlanguage?.toLowerCase() || 'en'
		};
		let { data } = await http.CMSpost('PageApi/GetPageDetails', payload);
		setData(data.Pages[0]);
	};

	const getReferralCmsData = async () => {
		let payload = {
			categoryname: 'Default',
			pagename: 'Referrals',
			language: props?.userSelectedlanguage?.toLowerCase() || 'en'
		};
		await http
			.CMSpost('PageApi/GetPageDetails', payload)
			.then(res => {
				const { data } = res;
				if (data) {
					data?.Pages?.map(x => {
						if (x.Key == 'CandidateReferrals') {
							let statusList = {};
							x.Components?.['Referrals.SubmitttedReferralsList']?.LOVList[0]?.Values.map(y => {
								statusList[y.Key] = y.Value;
							});
							setRefCmsData(statusList);
						}
					});
				} else {
					return [];
				}
			})
			.catch(error => {
				console.log('Referral Api Error::HomePage', error); // eslint-disable-line
				return error;
			});
	};

	useEffect(() => {
		//console.log('FROM HOME PAGE PROCESS', process.env); // eslint-disable-line
		//console.log('FROM HOME PAGE REACT_APP_API_URL:', process.env.REACT_APP_API_URL); // eslint-disable-line
		document.title = 'Home';
		const tokenData = JSON.parse(sessionStorage.getItem('setIdTokenClaims'));
		// console.log(tokenData.PermissionCode);
		setPermissionCode(tokenData.PermissionCode);
	}, []);

	useEffect(() => {
		getData();
		getReferralCmsData();
		getDashboardConfig();
		props.reset();
		const date = new Date();
		const utcDate =
			date.getUTCFullYear() +
			'-' +
			(date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1) +
			'-' +
			(date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate());
		const utcTime =
			(date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours()) +
			':' +
			(date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes()) +
			':' +
			(date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds());

		const UserDateTime = utcDate + ' ' + utcTime;
		const TimeZoneIdentifier = getUserTimeZoneID();
		const adminMessages = localStorage.getItem('adminMessages');
		if (adminMessages) {
			const storedMessages = JSON.parse(adminMessages);
			setMessages(storedMessages);
		} else {
			let userId = window.sessionStorage.getItem('UserId');
			const userSelectedlanguage = localStorage.getItem('userLanguage');
			makeAdminMessageRequest({
				UserId: userId,
				Language: userSelectedlanguage ? userSelectedlanguage : 'en',
				//UserDateTime: '2023-11-07 21:39:13',
				UserDateTime,
				TimeZoneIdentifier
			});
		}
	}, [props.userSelectedlanguage]);

	function getUserTimeZoneID() {
		var timezone = String(new Date());
		return timezone
			.substring(timezone.lastIndexOf('(') + 1)
			.replace(')', '')
			.trim();
	}

	const makeAdminMessageRequest = async payload => {
		await http
			.post('User-Profile/client/clientAdminMessage', payload, 'application/json')
			.then(res => {
				if (res.data) {
					const dataString = JSON.stringify(res.data);
					localStorage.setItem('adminMessages', dataString);
					setMessages(res.data);
				}
			})
			.catch(error => {
				return error;
			});
	};

	const getDashboardConfig = async () => {
		let payload = {};
		await http
			.Get(environment.DASHBOARD_CONFIG + '/' + environment.BRAND_ID + '/dashboardconfig', payload)
			.then(res => {
				//console.log('res', res.data);
				if (res.data) {
					let groupHomeData = [];
					let groupAData = [];
					let groupBData = [];
					groupHomeData['GROUPHOME'] = environment.HOME_REPORTID;
					res.data.map(configData => {
						if (configData.Name.includes('GROUPHOME')) {
							groupHomeData[configData.Name] = configData.TypeId;
						} else if (configData.Name.includes('GROUPA')) {
							groupAData[configData.Name] = configData.TypeId;
						} else if (configData.Name.includes('GROUPB')) {
							groupBData[configData.Name] = configData.TypeId;
						} else if (configData.Name.includes('WORKSPACE')) {
							groupHomeData['WORKSPACE_ID'] = configData.TypeId;
							groupAData['WORKSPACE_ID'] = configData.TypeId;
							groupBData['WORKSPACE_ID'] = configData.TypeId;
						}
					});
					//console.log('groupHomeData', groupHomeData);
					setGroupHomeConfig(groupHomeData);
					setGroupAConfig(groupAData);
					setGroupBConfig(groupBData);
				}
			})
			.catch(error => {
				return error;
			});
	};

	return (
		<>
			{data ? (
				<PageLayout containerClass="home" bannerTitle={data?.Title} data={data}>
					{messages && messages.length > 0 && <AdminMessage messages={messages} />}
					{groupHomeConfig && groupAConfig && groupBConfig && (
						<HomeDashboard
							data={data}
							permissionCode={permissionCode}
							groupHomeConfig={groupHomeConfig}
							groupAConfig={groupAConfig}
							groupBConfig={groupBConfig}
						/>
					)}
					<div className="container-fluid home__reports vs-comp">
						<div className="container-fluid-md container-lg g-0 g-md-2">
							<div className="row">
								{props?.userSelectedlanguage &&
									props?.userSelectedlanguage != undefined &&
									!reportsNotAllowed.includes(permissionCode) && (
										<div className="col-12 col-md-12 col-lg-8">
											<RecentReports data={data} userSelectedlanguage={props?.userSelectedlanguage} />
										</div>
									)}
								{!referralsNotAllowed.includes(permissionCode) && (
									<div
										className={
											!reportsNotAllowed.includes(permissionCode)
												? `col-12 col-md-12 col-lg-4 home__referrals-section`
												: `col-12 col-lg-12 home__referrals-section`
										}>
										{data && <YourReferrals data={data} refCmsData={refCmsData} />}
									</div>
								)}
							</div>
						</div>
					</div>
					{/* <GetStarted data={data} /> */}
					<div>
						<a
							href="//privacy.truste.com/privacy-seal/validation?rid=e835f694-01d1-4f5a-8afc-92a721acff1d"
							target="_blank"
							rel="noreferrer">
							<img
								style={{ border: 'none' }}
								src="//privacy-policy.truste.com/privacy-seal/seal?rid=e835f694-01d1-4f5a-8afc-92a721acff1d"
								alt="TRUSTe"
							/>
						</a>
					</div>
				</PageLayout>
			) : (
				<Loader />
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
