//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../helpers/HttpHelper';
import environments from '../common/environments/environments'; // eslint-disable-line
const http = new httpHelper(); // eslint-disable-line

export const ReportsActions = {
	GetCMSRequestPageDetails: getCMSRequestPageDetails,
	GetReportsList: getReportsList
};

function getCMSRequestPageDetails(payload) {
	return http.CMSpost(environments.REFERRAL_PAGE_API_CMS, payload);
}

function getReportsList(payload) {
	let userId = window.sessionStorage.getItem('UserId');
	return http.Get(
		environments.REPORTS_LIST_API +
			'?UserId=' +
			userId +
			'&BrandId=' +
			environments.BRAND_ID +
			'&PageNumber=' +
			payload.PageNumber +
			'&PageSize=' +
			payload.PageSize,
		payload
	);
}
