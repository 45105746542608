import './adminMessage.scss';
const AdminMessage = ({ messages }) => {
	return (
		<div className="container message-container">
			{messages.map(message => (
				<div className="alert alert-secondary alert-dismissible custom" key={message.MessageContent}>
					<strong>{message.MessageHeader} - </strong> {message.MessageContent}
					<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
				</div>
			))}
		</div>
	);
};
export default AdminMessage;
