//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import jwt_decode from 'jwt-decode';
import useAutoLogout from '../components/common/IdleTimer';

const PrivateRoutes = () => {
	const timer = useAutoLogout(1800); //30 mins
	const { instance, accounts, inProgress } = useMsal();

	useEffect(() => {
		console.log('IdleTimer::', timer); //eslint-disable-line
		if (timer == 0) {
			logOut();
		}
	}, [timer]);

	let auth = {
		token: sessionStorage.getItem('AccessToken') ? true : false
	};
	let accessToken = window.sessionStorage.getItem('AccessToken');
	let refreshTokenTimer; //eslint-disable-line
	const isAuthenticated = useIsAuthenticated();
	let redirectCheck = sessionStorage.getItem('REDIRECTTO');
	useEffect(() => {
		//console.log('CHECK 222', sessionStorage.getItem('REDIRECTTO')); //eslint-disable-line
		if (redirectCheck === 'FORGOT') {
			//console.log('CHECK 444'); //eslint-disable-line
			sessionStorage.setItem('REDIRECTTO', 'RESET');
		}
		if (redirectCheck === 'RESET') {
			//console.log('CHECK 555', inProgress); //eslint-disable-line
			if (accounts.length > 0 && isAuthenticated && inProgress === InteractionStatus.None) {
				sessionStorage.setItem('REDIRECTTO', '');
				localStorage.removeItem('adminMessages');
				localStorage.removeItem('userLanguage');
				localStorage.clear();
				sessionStorage.clear();
				instance.logoutRedirect({
					postLogoutRedirectUri: '/'
				});
			}
		}
	}, [redirectCheck, accounts, inProgress]);

	useEffect(() => {
		if (accessToken !== null && accessToken !== undefined) {
			let startTime = jwt_decode(accessToken).exp - 300;
			//console.log(jwt_decode(accessToken).exp);
			let endTime = Date.now() / 1000;
			let diff = startTime - endTime;
			let refreshTime = diff * 1000;
			//console.log('refreshTime', refreshTime);
			refreshTokenHandler(refreshTime);
		}
	}, []);

	const refreshTokenHandler = refreshTime => {
		//console.log('ddd', refreshTime);
		refreshTokenTimer = setTimeout(() => {
			refreshTokenAction();
		}, refreshTime);
	};

	const logOut = async () => {
		localStorage.removeItem('adminMessages');
		localStorage.removeItem('userLanguage');
		localStorage.clear();
		sessionStorage.clear();
		await instance.logoutRedirect({
			postLogoutRedirectUri: '/'
		});
	};

	const refreshTokenAction = () => {
		//console.log('refresg');
		const accessTokenRequest = {
			scopes: ['openid', 'profile'],
			account: accounts[0]
		};
		instance
			.acquireTokenSilent(accessTokenRequest)
			.then(accessTokenResponse => {
				let startTime = jwt_decode(accessTokenResponse.idToken).exp;
				let endTime = Date.now() / 1000;
				let diff = startTime - endTime;
				let refreshTime = diff * 1000;

				window.sessionStorage.setItem('AccessToken', accessTokenResponse.idToken);
				refreshTokenHandler(refreshTime);
			})
			.catch(error => {
				if (String(error).includes('AADB2C90077')) {
					console.log('Session Expired'); //eslint-disable-line
					logOut();
				}
			});
	};

	return auth.token ? (
		<>
			<Outlet />
		</>
	) : (
		<>
			<Navigate to="/" />
		</>
	);
};

export default PrivateRoutes;
