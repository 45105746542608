import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccountHeader from '../AcccountHeader/AccountHeader';
import { pageUrls, pageNavigations, defaultNotificationCount, restrictions } from '../../../helpers/constants';
import { SwitchLanguage } from '../../../store/slices/userSlice';
//import { isMobile } from 'react-device-detect';
import './Header.scss';
import Notification from '../../common/Notification';
import { GetAllNotifications } from '../../../store/slices/notification';
//import Image from '../../common/image';
import HttpHelper from '../../../helpers/HttpHelper';
import TrackActivity from '../../../helpers/TrackActivity';
import packdefaultLogo from '../../../assets/images/NextLogo.png';
//import NextLogo1 from '../../../assets/images/NextLogo1.png';
const get = require('get-value');
import { useScreenType } from '../../../helpers/hooks/useScreenType';
const Header = props => {
	const navigate = useNavigate();
	const [headerNav, setHeaderNav] = useState('');
	const [headerLogo, setHeaderLogo] = useState(null);
	//const [logoLoaded, setLogoLoaded] = useState(false);
	const [defaultLogo, setDefaultLogo] = useState(null);
	const shouldLog = useRef(true);
	const [notificationLabels, setNotificationLabels] = useState([]);
	const [notifyLabels, setNotifyLabels] = useState(null);
	const navRef = useRef();
	const [permissionCode, setPermissionCode] = useState('');
	const http = new HttpHelper();
	const tracker = new TrackActivity();
	const { isSmallDevice, isMediumDevice } = useScreenType();

	useEffect(() => {
		const tokenData = JSON.parse(sessionStorage.getItem('setIdTokenClaims'));
		setPermissionCode(tokenData.PermissionCode);
		getLogo();
	}, []);

	const getLogo = async () => {
		let userId = window.sessionStorage.getItem('UserId');
		await http
			.Get('User-Profile/client/' + userId + '/logo')
			.then(res => {
				setHeaderLogo(res.data.Logo);
				//setLogoLoaded(true);
			})
			.catch(error => {
				return error;
			});
	};

	const getData = async () => {
		const { Components } = props.headerPageContent.Pages[0];
		const headerNavigation = get(Components, 'HeaderFooter.Header.Labels');
		const actionLabel = get(Components, 'Common.Component.Labels');
		const imageData = {
			smallImage: get(props.headerPageContent.Pages[0], 'SmallImage'),
			mediumImage: get(props.headerPageContent.Pages[0], 'MediumImage'),
			largeImage: get(props.headerPageContent.Pages[0], 'LargeImage')
		};
		setDefaultLogo(imageData);
		const navigationLabels = [];
		const notificationLabels = {};
		headerNavigation.map(notificationItems => {
			if (pageNavigations.includes(notificationItems.Key)) {
				navigationLabels.push(notificationItems);
			}
			notificationLabels[notificationItems.Key] = notificationItems;
		});
		let sortOrder = pageNavigations;
		navigationLabels.sort(function (a, b) {
			return sortOrder.indexOf(a.Key) - sortOrder.indexOf(b.Key);
		});
		let notifyLabels = [];
		actionLabel.map(actionLabelItems => {
			notifyLabels[actionLabelItems.Key] = actionLabelItems.Phrase;
		});
		setNotificationLabels(notificationLabels);
		setNotifyLabels(notifyLabels);
		setHeaderNav(navigationLabels);
	};

	const [showNotification, setShowNotification] = useState(false);
	const handleNotification = () => {
		setShowNotification(true);
		navRef.current.click();
	};
	const closeNotification = () => {
		setShowNotification(false);
		props.closeNotification();
	};

	const getAllNotifications = async () => {
		let data = {};
		data.offset = 1;
		data.limit = defaultNotificationCount;
		data.locale = props.userSelectedlanguage;
		props.getAllNotifications(data);
	};

	useEffect(() => {
		if (props.headerPageContent) {
			getData();
		}
		return () => {};
	}, [props.headerPageContent]);

	useEffect(() => {
		if (shouldLog.current) {
			getAllNotifications();
			shouldLog.current = false;
		}
		return () => {};
	}, []);

	useEffect(() => {
		if (props.showNotification) {
			setShowNotification(true);
		} else {
			setShowNotification(false);
		}
		return () => {};
	}, [props.showNotification]);

	useEffect(() => {
		if (props.userSelectedlanguage) {
			getAllNotifications();
		}
	}, [props.userSelectedlanguage]);

	const navigateToUrl = key => {
		//if (reportsNotAllowed.includes(permissionCode) && key === 'Header.ReportsAndResources') navigate('/all-resources');
		//else navigate(pageUrls[0][key]); //Commented these lines to make report section available for all users
		navigate(pageUrls[0][key]);
		let userId = window.sessionStorage.getItem('UserId');
		const value = key.split('.')[1] == 'Dashboards' ? 'Dashboard-A' : key.split('.')[1];
		tracker.sendActivity('UserVisit' + value, userId, userId, value);
	};
	return (
		<>
			<div className="nextNav navbar navbar-light navbar-expand-lg">
				{headerLogo ? (
					<div
						role="button"
						className="logo_img-container "
						onClick={() => navigate(pageUrls[0]['Header.Home'])}
						onKeyDown={e => {
							if (e.key === 'Enter') navigate(pageUrls[0]['Header.Home']);
						}}
						tabIndex={0}
						aria-label="Logo">
						<img src={headerLogo} alt="Logo" className="img-fluid fromapi" />
					</div>
				) : (
					<div
						role="button"
						className="logo_img-container"
						onClick={() => navigate(pageUrls[0]['Header.Home'])}
						onKeyDown={e => {
							if (e.key === 'Enter') navigate(pageUrls[0]['Header.Home']);
						}}
						tabIndex={0}
						aria-label="Logo">
						{defaultLogo?.mediumImage?.ImageUrl ? (
							<img src={defaultLogo?.mediumImage?.ImageUrl} alt="Logo" className="img-fluid fromsite" />
						) : (
							<img src={packdefaultLogo} alt="Logo" className="img-fluid frompack" />
						)}
					</div>
				)}
				<button
					ref={navRef}
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<div className="navbar-nav ms-auto mb-2 mb-lg-0">
						<nav>
							<ul className="" role="list">
								<button
									type="button"
									className="d-lg-none btn btn-dark navClose rounded-pill my-5"
									data-bs-toggle="collapse"
									data-bs-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent"
									aria-expanded="false"
									aria-label="Toggle navigation">
									Close
								</button>
								{Object.values(headerNav).map(
									(navitems, index) =>
										index > 1 &&
										index != 6 &&
										!restrictions[permissionCode].includes(navitems.Key) && (
											<li key={navitems.Key} role="listitem" className="nav-item">
												<a
													role="link"
													className="nav-link active"
													// aria-current="page"
													tabIndex={0}
													aria-label={navitems.Phrase}
													onKeyDown={e => {
														if (e.key === 'Enter') navigateToUrl(navitems.Key);
													}}
													onClick={() => navigateToUrl(navitems.Key)}>
													{navitems.Phrase}
												</a>
											</li>
										)
								)}
							</ul>
						</nav>
						<div className="d-lg-none accountHeader">
							{(isSmallDevice || isMediumDevice) && (
								<AccountHeader
									notification={() => handleNotification()}
									unreadNotificationsCount={props?.unreadNotificationsCount}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			{Object.keys(notificationLabels).length > 0 && showNotification && (
				<Notification
					showNotification={showNotification}
					closeNotification={() => closeNotification()}
					notificationLabels={notificationLabels}
					notificationList={props?.notificationList}
					notificationsCount={props?.notificationsCount}
					notifyLabels={notifyLabels}
					unreadNotificationsCount={props?.unreadNotificationsCount}
				/>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		headerPageContent: state.userSlice.headerPageContent,
		notificationList: state.notificationSlice.notificationList,
		notificationsCount: state.notificationSlice.notificationsCount,
		unreadNotificationsCount: state.notificationSlice.unreadNotificationsCount
	};
};

const mapDispatchToProps = dispatch => {
	return {
		switchLanguage: shortLang => dispatch(SwitchLanguage(shortLang)),
		getAllNotifications: data => dispatch(GetAllNotifications(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
