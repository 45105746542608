import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Header, HeaderRow, HeaderCell, Body, Row, Cell } from '@table-library/react-table-library/table';
import { useTree, CellTree } from '@table-library/react-table-library/tree';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline'; //eslint-disable-line
//import DownArrow from '../../assets/images/DownArrow.png';
//import DocumentIcon from '../../assets/images/document.png';
//import progData from './data';
import DownlodIcon from '../../assets/images/RightArrow.png';
import PgRightIcon from '../../assets/images/PgRightIcon_20.png';
import PgDownIcon from '../../assets/images/PgDownIcon_20.png';
import PgFileIcon from '../../assets/images/PgFileIcon_20.png';
import environments from '../../components/common/environments/environments'; // eslint-disable-line
import HttpHelper from '../../helpers/HttpHelper';
import { programDescriptorKey } from '../../helpers/constants';
import InLineLoader from '../common/inlineLoader';
import { v4 as uuidv4 } from 'uuid';
import './style.scss';
const get = require('get-value');
const programDescriptor = props => {
	//const [list, setList] = useState();
	//const data = { nodes: list ? lists : '' };l
	const http = new HttpHelper();
	const navigate = useNavigate();
	const [data, setData] = useState({ nodes: [] });
	const [defaultIds, setDefaultIds] = useState([]); //eslint-disable-line
	const [gridLabels, setGridLabels] = useState([]);
	const [inLineLoader, setInLineLoader] = useState(false);
	const getProgramDescriptorList = async () => {
		setInLineLoader(true);
		let ClientAccountID = sessionStorage.getItem('ClientAccId');
		console.log('ClientAccountID', ClientAccountID); //eslint-disable-line
		await http
			.Get(environments.PROGRAM_DESCRIPTOR_API + '/' + ClientAccountID)
			.then(res => {
				console.log('Program Descriptor Api Data', res); // eslint-disable-line
				if (res.status == 200) {
					let ndata = res.data;
					//let nodedata = {};
					if (ndata['RootFolder']) {
						//nodedata.id = ndata['RootFolder']['Id'];
						//nodedata.name = ndata['ClientAccountName'];
						//setDefaultIds(defaultIds => [...defaultIds, ndata['RootFolder']['Id']]);

						//nodedata.nodes = [];
						let nodedata = [];

						let parentSubFolders = get(ndata, 'RootFolder.ResourceFolders');
						console.log('parentSubFolders', parentSubFolders); //eslint-disable-line

						parentSubFolders?.map(item => {
							let resourceFolderNode = {};
							resourceFolderNode.id = uuidv4(); //item?.Id;
							//setDefaultIds(defaultIds => [...defaultIds, item?.Id]);
							resourceFolderNode.name = item?.ResourceFolderName;
							resourceFolderNode.nodes = [];

							if (item.ResourceSubFolders.length > 0) {
								item.ResourceSubFolders?.map(subitem => {
									let resourcesubFolderNode = {};
									resourcesubFolderNode.id = uuidv4(); //subitem?.Id;
									resourcesubFolderNode.name = subitem?.ResourceSubFolderName;
									if (subitem?.ProgramDescriptors?.length > 0) {
										let getProgDescObj = generateProgramDescriptorObjects(subitem);
										resourcesubFolderNode.nodes = getProgDescObj;
									} else {
										resourcesubFolderNode.nodes = [];
									}
									resourceFolderNode.nodes.push(resourcesubFolderNode);
								});
							}
							if (item.ProgramDescriptors.length > 0) {
								item.ProgramDescriptors.map(progitem => {
									if (progitem?.IsActive) {
										let descriptorDatas = {};
										descriptorDatas.id = uuidv4(); //progitem?.Id;
										descriptorDatas.clientaccountpdid = progitem?.Id;
										descriptorDatas.name = progitem?.ClientAccountProgramDescripitorTitle;
										descriptorDatas.filetype = 'FILE';
										resourceFolderNode.nodes.push(descriptorDatas);
									}
								});
							}
							//nodedata.nodes.push(resourceFolderNode);
							nodedata.push(resourceFolderNode);
						});

						let parentProgramDescriptor = get(ndata, 'RootFolder.ProgramDescriptors');
						if (parentProgramDescriptor.length > 0) {
							parentProgramDescriptor.map(item => {
								if (item?.IsActive) {
									let descriptorDatas = {};
									descriptorDatas.id = uuidv4(); //item?.Id;
									descriptorDatas.clientaccountpdid = item?.Id;
									descriptorDatas.name = item?.ClientAccountProgramDescripitorTitle;
									descriptorDatas.filetype = 'FILE';
									//nodedata.nodes.push(descriptorDatas);
									nodedata.push(descriptorDatas);
								}
							});
						}

						//let finalNode = [];
						//finalNode.push(nodedata);
						//console.log('finalNode', finalNode); //eslint-disable-line
						//setData({ nodes: finalNode });
						console.log('finalNode', nodedata); //eslint-disable-line
						setData({ nodes: nodedata });
						setInLineLoader(false);
					} else {
						setInLineLoader(false);
					}
				}
			})
			.catch(error => {
				console.log('PROGRAM DESC API ERROR:', error); //eslint-disable-line
				return error;
			});
	};

	useEffect(() => {
		//console.log('nodedataddddd', data.nodes.length); //eslint-disable-line
		if (props?.pageData) {
			let labels = {};
			props?.pageData?.Labels.map(element => {
				labels[element.Key] = [element.Phrase];
			});
			setGridLabels(labels);
		}
	}, [props?.pageData]);

	useEffect(() => {
		getProgramDescriptorList();
	}, []);

	const generateProgramDescriptorObjects = subitem => {
		let descriptorObj = [];
		subitem.ProgramDescriptors.map(item => {
			if (item?.IsActive) {
				let descriptorDatas = {};
				descriptorDatas.id = uuidv4(); //item?.Id;
				descriptorDatas.clientaccountpdid = item?.Id;
				descriptorDatas.name = item?.ClientAccountProgramDescripitorTitle;
				descriptorDatas.filetype = 'FILE';
				descriptorObj.push(descriptorDatas);
			}
		});
		console.log('descriptorObj', descriptorObj); //eslint-disable-line
		return descriptorObj;
	};

	const theme = useTheme({});

	function onTreeChange(action, state) {
		console.log(action, state); //eslint-disable-line
	}
	const tree = useTree(
		data,
		{
			state: {
				ids: defaultIds
			},
			onChange: onTreeChange
		},
		{
			treeIcon: {
				margin: '4px',
				iconDefault: <img src={PgFileIcon} className="fileIcon" />,
				iconRight: <img src={PgRightIcon} className="rightArrow" />,
				iconDown: <img src={PgDownIcon} className="downArrow" />
			}
		}
	);
	const viewProgramDescriptor = pdid => {
		let viewUrl = '/reports-view/' + pdid + '/' + programDescriptorKey;
		navigate(viewUrl);
	};
	return (
		<>
			{!inLineLoader && data ? (
				<div className="program-descriptor">
					{props?.pageData?.Title && <h2 className="program-descriptor__title">{props?.pageData?.Title}</h2>}
					{props?.pageData?.Description && (
						<p className="program-descriptor__description">{props?.pageData?.Description}</p>
					)}
					<Table
						data={data}
						tree={tree}
						className={data.nodes.length ? 'table' : 'table noscroll'}
						theme={theme}
						layout={{ fixedHeader: true }}>
						{tableList => (
							<>
								<Header>
									<HeaderRow>
										{gridLabels && (
											<HeaderCell resize={{ maxWidth: 50 }}>{gridLabels?.['ClientResources.Name']}</HeaderCell>
										)}
										{gridLabels && (
											<HeaderCell resize={{ maxWidth: 50 }} style={{ 'text-align': 'left' }}>
												{gridLabels?.['ClientResources.View']}
											</HeaderCell>
										)}
									</HeaderRow>
								</Header>

								<Body>
									{tableList?.map(item => (
										<Row key={item.id} item={item}>
											<CellTree item={item}>{item.name}</CellTree>
											<Cell style={{ 'text-align': 'left' }}>
												{item.filetype === 'FILE' && (
													<span className="viewlink" onClick={() => viewProgramDescriptor(item.clientaccountpdid)}>
														{gridLabels && gridLabels?.['ClientResources.View']} <img src={DownlodIcon} />
													</span>
												)}
											</Cell>
										</Row>
									))}
								</Body>
							</>
						)}
					</Table>
				</div>
			) : (
				<InLineLoader />
			)}
		</>
	);
};
export default programDescriptor;
