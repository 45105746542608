import { useEffect, useContext } from 'react';
import { AccoutTeamContext } from '../../../helpers/contexts';
import Button from '../../common/button';
//import HttpHelper from '../../../helpers/HttpHelper';
import { MeetingList } from '../../../store/slices/userSlice';
import { connect } from 'react-redux';

import './style.scss';

const MeetingRequests = props => {
	//[meetingsData, setMeetingsData] = useState(null),
	//http = new HttpHelper()
	const data = useContext(AccoutTeamContext),
		{ pageDataAccountTeam } = data,
		labels = {};

	let firstTwoMeetings = [],
		restMeetings = [];

	pageDataAccountTeam?.Labels?.forEach(ele => {
		labels[ele.Key] = ele.Phrase;
	});

	useEffect(() => {
		getMeetingsData().catch(error => {
			console.log(error); //eslint-disable-line
		});
	}, []);

	const getMeetingsData = async () => {
		// let userId = window.sessionStorage.getItem('UserId');
		// const { data } = await http.Get('User-Profile/mymeeting/' + userId);
		// setMeetingsData(data);
		props.meetingList();
	};

	const convertDate = date => {
		return new Date(date.toString()).toLocaleDateString();
	};

	if (props.meetingsData?.length > 0) {
		firstTwoMeetings = props?.meetingsData?.slice(0, 1);
		restMeetings = props?.meetingsData?.slice(1);
	}

	return (
		<div className="meeting-requests">
			{props.meetingsData?.length > 0 ? (
				<>
					<h4 className="mb-3">{labels['ClientTeam.AccountTeam.MeetingRequests']}</h4>
					{firstTwoMeetings?.length > 0 &&
						firstTwoMeetings.map((meeting, index) => {
							return (
								<div className="meeting__detail mt-2" key={`meeting${index}`}>
									<div className="meeting__title">
										{meeting?.Title} | <small className="meeting__status">{meeting?.MeetingStatusDescription}</small>
									</div>
									<div className="meeting__date-time">
										{convertDate(meeting?.MeetingDate)} | {meeting?.PreferredTimeSlot} | {meeting?.Duration}
									</div>
								</div>
							);
						})}

					{restMeetings?.length > 0 && (
						<>
							<div className="collapse" id="collapseExample">
								{restMeetings.map((meeting, index) => {
									return (
										<div className="meeting__detail mt-2" key={`meeting${index}`}>
											<div className="meeting__title">
												{meeting?.Title} |{' '}
												<small className="meeting__status">{meeting?.MeetingStatusDescription}</small>
											</div>
											<div className="meeting__date-time">
												{convertDate(meeting?.MeetingDate)} | {meeting?.PreferredTimeSlot} | {meeting?.Duration}
											</div>
										</div>
									);
								})}
							</div>
							<div className="mt-2 text-center">
								<Button
									data-bs-toggle="collapse"
									data-bs-target="#collapseExample"
									aria-expanded="false"
									aria-controls="collapseExample"
									buttonClass="button__icon-button meeting__show-btn">
									<span className="more">{labels['ClientTeam.AccountTeam.LoadMore']}</span>
									<span className="less">{labels['ClientTeam.AccountTeam.ShowLess']}</span>
								</Button>
							</div>
						</>
					)}
				</>
			) : (
				<>
					<h4>{labels['ClientTeam.AccountTeam.NoMeetingRequest']}</h4>
					{labels['ClientTeam.AccountTeam.NoMeetingRequest']}
				</>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		meetingsData: state.userSlice.meetingsData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		meetingList: () => dispatch(MeetingList())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRequests);
