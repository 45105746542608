//import HomeRecentReports from '../../../assets/images/HomeRecentReports.png';
import { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import Reports from '../../reportsAndResources/reports';
//import Button from '../../common/button';
import { ReportsActions } from '../../reportsAndResources/ReportsActions';

import './recentReports.scss';
const get = require('get-value');

const RecentReports = props => {
	const [cmsReferralPage, setCmsReferralPage] = useState('');
	//const navigate = useNavigate();
	const labels = get(props.data, 'Components.LandingPage.Home.Labels');

	useEffect(() => {
		//console.log('asdfasd');
		getReferralsPageContent();
	}, []);

	useEffect(() => {
		if (props?.userSelectedlanguage) getReferralsPageContent();
	}, [props?.userSelectedlanguage]);

	const getReferralsPageContent = async () => {
		try {
			//setPageLoader(true);
			let cmsReferralsPagepayload = {
				categoryname: 'Default',
				pagename: 'ReportsAndResources',
				language: props?.userSelectedlanguage?.toLowerCase() || 'en'
			};
			//console.log('welsome');
			const res = await ReportsActions.GetCMSRequestPageDetails(cmsReferralsPagepayload);
			if (res.status === 200) {
				//setPageLoader(false);
				const data = res?.data?.Pages[0];
				setCmsReferralPage(data);
			} else {
				return [];
			}
		} catch (error) {
			//console.log(error);
			return [];
		}
	};

	return (
		<div className="recent-reports mx-3 mx-md-0">
			{cmsReferralPage && (
				<Reports
					pageSize={5}
					pagination={false}
					gridHeight={300}
					hideDesc={true}
					title={labels?.[1]?.Phrase}
					submittedRequestData={cmsReferralPage}
					seeAll={true}
					seeAllLabel={labels?.[2]?.Phrase}
					titleWidth={225}
				/>
			)}
		</div>
	);
};

export default RecentReports;
