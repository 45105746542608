import { useState } from 'react';
import Image from '../../common/image';
import rightArrow from '../../../assets/images/RightArrow.png';
//import { useScreenType } from '../../../helpers/hooks/useScreenType';
import './style.scss';
import DefaultImage from '../../../assets/images/default_report_resources.png';

const ResourceTile = ({ resource, first, onClick = null }) => {
	const { Title, ShortDescription, ResourceType, SmallImage, MediumImage, LargeImage } = resource;
	const imageData = {
		smallImage: SmallImage,
		mediumImage: MediumImage,
		largeImage: LargeImage
	};
	//console.log('resource', resource);
	//const { isSmallDevice, isLargeDevice, isMediumDevice } = useScreenType();
	const [showDefault, setShowDefault] = useState(false);
	const defaultImage = () => {
		//console.log('defaultImage:::', defaultImage);
		setShowDefault(true);
	};
	return (
		<div className={`resource-tile row ${first ? 'resource-tile__first-tile' : ''}`}>
			{first ? (
				<>
					<div className="col-12 col-md-7 mb-3 mb-md-0 resource-tile__image">
						<Image imageData={imageData} />
					</div>
					<div className="col-12 col-md-5 position-relative resource-tile__desc">
						<div className="resource-tile__title" onClick={onClick}>
							{Title}
						</div>
						<div className="resource-tile__description mt-2">{ShortDescription}</div>
						<div className="resource-tile__type">{ResourceType}</div>
						<div className="resource-tile__view" onClick={onClick}>
							<img src={rightArrow} alt="right arrow icon" />
						</div>
					</div>
				</>
			) : (
				<>
					<div className="col-12 col-md-5 resource-tile__image mb-4 d-md-none">
						<Image imageData={imageData} />
						{/* <img
							src={SmallImage.ImageUrl}
							alt={SmallImage.AltText ? SmallImage.AltText : 'Thought Leadership Image'}
							height={height}
							width={width}
						/> */}
					</div>
					<div className="col-12 col-md-9 col-lg-7">
						<div className="resource-tile__title">
							<button
								data-bs-toggle="tooltip"
								data-bs-placement="top"
								title={Title}
								className="remove-style"
								onClick={onClick}>
								{Title.substring(0, 25) + '...'}
							</button>
						</div>
						<div className="resource-tile__description mt-2">{ShortDescription}</div>
						<div className="resource-tile__type">{ResourceType}</div>
					</div>
					<div className="col-12 col-md-3 col-lg-5 resource-tile__image d-none d-md-block">
						{showDefault ? (
							<img src={DefaultImage} alt={'Thought Leadership Image'} height="120px" width="100%" />
						) : (
							<Image imageData={imageData} tileHeight={'120px'} onError={defaultImage} />
						)}
						{/* <img
							src={MediumImage.ImageUrl}
							alt={MediumImage.AltText ? MediumImage.AltText : 'Thought Leadership Image'}
							height="120px"
							width="100%"
						/> */}
					</div>
					<div className="col-12 d-none d-md-block">
						<hr className="my-4" />
					</div>
				</>
			)}
		</div>
	);
};

export default ResourceTile;
