import React from 'react';
import './twoColumnTemplateAfterOnboard.scss';
import Header from '../Headers/Header/Header';
import AccountHeader from '../Headers/AcccountHeader/AccountHeader';

export default function twoColumnTemplateAfterOnboard({ children }) {
	return (
		<>
			<div className="container-fluid accountHeader d-none">
				<AccountHeader />
			</div>
			<div className="container-fluid container-lg d-block d-lg-none">
				<Header />
			</div>
			<div className="container-fluid twoColumnTemplateAfterOnboard">{children}</div>
		</>
	);
}
