//import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './header.scss';
import HttpHelper from '../../../helpers/HttpHelper';
//import BackIcon from '../../../assets/images/left-arrow.png';

const Header = () => {
	const http = new HttpHelper();
	const [logo, setLogo] = useState({});
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const languagecode = params.get('languagecode');

	useEffect(() => {
		getLogo();
	}, []);

	const getLogo = async () => {
		const payload = {
			categoryname: 'Default',
			pagename: 'PreLoginHeader',
			language: localStorage.getItem('userLanguage') || languagecode || 'en'
		};
		let { data } = await http.CMSpost('PageApi/GetPageDetails', payload);
		setLogo(data.Pages[0].SmallImage);
	};

	return (
		<div className="policy-header">
			{/* <button className="back-button" onClick={goBack}>
				<img src={BackIcon} alt="Back Icon" width={13} />
				<span className="backtext">Back</span>
			</button> */}
			<button className="logo-button" /*onClick={goToHomePage}*/>
				<img src={logo.ImageUrl} alt={logo.AltText} width={150} className="img-fluid" />
			</button>
		</div>
	);
};

export default Header;
