import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputBox from '../../common/inputBox';
import SelectBox from '../../common/selectBox';
import Button from '../../common/button';
import Modalpopup from '../../common/Modal/Modalpopup';
import InlineLoader from '../../common/inlineLoader';
import HttpHelper from '../../../helpers/HttpHelper';
import { useMsal } from '@azure/msal-react';
import { b2cPolicies } from '../../../helpers/AuthService';
import { SwitchLanguage } from '../../../store/slices/userSlice';
import './style.scss';
const get = require('get-value');

const CancelConfirmation = ({
	toggleCancelConfirmation,
	toggleEditingMode,
	setProfileData,
	profileDataBackup,
	modalLabels,
	buttonLabels
}) => {
	return (
		<div className="cancel-confirmation text-center">
			<h2>{modalLabels['PersonalDetailsExceptionMsg.CancelChangesPopup'].Title}</h2>
			<p className="mt-3 mb-4">{modalLabels['PersonalDetailsExceptionMsg.CancelChangesPopup'].Phrase}</p>
			<div className="mt-3">
				<Button
					buttonClass="button--blue"
					type="submit"
					onClickHandler={() => {
						toggleEditingMode();
						toggleCancelConfirmation();
						setProfileData(profileDataBackup.current);
					}}>
					{buttonLabels['AccountDetails.Confirm']}
				</Button>
				<Button buttonClass="button--white ms-3" onClickHandler={toggleCancelConfirmation}>
					{buttonLabels['AccountDetails.Cancel']}
				</Button>
			</div>
		</div>
	);
};

const SaveConfirmation = ({ toggleSaveConfirmation, submitBtnRef, modalLabels, buttonLabels }) => {
	//console.log('modalLabelsss', modalLabels);

	return (
		<div className="cancel-confirmation text-center">
			<h2>{modalLabels['PersonalDetailsExceptionMsg.SaveChanges'].Title}</h2>
			<p className="mt-3 mb-4">{modalLabels['PersonalDetailsExceptionMsg.SaveChanges'].Phrase}</p>
			<div className="mt-3">
				<Button
					buttonClass="button--blue"
					type="submit"
					onClickHandler={() => {
						submitBtnRef.current.click();
					}}>
					{buttonLabels['AccountDetails.Confirm']}
				</Button>
				<Button buttonClass="button--white ms-3" onClickHandler={toggleSaveConfirmation}>
					{buttonLabels['AccountDetails.Cancel']}
				</Button>
			</div>
		</div>
	);
};

const MyAccountForm = ({ data }) => {
	const [languages, setLanguages] = useState([]),
		[profileData, setProfileData] = useState(null),
		profileDataBackup = useRef(null),
		[cancelConfirmation, setCancelConfirmation] = useState(false),
		[saveConfirmation, setSaveConfirmation] = useState(false),
		[isEditingMode, enableEditingMode] = useState(false),
		submitBtnRef = useRef(null),
		{ myAccount } = data,
		http = new HttpHelper(),
		buttonLabels = {},
		modalLabels = {},
		controlLabels = get(myAccount, 'Components.Account.PersonalDetails.Labels'),
		fieldDisabled = isEditingMode ? '' : 'disabled',
		langTexts = {};
	const headerPageContent = useSelector(state => state?.userSlice?.headerPageContent);
	const [cmsLanguages, setCmsLanguages] = useState('');
	const [profileLanguages, setProfileLanguages] = useState('');
	const { instance } = useMsal();
	const dispatch = useDispatch();

	get(myAccount, 'Components.MyAccount.AccountDetails.Labels').forEach(ele => {
		buttonLabels[ele.Key] = ele.Phrase;
	});

	get(myAccount, 'Components.Account.PersonalDetailsExceptionMsg.LabelWithTitle').forEach(ele => {
		modalLabels[ele.Key] = ele;
	});

	useEffect(() => {
		let UserId = window.sessionStorage.getItem('UserId');
		getLanguages(UserId).catch(error => {
			console.log(error); //eslint-disable-line
		});

		getProfileData(UserId).catch(error => {
			console.warn(error); //eslint-disable-line
		});
	}, []);

	const getLanguages = async UserId => {
		await http
			.Get('User-Profile/client/' + UserId + '/language')
			.then(res => {
				setProfileLanguages(res.data);
			})
			.catch(error => {
				return error;
			});
	};

	const getProfileData = async UserId => {
		await http
			.Get('User-Profile/client/' + UserId + '/profile')
			.then(res => {
				profileDataBackup.current = res.data;
				setProfileData(res.data);
			})
			.catch(error => {
				return error;
			});
	};

	const toggleEditingMode = () => {
		enableEditingMode(!isEditingMode);
	};

	const toggleCancelConfirmation = () => {
		setCancelConfirmation(!cancelConfirmation);
	};

	const toggleSaveConfirmation = () => {
		setSaveConfirmation(!saveConfirmation);
	};

	const updateProfileData = e => {
		setProfileData({ ...profileData, [e.target.name]: e.target.value });
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (e.target.checkValidity()) {
			const formData = new FormData(e.target);
			const payload = {
				...profileData
			};

			for (const pair of formData.entries()) {
				payload[pair[0]] = pair[1];
			}

			saveProfileData(payload);
			//console.log('payload', payload);
			let selectedLanguageData = profileLanguages.filter(lang => lang.LanguageID === payload['LanguageId']);
			//console.log('la', selectedLanguageData);
			localStorage.setItem('updateGroupADashboard', 1);
			localStorage.setItem('updateGroupBDashboard', 1);
			dispatch(SwitchLanguage(selectedLanguageData[0].LanguageCode.toLowerCase()));
		} else {
			e.target.classList.add('was-validated');
			e.target.querySelectorAll(`:invalid`)[0].focus();
			toggleSaveConfirmation();
		}
	};

	const saveProfileData = async payload => {
		const { data } = await http.post('User-Profile/client/editprofile', payload, 'application/json');

		if (data) {
			toggleSaveConfirmation();
			toggleEditingMode();
			profileDataBackup.current = { ...payload };
		}
	};

	get(myAccount, 'Components.Header.Language.LabelWithTitle').forEach(ele => {
		langTexts[ele.Title] = ele.Phrase;
	});

	const langOptions = useMemo(
		() =>
			languages
				? languages.map(ele => {
						let languageDetails = profileLanguages.find(o => o.LanguageCode.toLowerCase() === ele?.Title.toLowerCase());
						return {
							text: langTexts[ele?.Title] || ele?.Phrase,
							value: languageDetails.LanguageID
						};
				  })
				: [],
		[languages]
	);

	const forgotPassword = () => {
		sessionStorage.setItem('REDIRECTTO', 'FORGOT');
		instance.loginRedirect(b2cPolicies.authorities.changePassword).then(response => {
			return response;
		});
	};
	//console.log('profileData.LanguageId', profileData);
	//console.log('languages', languages);

	useEffect(() => {
		if (headerPageContent) {
			const { Components } = headerPageContent.Pages[0];
			const languages = get(Components, 'Header.Language.LabelWithTitle');
			setCmsLanguages(languages);
		}
	}, [headerPageContent]);

	const getDifference = (array1, array2) => {
		return array1.filter(object1 => {
			return array2.some(object2 => {
				return object1.Title.toLowerCase() === object2.LanguageCode.toLowerCase();
			});
		});
	};

	useEffect(() => {
		if (cmsLanguages.length > 0 && profileLanguages.length > 0) {
			let filteredLang = getDifference(cmsLanguages, profileLanguages);
			setLanguages(filteredLang);
		}
	}, [cmsLanguages, profileLanguages]);

	return (
		<>
			{profileData ? (
				<div className={`container-fluid vs-comp ${fieldDisabled}`}>
					<div className="container-fluid container-lg myaccount_personal p-lg-1 p-xl-0">
						<form onSubmit={handleSubmit} className="needs-validation" id="myAccountForm" noValidate>
							<div className="row">
								<div className="col-12 col-md-6">
									<h2 className="my-account__summary-title">
										{get(myAccount, 'Components.Account.PersonalDetails.Title')}
									</h2>
								</div>
								<div className="col-12 col-md-6 text-md-end mt-3 mt-md-0">
									{isEditingMode ? (
										<>
											<Button buttonClass="button--white" onClickHandler={toggleCancelConfirmation}>
												{buttonLabels['AccountDetails.Cancel']}
											</Button>

											<Button buttonClass="button--black ms-3" onClickHandler={toggleSaveConfirmation}>
												{buttonLabels['AccountDetails.Save']}
											</Button>
										</>
									) : (
										<Button buttonClass="button--white" onClickHandler={toggleEditingMode}>
											{buttonLabels['AccountDetails.Edit']}
										</Button>
									)}
								</div>
							</div>
							<hr className="mb-md-2" />
							<div className="col-12 col-md-11">
								<div className="row">
									<div className="col-12 col-md-5">
										<InputBox
											labelText={controlLabels?.[0]?.Phrase}
											name="FirstName"
											value={profileData.FirstName}
											onChange={updateProfileData}
											required
											disabled={fieldDisabled}
										/>
									</div>
									<div className="col-12 col-md-1 d-none d-md-block"></div>
									<div className="col-12 col-md-5">
										<InputBox
											labelText={controlLabels?.[1]?.Phrase}
											name="LastName"
											value={profileData.LastName}
											onChange={updateProfileData}
											required
											disabled={fieldDisabled}
										/>
									</div>
									<div className="col-12 col-md-5">
										<InputBox
											labelText={controlLabels?.[2]?.Phrase}
											name="EmailAddress"
											value={profileData.EmailAddress}
											required
											disabled
										/>
									</div>
									<div className="col-12 col-md-1 d-none d-md-block"></div>
									{/* <div className="col-12 col-md-5">
										<InputBox
											labelText={controlLabels?.[3]?.Phrase}
											name="ContactNumber"
											value={profileData.ContactNumber}
											onChange={updateProfileData}
											disabled={fieldDisabled}
											pattern="^((\+91[0-9]{10,15})|(0[0-9]{10,15}))$"
										/>
									</div> */}
									<div className="col-12 col-md-5">
										<SelectBox
											labelText={controlLabels?.[4]?.Phrase}
											name="LanguageId"
											value={profileData.LanguageId}
											options={langOptions}
											onChange={updateProfileData}
											required
											disabled={fieldDisabled}
										/>
									</div>
								</div>
							</div>
							<button type="submit" className="d-none" ref={submitBtnRef}></button>
						</form>
					</div>
					<div className="container-fluid container-lg myaccount_password p-lg-1 p-xl-2">
						<div className="my-account__reset-pass">
							<h2 className="my-account__summary-title">{buttonLabels['AccountDetails.Password']}</h2>
							<hr />
							<Button onClick={() => forgotPassword()}>{buttonLabels['AccountDetails.ResetPassword']}</Button>
						</div>
					</div>
				</div>
			) : (
				<InlineLoader />
			)}

			<Modalpopup show={cancelConfirmation} modalSize={'md'}>
				<CancelConfirmation
					toggleCancelConfirmation={toggleCancelConfirmation}
					toggleEditingMode={toggleEditingMode}
					setProfileData={setProfileData}
					profileDataBackup={profileDataBackup}
					modalLabels={modalLabels}
					buttonLabels={buttonLabels}
				/>
			</Modalpopup>

			<Modalpopup show={saveConfirmation} modalSize={'md'}>
				<SaveConfirmation
					toggleSaveConfirmation={toggleSaveConfirmation}
					submitBtnRef={submitBtnRef}
					modalLabels={modalLabels}
					buttonLabels={buttonLabels}
				/>
			</Modalpopup>
		</>
	);
};

export default MyAccountForm;
