import { useEffect, useRef } from 'react';
// import {
// 	notificationDateConverter,
// 	customDateConverterCard,
// 	capitalizeFirstLetterOfEachWord
// } from '../../../helpers/constants';
import { notificationDateConverter, customDateConverterCard } from '../../../helpers/constants';
import { useNavigate } from 'react-router-dom';
import './notification.scss';
import { connect } from 'react-redux';
import { GetAllNotifications, MarkAllAsRead } from '../../../store/slices/notification';
import environments from '../../../components/common/environments/environments'; // eslint-disable-line
//import parse from 'html-react-parser';
const get = require('get-value');

const Notification = props => {
	const navigate = useNavigate();
	const notifyRef = useRef();
	let notification = 'show';
	const notificationTitle = get(props.notificationLabels, 'Header.Notifications');
	const markAllAsRead = get(props.notificationLabels, 'Header.MarkAllasRead');
	const viewAll = get(props.notificationLabels, 'Header.ViewAllNotification');
	const closeText = get(props.notifyLabels, 'Common.ButtonText.Close');
	const disableViewAll =
		props?.notificationsCount === props?.notificationList?.length
			? 'disable'
			: props?.notificationsCount === 0
			? 'disable'
			: '';
	// const ndata = [
	// 	{
	// 		NotificationId: '38fb7f46-2c8d-49a6-8f74-d12a7e074b00',
	// 		NotificationUserId: '05b9e94a-d2aa-445d-f8eb-08dbb2f2beea',
	// 		NotificationReceiverEmailId: 'accountuser1513@mailsac.com',
	// 		NotificationTypeId: 'f967454d-0189-ee11-8925-000d3aae6e19',
	// 		NotificationType: 'ChangeInReferralStatus',
	// 		NotificationUrl: 'https://ase3shdcirrus004.azurewebsites.net/#/referrals',
	// 		NotificationStatus: 'complete',
	// 		PlaceholderValue: '',
	// 		HighlightedText: [],
	// 		NotificationMessage: '<p>Your Referral Status has been changed #RefTemp1_23_11_2023.xlsx# </p>',
	// 		IsRead: true,
	// 		NotificationDate: '2023-11-23T11:28:14.4250796+00:00',
	// 		Template: 'Your Referral Status has been changed #ReferralDocumentName#'
	// 	},
	// 	{
	// 		NotificationId: 'ac3ebc0a-f085-4ecd-8182-1d37c83b6619',
	// 		NotificationUserId: '05b9e94a-d2aa-445d-f8eb-08dbb2f2beea',
	// 		NotificationReceiverEmailId: 'accountuser1513@mailsac.com',
	// 		NotificationTypeId: 'f967454d-0189-ee11-8925-000d3aae6e19',
	// 		NotificationType: 'ChangeInReferralStatus',
	// 		NotificationUrl: 'https://ase3shdcirrus004.azurewebsites.net/#/referrals',
	// 		NotificationStatus: 'complete',
	// 		PlaceholderValue: '',
	// 		HighlightedText: [],
	// 		NotificationMessage: '<p>Your Referral Status #RefTemp1_23_11_2023.xlsx# has been changed</p>',
	// 		IsRead: true,
	// 		NotificationDate: '2023-11-23T10:14:27.199457+00:00',
	// 		Template: 'Your Referral Status has been changed #ReferralDocumentName#'
	// 	},
	// 	{
	// 		NotificationId: 'ac3ebc0a-f085-4ecd-8182-1d37c83b6619',
	// 		NotificationUserId: '05b9e94a-d2aa-445d-f8eb-08dbb2f2beea',
	// 		NotificationReceiverEmailId: 'accountuser1513@mailsac.com',
	// 		NotificationTypeId: 'f967454d-0189-ee11-8925-000d3aae6e19',
	// 		NotificationType: 'ChangeInReferralStatus',
	// 		NotificationUrl: 'https://ase3shdcirrus004.azurewebsites.net/#/referrals',
	// 		NotificationStatus: 'complete',
	// 		PlaceholderValue: '',
	// 		HighlightedText: [],
	// 		NotificationMessage: '<p>#RefTemp1_23_11_2023.xlsx# referral has been updated</p>',
	// 		IsRead: true,
	// 		NotificationDate: '2023-11-23T10:14:27.199457+00:00',
	// 		Template: 'Your Referral Status has been changed #ReferralDocumentName#'
	// 	}
	// ];

	const markAllasRead = async () => {
		let payload = {};
		let UserId = window.sessionStorage.getItem('UserId');
		payload.clientUserId = UserId;
		payload.brandId = environments.BRAND_ID;
		payload.notificationStatuses = [];
		props?.notificationList.map(notificationItems => {
			let notifyObject = { notificationId: '', isRead: true };
			notifyObject.notificationId = notificationItems.NotificationId;
			payload.notificationStatuses.push(notifyObject);
		});
		props.markAllAsRead(payload);
	};
	const markAsRead = async notificationId => {
		let payload = {};
		let UserId = window.sessionStorage.getItem('UserId');
		payload.clientUserId = UserId;
		payload.brandId = environments.BRAND_ID;
		payload.notificationStatuses = [];
		let notifyObject = { notificationId: '', isRead: true };
		notifyObject.notificationId = notificationId;
		payload.notificationStatuses.push(notifyObject);
		props.markAllAsRead(payload);
	};

	useEffect(() => {
		const checkIfClickedOutside = e => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu

			if (notifyRef.current && !notifyRef.current.contains(e.target) && e.target.className != 'notificationBtn') {
				//console.log();
				props.closeNotification(true);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, []);

	const generateLink = (templateName, templateLink) => {
		//let tt = fileName.split('#');
		//console.log(fileName, 'ssss', tt);
		return (
			<span tabIndex={0} className="notifyContainer__templateLink" onClick={() => navigate(templateLink)}>
				{templateName}
			</span>
		);
	};

	return (
		<>
			<div className={`notifyBackdrop ${notification}`}></div>
			<div ref={notifyRef} className={`notifyContainer ${notification}`}>
				<div className={`notifyContainer__slide-in from-right ${notification}`}>
					<div className="notifyContainer__slide-in-content">
						<div className="row notifyContainer__notifyMobileHead d-flex d-lg-none">
							{/* <div className="col-6  notifyContainer__notifyTitle">{notificationTitle.Phrase}</div> */}
							<div className="col-12 text-end">
								<button
									type="button"
									className="btn btn-dark notifyClose rounded-pill p3-2"
									onClick={() => props.closeNotification(true)}>
									{closeText}
								</button>
							</div>
						</div>
						<div className="row notifyContainer__notifyHead">
							<div className="col-6 notifyContainer__notifyTitle">{notificationTitle.Phrase}</div>
							{props?.unreadNotificationsCount > 0 && (
								<div tabIndex={0} className="col-6 notifyContainer__readAll text-end" onClick={() => markAllasRead()}>
									{markAllAsRead.Phrase}
								</div>
							)}
						</div>
						<div className="notifyContainer__notificationContainer">
							{props.notificationList && (
								<>
									{props.notificationList.map(notificationItem => {
										//let tmsg = notificationItem.NotificationMessage;
										let notificationUrl = notificationItem?.NotificationUrl?.split('#');
										let destrutcureMsg = notificationItem?.NotificationMessage?.split('#');
										let msglink =
											notificationUrl && destrutcureMsg ? generateLink(destrutcureMsg[1], notificationUrl[1]) : '';
										return (
											<div key={notificationItem.NotificationId}>
												<div
													className="row notifyContainer__notificationList"
													tabIndex={0}
													onClick={() => markAsRead(notificationItem.NotificationId)}>
													<div className="col-1">
														<span
															className={
																notificationItem.IsRead ? 'notifyContainer__greyDot' : 'notifyContainer__blueDot'
															}></span>
													</div>
													{notificationItem.NotificationMessage != null && (
														<div className="col-9 notifyContainer__notifyMsg">
															{/* {parse(notificationItem.NotificationMessage)} */}
															{/* {tmsg} */}
															{destrutcureMsg[0].replace('<p>', '')}
															{msglink}
															{destrutcureMsg[2].replace('</p>', '')}
														</div>
													)}
													<div className="col-2 notifyContainer__notifyTime">
														{customDateConverterCard(notificationItem.NotificationDate)}
													</div>
													<div className="col-8 notifyContainer__notifyDate">
														{notificationDateConverter(notificationItem.NotificationDate)}
													</div>
												</div>
											</div>
										);
									})}
								</>
							)}
						</div>
						<div className="row notifyContainer__viewAll">
							{
								<div
									tabIndex={0}
									className={`col-12 p-2 text-center ${disableViewAll}`}
									// onClick={() => props.getAllNotifications(props?.notificationsCount)}>
									onClick={() =>
										props.getAllNotifications({
											offset: 1,
											limit: props?.notificationsCount,
											locale: localStorage.getItem('userLanguage') || 'en'
										})
									}>
									{viewAll.Phrase}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		markAllAsRead: data => dispatch(MarkAllAsRead(data)),
		getAllNotifications: dataLimit => dispatch(GetAllNotifications(dataLimit))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
