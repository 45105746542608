import React, { useState, useId } from 'react';
import DatePicker from 'react-datepicker';
import Label from '../label';
import { addDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DatePickerInput = ({ labelText, ...restProps }) => {
	const [startDate, setStartDate] = useState(new Date()),
		datePickerID = useId();

	return (
		<div className="react-datepicker__container">
			{labelText && <Label htmlFor={`react-datepicker${datePickerID}`}>{labelText}</Label>}
			<DatePicker
				id={`react-datepicker${datePickerID}`}
				selected={startDate}
				onChange={date => setStartDate(date)}
				minDate={new Date()}
				dateFormat="dd/MM/yyyy"
				includeDateIntervals={[{ start: addDays(new Date(), 1), end: addDays(new Date(), 91) }]}
				{...restProps}
			/>
		</div>
	);
};

export default DatePickerInput;
