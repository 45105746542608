import { useId, forwardRef } from 'react';
import Label from '../label';

import './style.scss';

const InputBox = ({ labelText, placeholderText, errorMessage, value, ...restProps }, ref) => {
	const inputID = useId();

	return (
		<div className="input-box__container">
			{labelText && <Label htmlFor={`input${inputID}`}>{labelText}</Label>}
			<input
				className="input-box form-control"
				id={`input${inputID}`}
				type="text"
				placeholder={placeholderText}
				ref={ref}
				value={value}
				{...restProps}
			/>
			{errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
		</div>
	);
};

export default forwardRef(InputBox);
