import { useId, forwardRef } from 'react';
import Label from '../label';

import './style.scss';

const SelectBox = ({ labelText, options, errorMessage, value, className, ...restProps }, ref) => {
	const inputID = useId(),
		classes = className ? `select-box__container ${className}` : 'select-box__container';

	return (
		<div className={classes}>
			{labelText && <Label htmlFor={`input${inputID}`}>{labelText}</Label>}
			<select className="select-box form-select" id={`input${inputID}`} ref={ref} value={value} {...restProps}>
				{options.map((option, index) => (
					<option key={`option${index}`} value={option.value}>
						{option.text}
					</option>
				))}
			</select>
			{errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
		</div>
	);
};

export default forwardRef(SelectBox);
