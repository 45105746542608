import { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
//import { useSelector } from 'react-redux';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { SwitchLanguage } from '../../../store/slices/userSlice';
//import OnBoardBG from '../../../assets/images/OnBoarding/loginpeople.png';
//import MobileOnboardBg from '../../../assets/images/OnBoarding/mobileOnboardBg.png';
import LoginHeader from '../../Headers/LoginHeader';
import HttpHelper from '../../../helpers/HttpHelper';
import SelectBox from '../../common/selectBox';
import InputBox from '../../common/inputBox';
import InlineLoader from '../../common/inlineLoader';
import Loader from '../../common/Loader';
import Footer from '../../../components/footer/footer';
import Banner from '../../Headers/Banner';
import './ConfirmDetails.scss';
import TrackActivity from '../../../helpers/TrackActivity';
import htmlParser from 'html-react-parser';
//import Image from '../../common/image';
const ConfirmDetails = props => {
	const [languages, setLanguages] = useState([]);
	const [cmsLanguages, setCmsLanguages] = useState('');
	const [profileLanguages, setProfileLanguages] = useState('');
	const { instance, accounts, inProgress } = useMsal();
	const [profileData, setProfileData] = useState(null);
	const http = new HttpHelper();
	const tracker = new TrackActivity();
	const fieldDisabled = '';
	const profileDataBackup = useRef(null);
	const submitBtnRef = useRef(null);
	const navigate = useNavigate();
	const langTexts = {};
	const [loader, setLoader] = useState(false);
	const [confirmDetailsData, setConfirmDetailsData] = useState(null);
	const [formLabels, setFormLabels] = useState(null);
	const [richTexts, setRichTexts] = useState([]);
	const [peopleBanner, setPeopleBanner] = useState(null);
	const get = require('get-value');
	//const userSelectedLanguage = useSelector(state => state?.userSlice?.userSelectedlanguage);

	useEffect(() => {
		if (accounts.length > 0 && inProgress === InteractionStatus.None) {
			getAccessToken();
			//navigate('/');
		}
	}, [accounts, inProgress]);

	const getAccessToken = async () => {
		const accessTokenRequest = {
			scopes: ['openid', 'profile'],
			account: accounts[0]
		};
		await instance.acquireTokenSilent(accessTokenRequest).then(tokenResponse => {
			window.sessionStorage.setItem('AccessToken', tokenResponse.idToken);
			let idTokenClaims = tokenResponse?.idTokenClaims;
			window.sessionStorage.setItem('setIdTokenClaims', JSON.stringify(idTokenClaims));
			if (tokenResponse.idToken) {
				let UserId = window.sessionStorage.getItem('UserId');
				getProfileData(UserId).catch(error => {
					console.warn(error); //eslint-disable-line
				});
				getLanguages(UserId).catch(error => {
					console.warn(error); //eslint-disable-line
				});
			}
		});
	};

	const getProfileData = async UserId => {
		const { data } = await http.Get('User-Profile/client/' + UserId + '/profile');
		profileDataBackup.current = data;
		setProfileData(data);
		if (data.IsRegistered) {
			navigate('/home');
		}
	};

	const getLanguages = async UserId => {
		const { data } = await http.Get('User-Profile/client/' + UserId + '/language');
		//setLanguages(data);
		setProfileLanguages(data);
	};

	const langOptions = useMemo(
		() =>
			languages
				? languages.map(ele => {
						let languageDetails = profileLanguages.find(o => o.LanguageCode.toLowerCase() === ele?.Title.toLowerCase());
						return {
							text: langTexts[ele?.Title] || ele?.Phrase,
							value: languageDetails.LanguageID
						};
				  })
				: [],
		[languages]
	);

	const updateProfileData = e => {
		//console.log('[e.target.name]: e.target.value', e.target.name, e.target.value);
		setProfileData({ ...profileData, [e.target.name]: e.target.value });
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (e.target.checkValidity()) {
			setLoader(true);
			const formData = new FormData(e.target);
			const payload = {
				...profileData
			};

			for (const pair of formData.entries()) {
				payload[pair[0]] = pair[1];
			}
			payload['IsRegistered'] = true;
			saveProfileData(payload);
		} else {
			e.target.classList.add('was-validated');
			e.target.querySelectorAll(`:invalid`)[0].focus();
		}
	};

	const saveProfileData = async payload => {
		const userId = window.sessionStorage.getItem('UserId');
		const { data } = await http.post('User-Profile/client/editprofile', payload, 'application/json');
		if (data) {
			setLoader(false);
			profileDataBackup.current = { ...payload };
			tracker.sendActivity('ClientUserRegistration', userId, '', profileData?.EmailAddress + ' ' + 'Registered');
			navigate('/home');
		}
	};

	const getData = async () => {
		let preferredLanguage = profileLanguages.filter(lang => lang.LanguageID === profileData.LanguageId);
		//console.log('preferredLanguage', preferredLanguage);
		//props.switchLanguage(preferredLanguage[0].LanguageCode.toLowerCase());
		//console.log('languages', languages); //eslint-disable-line
		//console.log('props.userSelectedLanguage', props.userSelectedLanguage); //eslint-disable-line
		//console.log('Preferred', preferredLanguage); //eslint-disable-line
		//console.log('Preferred', preferredLanguage[0].LanguageCode.toLowerCase()); //eslint-disable-line

		let assignedLanguage = props?.userSelectedLanguage;
		if (
			preferredLanguage[0]?.LanguageCode.toLowerCase() != '' &&
			preferredLanguage[0]?.LanguageCode.toLowerCase() != undefined
		) {
			assignedLanguage = preferredLanguage[0]?.LanguageCode.toLowerCase();
		} else {
			assignedLanguage = props?.userSelectedLanguage ? props?.userSelectedLanguage : 'en';
		}

		const payload = {
			categoryname: 'Default',
			pagename: 'OnBoarding',
			language: assignedLanguage
		};
		let { data } = await http.CMSpost('PageApi/GetPageDetails', payload);
		const imageData = {
			smallImage: get(data.Pages[0], 'SmallImage'),
			mediumImage: get(data.Pages[0], 'MediumImage'),
			largeImage: get(data.Pages[0], 'LargeImage')
		};
		//console.log('imageData', imageData);
		setPeopleBanner(imageData);
		//console.log(data);

		setConfirmDetailsData(data?.Pages?.[0]);
		const { Components } = data.Pages[0];
		//console.log(get(Components['Account.PersonalDetails'], 'Labels'));
		let formLabels = {};
		let getLabels = get(Components['Account.PersonalDetails'], 'Labels');
		getLabels.map(label => {
			formLabels[label.Key] = label;
		});
		//console.log(formLabels['PersonalDetails.FirstName'].Phrase);
		setFormLabels(formLabels);

		let formRichTexts = {};
		let getRichTexts = get(Components['Account.PersonalDetails'], 'RichTexts');
		getRichTexts.map(texts => {
			formRichTexts[texts.Key] = texts;
		});
		setRichTexts(formRichTexts);
		//setConfirmFormData(get(Components['Account.PersonalDetails'], 'Account.PersonalDetails'));
	};

	useEffect(() => {
		//console.log('languages', languages);
		if (profileLanguages?.length > 0 && profileData) {
			getData().catch(error => {
				console.warn(error); //eslint-disable-line
			});
			let selectedLanguageData = profileLanguages?.filter(lang => lang.LanguageID === profileData.LanguageId);
			props.switchLanguage(selectedLanguageData[0]?.LanguageCode.toLowerCase());
		}
	}, [profileLanguages, profileData]);

	useEffect(() => {
		//console.log('props.headerPageContent', props.headerPageContent);
		if (props.headerPageContent) {
			const { Components } = props.headerPageContent.Pages[0];
			const languages = get(Components, 'Header.Language.LabelWithTitle');
			setCmsLanguages(languages);
			// if (languages?.length > 0) {
			// 	let filteredLang = languages.filter(lang => lang.Title === props.userSelectedlanguage.toUpperCase());
			// 	setDisplayLang(filteredLang[0]['Phrase']);
			// }
		}
	}, [props.headerPageContent]);

	const getDifference = (array1, array2) => {
		return array1.filter(object1 => {
			return array2.some(object2 => {
				return object1.Title.toLowerCase() === object2.LanguageCode.toLowerCase();
			});
		});
	};

	useEffect(() => {
		if (cmsLanguages.length > 0 && profileLanguages.length > 0) {
			let filteredLang = getDifference(cmsLanguages, profileLanguages);
			setLanguages(filteredLang);
		}
	}, [cmsLanguages, profileLanguages]);

	const getUpdatedData = async () => {
		const payload = {
			categoryname: 'Default',
			pagename: 'OnBoarding',
			language: props.userSelectedlanguage || 'en'
		};
		let { data } = await http.CMSpost('PageApi/GetPageDetails', payload);
		const imageData = {
			smallImage: get(data.Pages[0], 'SmallImage'),
			mediumImage: get(data.Pages[0], 'MediumImage'),
			largeImage: get(data.Pages[0], 'LargeImage')
		};

		setPeopleBanner(imageData);

		setConfirmDetailsData(data?.Pages?.[0]);
		const { Components } = data.Pages[0];

		let formLabels = {};
		let getLabels = get(Components['Account.PersonalDetails'], 'Labels');
		getLabels.map(label => {
			formLabels[label.Key] = label;
		});

		setFormLabels(formLabels);

		let formRichTexts = {};
		let getRichTexts = get(Components['Account.PersonalDetails'], 'RichTexts');
		getRichTexts.map(texts => {
			formRichTexts[texts.Key] = texts;
		});
		setRichTexts(formRichTexts);
	};
	useEffect(() => {
		if (props.userSelectedlanguage)
			getUpdatedData().catch(error => {
				console.warn(error); //eslint-disable-line
			});
	}, [props.userSelectedlanguage]);

	return (
		<>
			{loader && <Loader />}
			{formLabels && profileData ? (
				<>
					<div className="container-fluid confirm-details">
						<div className="row">
							<div className="col-md-12 col-lg-5 col-xl-6 p-0 p-md-0 p-lg-2">
								<LoginHeader backButton={''} />
								<div className="d-lg-none">
									{confirmDetailsData?.Title && <Banner bannerTitle={confirmDetailsData?.Title} />}
								</div>
								<form
									onSubmit={handleSubmit}
									className="needs-validation p-4 p-md-5 p-lg-4 p-xl-5 mt-xl-0 mt-lg-5"
									id="confirmDetailsForm"
									noValidate>
									<h1 className="pageLabel">{confirmDetailsData?.Title}</h1>
									<p className="pageDescription">{confirmDetailsData?.Description}</p>
									<div className="col-12 col-md-6 col-lg-12 col-xl-8 mb-3">
										<InputBox
											labelText={formLabels['PersonalDetails.FirstName']?.Phrase}
											name={'FirstName'}
											value={profileData?.FirstName}
											onChange={updateProfileData}
											required
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-12 col-xl-8 mb-3">
										<InputBox
											labelText={formLabels['PersonalDetails.LastName']?.Phrase}
											name={'LastName'}
											value={profileData?.LastName}
											onChange={updateProfileData}
											required
											disabled={fieldDisabled}
										/>
									</div>
									<div className="col-12 col-md-6 col-lg-12 col-xl-8 mb-3">
										<InputBox
											labelText={formLabels['PersonalDetails.Email']?.Phrase}
											name={'EmailAddress'}
											value={profileData?.EmailAddress}
											onChange={updateProfileData}
											required
											disabled={'disabled'}
										/>
									</div>
									{/* <div className="col-12 col-md-6 col-lg-12 col-xl-8 mb-3">
										<InputBox
											labelText={formLabels['PersonalDetails.Contact']?.Phrase}
											name={'ContactNumber'}
											value={profileData?.ContactNumber}
											onChange={updateProfileData}
											disabled={fieldDisabled}
											pattern="^((\+91[0-9]{10,15})|(0[0-9]{10,15}))$"
										/>
									</div> */}
									<div className="col-12 col-md-6 col-lg-12 col-xl-8 mb-3">
										<SelectBox
											labelText={formLabels['PersonalDetails.LanguagePreference']?.Phrase}
											name={'LanguageId'}
											value={profileData?.LanguageId}
											options={langOptions}
											onChange={updateProfileData}
											required
											disabled={fieldDisabled}
										/>
									</div>
									<div className="col-12 mb-3 mt-3 text-md-start text-center">
										<label className="form-check-label already">
											{htmlParser(
												richTexts['PersonalDetails.TermsAndCondition']?.Value.replace(
													new RegExp('#ApplicationUrl#', 'g'),
													''
												)
											)}
										</label>
									</div>
									<div className="d-grid gap-2 d-md-block">
										<button type="submit" className={`btn btn-primary confirmBtn rounded-pill`} ref={submitBtnRef}>
											{formLabels['PersonalDetails.CompleteRegistration']?.Phrase}
										</button>
									</div>
								</form>
							</div>
							<div className="col-md-5 col-lg-7 col-xl-6 pe-0 d-none d-lg-block bgimg">
								<img
									className="img-fluid imt float-end"
									src={peopleBanner?.mediumImage?.ImageUrl}
									alt="People Banner Image"
								/>
								{/* <Image imageData={peopleBanner} /> */}
							</div>
						</div>
					</div>
					<div className="container-fluid confirm-details p-0">
						<Footer />
					</div>
				</>
			) : (
				<InlineLoader />
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		headerPageContent: state.userSlice.headerPageContent
	};
};

const mapDispatchToProps = dispatch => {
	return {
		switchLanguage: shortLang => dispatch(SwitchLanguage(shortLang))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDetails);
