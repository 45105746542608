import Modal from 'react-bootstrap/Modal';
import Button from '../button';
import './Modalpopup.scss';
import imgCloseButton from '../../../assets/images/btn-close.png';

export default function Modalpopup(props) {
	const { modalSize, onClose, closeButtonVariant, ...restProps } = props,
		classes = `${closeButtonVariant ? 'modal--close-button' : ''}`;

	return (
		<Modal size={modalSize} className={classes} aria-labelledby="contained-modal-title-vcenter" {...restProps} centered>
			{closeButtonVariant && (
				<Button buttonClass="button--img align-self-end modal__close-btn" onClickHandler={onClose}>
					<img src={imgCloseButton} alt="close" />
				</Button>
			)}
			{props.children}
			{closeButtonVariant && <div className="bottom d-none d-md-block"></div>}
		</Modal>
	);
}
