import React, { useRef, useState, useEffect } from 'react';
import PageLayout from '../../components/pageLayout/pageLayout';
import HttpHelper from '../../helpers/HttpHelper';
//import GroupA from '../../components/dashboards/GroupA';
//import GroupB from '../../components/dashboards/GroupB';
import GroupA from '../../components/dashboards/GroupA';
import GroupB from '../../components/dashboards/GroupB';
import Loader from '../../components/common/Loader';
import environment from '../../components/common/environments/environments';
import { Reset } from '../../store/slices/userSlice';
import { connect } from 'react-redux';
import './style.scss';
import TrackActivity from '../../helpers/TrackActivity';
const get = require('get-value');

const Dashboard = props => {
	const [pagedata, setPageData] = useState(null);
	const [tabTitles, setTabTitles] = useState(null);
	const [groupAPageData, setGroupAPageData] = useState(null);
	const [groupBPageData, setGroupBPageData] = useState(null);
	const [groupAConfig, setGroupAConfig] = useState(null);
	const [groupBConfig, setGroupBConfig] = useState(null);
	//const [enableGroupA, setEnableGroupA] = useState(true);
	const [enableGroupB, setEnableGroupB] = useState(false);
	const http = new HttpHelper();
	const tracker = new TrackActivity();
	const shouldLog = useRef(true);
	const payload = {
		categoryname: 'Default',
		pagename: 'Dashboard',
		language: props.userSelectedlanguage.toLowerCase() || 'en'
	};
	const getData = async () => {
		await http
			.CMSpost('PageApi/GetPageDetails', payload)
			.then(res => {
				setPageData({
					dashboard: res.data?.Pages?.[0]
				});
				let tabLabels = [];
				get(res.data?.Pages?.[0], 'Components.Dashboards.Submenu.Labels').forEach(ele => {
					tabLabels[ele.Key] = ele.Phrase;
				});
				setTabTitles(tabLabels);

				let groupAData = [];
				groupAData['title'] = get(res.data?.Pages?.[0], 'Components.Dashboards.GroupA.Title');
				groupAData['description'] = get(res.data?.Pages?.[0], 'Components.Dashboards.GroupA.Description');
				let groupAJumptoLabels = [];
				get(res.data?.Pages?.[0], 'Components.Dashboards.GroupA.Labels').forEach(ele => {
					groupAJumptoLabels[ele.Key] = ele.Phrase;
				});
				groupAData['jumptolables'] = groupAJumptoLabels;
				groupAData['jumptotitle'] = Object.keys(tabLabels).length > 0 ? tabLabels['Dashboard.Common.JumpTo'] : '';
				setGroupAPageData(groupAData);

				let groupBData = [];
				groupBData['title'] = get(res.data?.Pages?.[0], 'Components.Dashboards.GroupB.Title');
				groupBData['description'] = get(res.data?.Pages?.[0], 'Components.Dashboards.GroupB.Description');
				let groupBJumptoLabels = [];
				get(res.data?.Pages?.[0], 'Components.Dashboards.GroupB.Labels').forEach(ele => {
					groupBJumptoLabels[ele.Key] = ele.Phrase;
				});
				groupBData['jumptolables'] = groupBJumptoLabels;
				groupBData['jumptotitle'] = Object.keys(tabLabels).length > 0 ? tabLabels['Dashboard.Common.JumpTo'] : '';
				//console.log('groupBData', groupBData);
				setGroupBPageData(groupBData);
			})
			.catch(error => {
				return error;
			});
	};
	const getDashboardConfig = async () => {
		let payload = {};
		await http
			.Get(environment.DASHBOARD_CONFIG + '/' + environment.BRAND_ID + '/dashboardconfig', payload)
			.then(res => {
				//console.log('res', res.data);
				if (res.data) {
					let groupAData = [];
					let groupBData = [];

					res.data.map(configData => {
						if (configData.Name.includes('GROUPA')) {
							groupAData[configData.Name] = configData.TypeId;
						} else if (configData.Name.includes('GROUPB')) {
							groupBData[configData.Name] = configData.TypeId;
						} else if (configData.Name.includes('WORKSPACE')) {
							groupAData['WORKSPACE_ID'] = configData.TypeId;
							groupBData['WORKSPACE_ID'] = configData.TypeId;
						}
					});
					//console.log('groupAData', groupAData);
					setGroupAConfig(groupAData);
					setGroupBConfig(groupBData);
				}
			})
			.catch(error => {
				return error;
			});
	};
	useEffect(() => {
		document.title = 'Dashboard';
		if (shouldLog.current) {
			getData();
			getDashboardConfig();
			props.reset();
			shouldLog.current = false;
		}
	}, []);

	useEffect(() => {
		if (props.isLangChanged) {
			getData();
			props.reset();
		}
	}, [props.isLangChanged, props.userSelectedlanguage]);
	return (
		<>
			{pagedata ? (
				<PageLayout containerClass="" bannerTitle={pagedata.dashboard?.Title} data={pagedata.dashboard}>
					<div className="container-fluid-md container-lg p-lg-0 dashboard__container">
						<div className="tabs">
							<ul className="nav nav-tabs" id="myTab" role="tablist">
								{/* <li className="nav-item" role="presentation">
								<button
									className="nav-link active"
									id="home-tab"
									data-bs-toggle="tab"
									data-bs-target="#home"
									type="button"
									role="tab"
									aria-controls="home"
									aria-selected="true">
									Group A
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="profile-tab"
									data-bs-toggle="tab"
									data-bs-target="#profile"
									type="button"
									role="tab"
									aria-controls="profile"
									aria-selected="false">
									Group B
								</button>
							</li> */}
								<li className="nav-item" role="presentation">
									<button
										onClick={() => {
											let userId = window.sessionStorage.getItem('UserId');
											tracker.sendActivity('UserVisitDashboard-A', userId, userId, 'Dashboard-A');
										}}
										className="nav-link active"
										id="profile-tab"
										data-bs-toggle="tab"
										data-bs-target="#groupc"
										type="button"
										role="tab"
										aria-controls="profile"
										aria-selected="false">
										{tabTitles['Dashboard.Submenu.GroupA']}
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button
										onClick={() => {
											let userId = window.sessionStorage.getItem('UserId');
											tracker.sendActivity('UserVisitDashboard-B', userId, userId, 'Dashboard-B');
											setEnableGroupB(true);
										}}
										className="nav-link"
										id="profile-tab"
										data-bs-toggle="tab"
										data-bs-target="#groupd"
										type="button"
										role="tab"
										aria-controls="profile"
										aria-selected="false">
										{tabTitles['Dashboard.Submenu.GroupB']}
									</button>
								</li>
							</ul>
							<div className="tab-content dasboardTab" id="myTabContent">
								<div className="tab-pane fade show active" id="groupc" role="tabpanel" aria-labelledby="profile-tab">
									{groupAPageData && (
										<GroupA pagedata={groupAPageData} groupAConfig={groupAConfig} tabTitles={tabTitles} />
									)}
								</div>
								<div className="tab-pane fade" id="groupd" role="tabpanel" aria-labelledby="profile-tab">
									{enableGroupB && groupBPageData && (
										<GroupB pagedata={groupBPageData} groupBConfig={groupBConfig} tabTitles={tabTitles} />
									)}
								</div>
							</div>
						</div>
					</div>
				</PageLayout>
			) : (
				<Loader />
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		headerPageContent: state.userSlice.headerPageContent,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
