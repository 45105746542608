//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------
import { useNavigate } from 'react-router-dom';
import './errorHandler.scss';
function ErrorHandler() {
	const navigate = useNavigate();
	return (
		<div className="container-fluid containerErrorHandler d-flex justify-content-center align-items-center">
			<div className="row errorContent text-center d-flex align-items-center">
				<div className="col-12">
					<h2 className="headerText text-center">Oops, We cannot find that page.</h2>
					<p className="pageDescription">
						The page that you are looking for might have been removed or is temporarily unavailable. Click the button to
						return to your dashboard.
					</p>
				</div>
				<div className="col-12">
					<button
						className="btn btn-secondary rounded-pill backtohome"
						type="button"
						id="backButton"
						onClick={() => navigate('/home')}>
						Back
					</button>
				</div>
			</div>
		</div>
	);
}

export default ErrorHandler;
