import { useContext } from 'react';
import { AccoutTeamContext } from '../../helpers/contexts';
import Button from '../common/button';
import defaultImg from '../../assets/images/default-member-img.png';

import './style.scss';

const TeamMemberTile = ({ image, name, position, onClickHandler }) => {
	const data = useContext(AccoutTeamContext),
		{ pageDataAccountTeam } = data;
	//https://media-client-portal.right.com/cirrusprofile/9fc87513-6961-4952-a2f6-1e049ebb8748.jpg?sv=2018-03-28&sr=c&sig=%2FTIhlq89maH3k0j5jaI8oD8%2FXC8aeJh1qxbBJeuK1I0%3D&st=2024-02-16T05%3A56%3A16Z&se=2024-02-16T06%3A06%3A16Z&sp=rw
	return (
		<div className="row team-member-tile">
			<div className="col-3 col-md-12  team-member-tile__image">
				<img src={image ? image : defaultImg} alt={name} />
				<div
					className="team-member-tile__view d-none d-md-block"
					onClick={onClickHandler}
					role="button"
					tabIndex={0}
					aria-label="View Bio">
					<span className="team-member-tile__view-sign">+</span>
					<span className="team-member-tile__view-text">{pageDataAccountTeam?.Labels?.[8]?.Phrase}</span>
				</div>
			</div>
			<div className="col-6 col-md-12">
				<div className="team-member-tile__name">{name}</div>
				<div className="team-member-tile__position">{position}</div>
			</div>
			<div className="col-2 d-md-none team-member-tile__button">
				<Button buttonClass="button--white" onClickHandler={onClickHandler}>
					{pageDataAccountTeam?.Labels?.[8]?.Phrase}
				</Button>
			</div>
		</div>
	);
};

export default TeamMemberTile;
