import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from '../../components/pageLayout/pageLayout';
//import Testviewer from '../../components/common/DocViewer/tindex';
//import ReportViewBanner from '../../assets/images/ReportViewBanner.png';
//import ReportViewFooterBanner from '../../assets/images/ReportViewFooterBanner.png';
//import UserEllipse from '../../assets/images/UserEllipse.png';
import httpHelper from '../../helpers/HttpHelper';
import { byteConverter } from '../../helpers/constants';
import environments from '../../components/common/environments/environments'; // eslint-disable-line
import Loader from '../../components/common/Loader';
import ReportView from '../../components/reportsAndResourcesView';
import Image from '../../components/common/image';
import InlineLoader from '../../components/common/inlineLoader';
import { connect } from 'react-redux';
import { Reset } from '../../store/slices/userSlice';
const get = require('get-value');
const ReportsAndResourcesView = props => {
	let { reportUrl, resourceView, resourceTypeId } = useParams();
	const http = new httpHelper(); // eslint-disable-line
	const [viewData, setViewData] = useState('');
	const [embedBlob, setEmbedBlob] = useState(null);
	const [viewType, setViewType] = useState();
	const [reportFileSize, setReportFileSize] = useState();
	const [testpdf, setTestpdf] = useState();
	const [cmsReportResourcesViewPage, setCmsReportResourcesViewPage] = useState(null);
	//console.log('resourceType', resourceType);
	let cmsReportResourceViewPagepayload = {
		categoryname: 'Default',
		pagename: 'Resources',
		language: props.userSelectedlanguage?.toLowerCase() || 'en'
	};
	const getReportsResourceViewPageContent = async () => {
		//console.log('asdfastestteset');
		try {
			//console.log('yyyyyyy', cmsReportResourceViewPagepayload);
			const res = await http.CMSpost(environments.REFERRAL_PAGE_API_CMS, cmsReportResourceViewPagepayload);
			if (res.status === 200) {
				const data = res?.data?.Pages[0];
				let cmsData = [];
				let labels = get(data, 'Components.ResourceCommon.Component.Labels');
				const imageData = {
					smallImage: data.SmallImage,
					mediumImage: data.MediumImage,
					largeImage: data.LargeImage
				};
				let cmsLabels = [];
				labels.map(y => {
					cmsLabels[y.Key] = y.Phrase;
				});
				cmsData['labels'] = cmsLabels;
				cmsData['imageData'] = imageData;
				//console.log('labels', cmsData);
				setCmsReportResourcesViewPage(cmsData);
			} else {
				return [];
			}
		} catch (error) {
			//console.log(error);
			return [];
		}
	};

	useEffect(() => {
		if (reportUrl) {
			resourceView === 'resource' ? resourceDetails(reportUrl) : detailsReport(reportUrl, resourceTypeId);
		}
		//console.log('ffffff');
		getReportsResourceViewPageContent();
	}, [reportUrl]);

	useEffect(() => {
		if (props.isLangChanged) {
			getReportsResourceViewPageContent();
			if (reportUrl) {
				resourceDetails(reportUrl);
			}
			props.reset();
		}
	}, [props.isLangChanged, props.userSelectedlanguage]);

	/* eslint-disable */
	const detailsReport = async (resourceId, resourceTypeId) => {
		if (resourceId) {
			const detailsResult = http
				.Get(environments.DETAILS_REPORT_API + '/' + resourceId + '/' + environments.BRAND_ID + '/' + resourceTypeId)
				.then(res => {
					if (res?.data) {
						setViewData(res?.data?.Title);
						// if (resourceType == 'ProgramDescriptor') {
						// 	viewProgramDescriptorReport(res?.data, resourceType);
						// } else {
						// 	viewReport(resourceId, '');
						// }
						viewReport(resourceId, '', resourceTypeId);
					}
				})
				.catch(error => {
					//setLoader(false);
					return error;
				});
		}
	};
	/* eslint-enable */

	const resourceDetails = resourceID => {
		if (resourceID) {
			const payload = {
				categoryname: 'Default',
				language: props.userSelectedlanguage?.toLowerCase() || 'en',
				Id: resourceID
			};
			http
				.post(
					'Reports-Resources/thoughtleadershipresourceDetails/561A49E2-8642-EE11-B8F0-00224882FA39/' +
						environments.BRAND_ID +
						'/view',
					payload,
					'application/json'
				)
				.then(res => {
					if (res?.data) {
						setViewData(res?.data?.PageContentValues);
						viewReport(resourceID, res?.data?.PageContentValues?.OtherMediaField, '');
					}
				})
				.catch(error => {
					//setLoader(false);
					return error;
				});
		}
	};

	/* eslint-disable */
	const viewReport = async (filePath, resourceMedia, resourceTypeId) => {
		const mediaURL =
			resourceView === 'resource'
				? resourceMedia
				: environments.DOWNLOAD_REPORT_API + '/' + filePath + '/' + environments.BRAND_ID + '/' + resourceTypeId;

		//setLoader(true);
		if (filePath && resourceView !== 'resource') {
			http
				.documentsDownloadGet(mediaURL)
				.then(res => {
					console.log('reportandresourcesdown', res);
					//setLoader(false);
					if (res.headers['content-type']) {
						let contentTypeHeader = res.headers['content-type'];
						let blob = new Blob([res.data], {
							type: contentTypeHeader.toLowerCase(),
							charset: 'UTF-8'
						});
						const fileDetails = new File([blob], 'sampledocument');
						setReportFileSize(byteConverter(fileDetails['size']));

						if (contentTypeHeader === 'application/pdf') {
							setViewType('PDF');
							let pptxviewURL = process.env.REACT_APP_API_URL + mediaURL;
							//setEmbedBlob(pptxviewURL);
							setEmbedBlob(res);
							setTestpdf(pptxviewURL);
						} else if (
							contentTypeHeader === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
						) {
							setViewType('DOCX');
							let pptxviewURL = process.env.REACT_APP_API_URL + mediaURL;
							setEmbedBlob(res);
							//setEmbedBlob(res);
						} else if (contentTypeHeader === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
							setViewType('XLSX');
							let pptxviewURL = process.env.REACT_APP_API_URL + mediaURL;
							setEmbedBlob(res);
						} else if (
							contentTypeHeader === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
						) {
							setViewType('PPTX');
							let pptxviewURL = process.env.REACT_APP_API_URL + mediaURL;
							setEmbedBlob(pptxviewURL);
						}
					} else {
						setViewType('ERROR');
						setEmbedBlob('ERROR');
					}
				})
				.catch(error => {
					//setLoader(false);
					console.log('err', error);
					setViewType('ERROR');
					setEmbedBlob('ERROR');
					return error;
				});
		}
	};
	/* eslint-enable */

	/* eslint-disable */
	const viewProgramDescriptorReport = async (data, resourceType) => {
		const mediaURL = environments.DOWNLOAD_PROGRAMDESCRIPTOR_API;
		console.log('datallllll', data);
		//setLoader(true);

		http
			.documentsDownloadGet(mediaURL)
			.then(res => {
				console.log('reportandresourcesdown', res);
				//setLoader(false);
				if (res.headers['content-type']) {
					let contentTypeHeader = res.headers['content-type'];
					let blob = new Blob([res.data], {
						type: contentTypeHeader.toLowerCase(),
						charset: 'UTF-8'
					});
					const fileDetails = new File([blob], 'sampledocument');
					setReportFileSize(byteConverter(fileDetails['size']));

					if (contentTypeHeader === 'application/pdf') {
						setViewType('PDF');
						let pptxviewURL = process.env.REACT_APP_API_URL + mediaURL;
						//setEmbedBlob(pptxviewURL);
						setEmbedBlob(res);
						setTestpdf(pptxviewURL);
					} else if (contentTypeHeader === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
						setViewType('DOCX');
						let pptxviewURL = process.env.REACT_APP_API_URL + mediaURL;
						setEmbedBlob(res);
						//setEmbedBlob(res);
					} else if (contentTypeHeader === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
						setViewType('XLSX');
						let pptxviewURL = process.env.REACT_APP_API_URL + mediaURL;
						setEmbedBlob(res);
					} else if (
						contentTypeHeader === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
					) {
						setViewType('PPTX');
						let pptxviewURL = process.env.REACT_APP_API_URL + mediaURL;
						setEmbedBlob(pptxviewURL);
					}
				} else {
					setViewType('ERROR');
					setEmbedBlob('ERROR');
				}
			})
			.catch(error => {
				//setLoader(false);
				console.log('err', error);
				setViewType('ERROR');
				setEmbedBlob('ERROR');
				return error;
			});
	};
	/* eslint-enable */

	return (
		<>
			{embedBlob || resourceView === 'resource' ? (
				<PageLayout showFooter={false}>
					<div className="container-fluid">
						{viewData ? (
							<ReportView
								reportTitle={resourceView === 'resource' ? viewData?.ResourceOverivew?.Title : viewData}
								reportFileSize={reportFileSize}
								embedBlob={embedBlob}
								reportUrl={reportUrl}
								resourceTypeId={resourceTypeId}
								viewType={viewType}
								viewData={viewData}
								resourceView={resourceView}
								testpdfUrl={testpdf}
								cmsReportResourcesViewPage={cmsReportResourcesViewPage}
							/>
						) : (
							<InlineLoader />
						)}
					</div>

					{resourceView && viewData && (
						<div className="resource-view__footer-view mt-4 mt-md-5">
							<Image
								imageData={{
									smallImage: viewData?.ResourceOverivew?.LargeImage,
									mediumImage: viewData?.ResourceOverivew?.LargeImage,
									largeImage: viewData?.ResourceOverivew?.LargeImage
								}}
							/>
						</div>
					)}
				</PageLayout>
			) : (
				<Loader />
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsAndResourcesView);
