import { useEffect, useState } from 'react';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import './excelViewer.scss';
export default function excelViewer({ embedBlob }) {
	const [cols, setCols] = useState();
	const [rows, setRows] = useState();
	useEffect(() => {
		let contentTypeHeader = embedBlob.headers['content-type'];
		var blob = new Blob([embedBlob.data], {
			type: contentTypeHeader.toLowerCase(),
			charset: 'UTF-8'
		});
		const fileObj = new File([blob], 'document');
		ExcelRenderer(fileObj, (err, resp) => {
			if (err) {
				//console.log(err);
			} else {
				const columns = [{ name: '', key: 0 }];
				resp.cols.map(item => {
					columns.push({ name: item.name, key: item.key + 1 });
					return null;
				});
				setCols(columns);
				setRows(resp.rows);
			}
		});
	}, [embedBlob]);
	return (
		<>
			{rows && (
				<div className="restrict-card">
					<OutTable data={rows} columns={cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
				</div>
			)}
		</>
	);
}
