import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DownlodIcon from '../../../assets/images/DownlodIcon.png';
import httpHelper from '../../../helpers/HttpHelper';
import Loader from '../../common/Loader';
//import * as XLSX from 'xlsx';

export default function ReferralTemplate(props) {
	const navigate = useNavigate();
	const http = new httpHelper(); // eslint-disable-line
	const [pageLoader, setPageLoader] = useState(false);

	const downloadTemplate = (templatePath, templateName) => {
		setPageLoader(true);
		http
			.templateDownloadGet(templatePath)
			.then(res => {
				setPageLoader(false);
				let contentTypeHeader = res.headers['content-type'];
				var blob = new Blob([res.data], {
					type: contentTypeHeader.toLowerCase(),
					charset: 'UTF-8'
				});
				let fileName = templateName.replace(' ', '_');
				saveAs(blob, `${fileName}.xlsx`);

				//const filedata = new File([blob], `${fileName}.xlsx`);

				//console.log('filedata', filedata);
				//handleExcelFile(filedata);
			})
			.catch(error => {
				setPageLoader(false);
				return error;
			});
	};
	// const handleExcelFile = files => {
	// 	let f = files;
	// 	const reader = new FileReader();
	// 	reader.onload = files => {
	// 		const bstr = files.target.result;
	// 		const wb = XLSX.read(bstr, { type: 'binary' });
	// 		const wsname = wb.SheetNames[0];
	// 		const ws = wb.Sheets[wsname];
	// 		const dataExceljson = XLSX.utils.sheet_to_json(ws);
	// 		console.log('dataExceljson', dataExceljson);
	// 	};
	// 	reader.readAsBinaryString(f);
	// };
	return (
		<>
			{pageLoader && <Loader />}
			{props.templateData && (
				<div className="row mt-5 p-sm-3 p-md-2 p-lg-1 p-xl-0 border-bottom">
					<div className="col-12 col-lg-6 p-0 p-sm-0 p-md-2 p-lg-1 leftPanel">
						<div className="col-12">
							<p className="title">{props.templateData?.title}</p>
						</div>
						<div className="col-12">
							<p className="description">{props.templateData?.description}</p>
						</div>
						<div className="col-12 col-lg-12 mx-auto mx-sm-0 mx-md-0 mx-lg-auto">
							<div className="dropdown template-dropdown">
								<button
									className="btn btn-primary downloadReferralBtn rounded-pill"
									type="button"
									//id="downloadReferralDropdown"
									//aria-expanded="false"
									aria-label={props.templateData?.['Referrals.CandidateReferrals.DownloadReferralTemplate']}
									onClick={() =>
										downloadTemplate(
											props.templateData?.templates['Referrals.CandidateReferrals.GlobalTempalte'].OtherMediaUrl,
											props.templateData?.templates['Referrals.CandidateReferrals.GlobalTempalte'].Phrase
										)
									}>
									<img src={DownlodIcon} alt="Download Icon" />
									{props.templateData?.['Referrals.CandidateReferrals.DownloadReferralTemplate']}
								</button>

								{/* <ul className="dropdown-menu" aria-labelledby="downloadReferralDropdown">
									{props.templateData?.templates &&
										Object.keys(props.templateData?.templates).map(key => (
											<li key={key}>
												<a
													id={key}
													className="dropdown-item"
													onClick={() =>
														downloadTemplate(
															props.templateData?.templates[key].OtherMediaUrl,
															props.templateData?.templates[key].Phrase
														)
													}>
													{props.templateData?.templates[key].Phrase}
												</a>
											</li>
										))}
								</ul> */}
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-1"></div>
					<div className="col-12 col-lg-5 mt-5 mb-5 mt-lg-0 rightPanel">
						<p className="title">{props.templateData?.['Referrals.CandidateReferrals.CreateNewReferrals']}</p>
						<p className="description">{props.templateData?.createnewReferralDesc}</p>
						<div className="col-12 col-lg-12 mx-auto">
							<button
								type="button"
								onClick={() => navigate(`${props.templateData?.createNewBtn?.link}`)}
								className="btn btn-primary confirmBtn newReferralBtn rounded-pill">
								{props.templateData?.createNewBtn?.label}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
