import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import HttpHelper from '../../helpers/HttpHelper';
import Loader from '../../components/common/Loader';
import { b2cPolicies } from '../../helpers/AuthService';
import { EventType } from '@azure/msal-browser';
import { SwitchLanguage } from '../../store/slices/userSlice';
import TrackActivity from '../../helpers/TrackActivity';

const Landing = props => {
	const { instance, accounts, inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [profileData, setProfileData] = useState(null);
	const [profileLanguages, setProfileLanguages] = useState('');
	const navigate = useNavigate();
	const http = new HttpHelper();
	const tracker = new TrackActivity();

	useEffect(() => {
		async function signin() {
			if (!isAuthenticated && inProgress === InteractionStatus.None) {
				//debugger; //eslint-disable-line
				await instance.loginRedirect().catch(error => {
					console.log('LoginError', error); //eslint-disable-line
				});
			}
		}
		signin();
	}, [isAuthenticated, inProgress, instance]);

	useEffect(() => {
		if (
			accounts.length > 0 &&
			isAuthenticated &&
			inProgress === InteractionStatus.None &&
			(sessionStorage.getItem('REDIRECTTO') == '' || sessionStorage.getItem('REDIRECTTO') == null)
		) {
			getAccessToken();
		} else {
			if (inProgress === InteractionStatus.None && sessionStorage.getItem('REDIRECTTO') == 'RESET') {
				sessionStorage.setItem('REDIRECTTO', '');
				localStorage.removeItem('adminMessages');
				localStorage.removeItem('userLanguage');
				localStorage.clear();
				sessionStorage.clear();
				instance.logoutRedirect({
					postLogoutRedirectUri: '/'
				});
			}
		}
	}, [accounts, inProgress]);

	useEffect(() => {
		const callbackId = instance.addEventCallback(event => {
			console.log('event.error', event); //eslint-disable-line
			if (event.eventType === EventType.LOGIN_FAILURE) {
				if (String(event.error).includes('AADB2C90118')) {
					if (inProgress === InteractionStatus.HandleRedirect)
						instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
				} else if (String(event.error).includes('AADB2C99072')) {
					console.log('CLAIM MISMATCH'); //eslint-disable-line
				}
			}
		});

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, [inProgress, instance]);

	const getAccessToken = async () => {
		const accessTokenRequest = {
			scopes: ['openid', 'profile'],
			account: accounts[0]
		};
		await instance
			.acquireTokenSilent(accessTokenRequest)
			.then(tokenResponse => {
				//debugger; //eslint-disable-line
				window.sessionStorage.setItem('AccessToken', tokenResponse.idToken);
				let idTokenClaims = tokenResponse?.idTokenClaims;
				window.sessionStorage.setItem('setIdTokenClaims', JSON.stringify(idTokenClaims));
				console.log('tokenResponse', tokenResponse); //eslint-disable-line
				if (tokenResponse.idToken) {
					let tokenDetails = jwt_decode(tokenResponse.idToken);
					//console.log('tokenDetails', tokenDetails);
					const userId = window.sessionStorage.getItem('UserId');
					if (!userId) {
						tracker.sendActivity(
							'ClientUserLogin',
							tokenDetails?.Id,
							'',
							tokenDetails?.EmailAddress + ' ' + 'Logged In'
						);
					}
					//debugger; //eslint-disable-line
					//console.log('tokenDetails', tokenDetails); //eslint-disable-line
					window.sessionStorage.setItem('UserId', tokenDetails.Id);
					window.sessionStorage.setItem('UserEmail', tokenDetails.EmailAddress);
					window.sessionStorage.setItem('UserLang', tokenDetails.PreferredLanguage);
					window.sessionStorage.setItem('ClientAccId', tokenDetails.ClientAccountID);
					getProfileData(tokenDetails.Id).catch(error => {
						console.warn(error); //eslint-disable-line
					});
					getLanguages(tokenDetails.Id).catch(error => {
						console.warn(error); //eslint-disable-line
					});
				}
			})
			.catch(err => {
				console.log('AUTH TOKEN ERROR:', err); //eslint-disable-line
				sessionStorage.setItem('REDIRECTTO', '');
				localStorage.removeItem('adminMessages');
				localStorage.removeItem('userLanguage');
				localStorage.clear();
				sessionStorage.clear();
				instance.logoutRedirect({
					postLogoutRedirectUri: '/'
				});
			});
	};

	const getProfileData = async UserId => {
		const { data } = await http.Get('User-Profile/client/' + UserId + '/profile');
		setProfileData(data);
	};

	const getLanguages = async UserId => {
		const { data } = await http.Get('User-Profile/client/' + UserId + '/language');
		//setLanguages(data);
		setProfileLanguages(data);
	};

	useEffect(() => {
		if (profileLanguages.length > 0 && profileData) {
			if (profileData.IsRegistered) {
				let selectedLanguageData = profileLanguages.filter(lang => lang.LanguageID === profileData.LanguageId);
				if (selectedLanguageData[0]) {
					//console.log('SET USER LANG', selectedLanguageData[0].LanguageCode.toLowerCase());
					props.switchLanguage(selectedLanguageData[0].LanguageCode.toLowerCase());
					localStorage.setItem('userLanguage', selectedLanguageData[0].LanguageCode.toLowerCase());
				}
				//const userId = window.sessionStorage.getItem('UserId');
				//tracker.sendActivity('ClientUserLogin', userId, '', profileData?.EmailAddress + ' ' + 'Logged In');
				setTimeout(function () {
					navigate('/home');
				}, 2000);
			} else {
				navigate('/confirm-details');
			}
		}
	}, [profileLanguages, profileData]);

	return <>{<Loader />}</>;
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage
	};
};

const mapDispatchToProps = dispatch => {
	return {
		switchLanguage: shortLang => dispatch(SwitchLanguage(shortLang))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
