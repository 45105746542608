import React from 'react';
import './ErrorBox.scss';

export default function ErrorBox(props) {
	return (
		<>
			<div data-testid="errorMsg" className={props.sizing}>
				<p className="errorTitle">{props?.errorTitle}</p>
				<p className="errorDesc">{props?.errorDesc}</p>
				{props.enableBtn && props.children}
				{/* {props.enableBtn && (
					<button
						type="button"
						className={`btn btn-primary errbutton rounded-pill`}
						onClick={e => props.errorBtnAction(e)}
					>
						{props.btnLabel}
					</button>
				)} */}
			</div>
		</>
	);
}
