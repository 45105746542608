import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Docviewer from '../common/DocViewer/documentViewer';
import Pdfviewer from '../common/DocViewer/pdfViewer';
import Excelviewer from '../common/DocViewer/excelViewer';
// import Pptviewer from '../common/DocViewer/pptViewer';
import CloseIcon from '../../assets/images/CloseIcon.png';
import DownlodIcon from '../../assets/images/DownlodIconWhite.png';
import httpHelper from '../../helpers/HttpHelper';
import environments from '../common/environments/environments'; // eslint-disable-line
import Loader from '../common/Loader';
import Image from '../common/image';
import Button from '../common/button';
import TrackActivity from '../../helpers/TrackActivity';
import './reportsView.scss';

export default function reportsAndResourcesView(props) {
	const {
		reportTitle,
		reportFileSize,
		embedBlob,
		reportUrl,
		resourceTypeId,
		viewType,
		viewData,
		resourceView,
		testpdfUrl
	} = props;
	const http = new httpHelper();
	const tracker = new TrackActivity();
	const [loader, setLoader] = useState(false);
	const navigate = useNavigate();
	let userId = window.sessionStorage.getItem('UserId');

	const downloadReport = async reportUrl => {
		setLoader(true);
		if (reportUrl) {
			http
				.documentsDownloadGet(
					environments.DOWNLOAD_REPORT_API + '/' + reportUrl + '/' + environments.BRAND_ID + '/' + resourceTypeId
				)
				.then(res => {
					setLoader(false);
					let contentTypeHeader = res.headers['content-type'];
					let contentTypeName = res.headers['content-disposition'];
					let getfileName = contentTypeName.split('filename=');
					let blob = new Blob([res.data], {
						type: contentTypeHeader.toLowerCase(),
						charset: 'UTF-8'
					});
					let fileName = '';
					if (getfileName[1].includes('.xlsx.xlsx')) {
						fileName = getfileName[1].replaceAll('.xlsx.xlsx', '.xlsx');
					} else if (getfileName[1].includes('.xls.xls')) {
						fileName = getfileName[1].replaceAll('.xls.xls', '.xls');
					} else if (getfileName[1].includes('.docx.docx')) {
						fileName = getfileName[1].replaceAll('.docx.docx', '.docx');
					} else if (getfileName[1].includes('.pdf.pdf')) {
						fileName = getfileName[1].replaceAll('.pdf.pdf', '.pdf');
					} else if (getfileName[1].includes('.pptx.pptx')) {
						fileName = getfileName[1].replaceAll('.pptx.pptx', '.pptx');
					} else {
						fileName = getfileName[1];
					}
					tracker.sendActivity('CientReportResourceDownload', userId, reportUrl, reportTitle);
					saveAs(blob, `${fileName}`);
				})
				.catch(error => {
					setLoader(false);
					return error;
				});
		}
	};
	return (
		<>
			{loader && <Loader />}
			{props?.cmsReportResourcesViewPage && (
				<div className="container report-view">
					<div className="row">
						<div className="col-12 col-lg-3">
							<button
								type="button"
								data-testid="cancelReferralBtn"
								name="cancelReferral"
								id="cancelReferralButton"
								className="btn btn-primary report-view__cancel-btn rounded-pill"
								onClick={() => navigate(-1)}>
								<img className="img-fluid closeIcon" src={CloseIcon} alt="Close Icon" />
								{props?.cmsReportResourcesViewPage.labels['ResourceCommon.ButtonText.Close']}
							</button>
						</div>
						<div className="col-12 col-lg-9">
							<div className="col-12 mt-4 mt-lg-0">
								<h1>{reportTitle}</h1>
							</div>

							{resourceView && viewData && (
								<>
									{viewData?.ResourceOverivew?.CreatedBy && (
										<div className="col-12 mt-3">
											<div className="report-view__uploadedBy">
												{/* <img src={viewData?.UserProfileImg?.ImageUrl} alt={viewData?.UserProfileImg?.AltText} /> */}
												<Image
													imageData={{
														smallImage:
															viewData?.UserProfileImg?.ImageUrl != null
																? viewData?.UserProfileImg
																: props?.cmsReportResourcesViewPage?.imageData?.smallImage,
														mediumImage:
															viewData?.UserProfileImg?.ImageUrl != null
																? viewData?.UserProfileImg
																: props?.cmsReportResourcesViewPage?.imageData?.mediumImage,
														largeImage:
															viewData?.UserProfileImg?.ImageUrl != null
																? viewData?.UserProfileImg
																: props?.cmsReportResourcesViewPage?.imageData?.largeImage
													}}
												/>
												<span className="byText">
													{props?.cmsReportResourcesViewPage.labels['ResourceCommon.Text.By']}
												</span>
												<span className="byName">{viewData?.ResourceOverivew?.CreatedBy}</span>
											</div>
										</div>
									)}
									<div className="col-12 resource-view__banner my-md-5">
										<Image
											imageData={{
												smallImage: viewData?.ResourceOverivew?.SmallImage,
												mediumImage: viewData?.ResourceOverivew?.MediumImage,
												largeImage: viewData?.ResourceOverivew?.MediumImage
											}}
										/>
									</div>
								</>
							)}

							<div className="col-12 col-md-9">
								{resourceView ? (
									viewData?.MediaFileProperties?.Url != null ? (
										<Button
											linkUrl={viewData.MediaFileProperties.Url}
											target="_blank"
											rel="noreferrer"
											onClick={() => tracker.sendActivity('ClientThoughtLeadDownload', userId, reportUrl, reportTitle)}
											buttonClass="button--blue report-view__downloadReportBtn  text-start">
											<img src={DownlodIcon} className="me-2" alt="Download Icon" />
											{props?.cmsReportResourcesViewPage.labels['ResourceCommon.DownloadResource']}
											<span className="fileData">{`${viewData.MediaFileProperties.FileSize} ${viewData.MediaFileProperties.FileExtension}`}</span>
										</Button>
									) : null
								) : (
									viewType != 'ERROR' && (
										<button
											className="btn btn-primary btn-lg btn-block report-view__downloadReportBtn rounded-pill text-start"
											type="button"
											onClick={() => downloadReport(reportUrl)}>
											<img src={DownlodIcon} className="me-2" alt="Download Icon" />
											{props?.cmsReportResourcesViewPage.labels['ResourceCommon.DownloadResource']}
											<span className="fileData">
												{reportFileSize} {viewType}
											</span>
										</button>
									)
								)}
							</div>

							{!resourceView && (
								<div className="col-12 mt-4 mb-4">
									{viewType == 'PDF' && <Pdfviewer embedBlob={embedBlob} testpdfUrl={testpdfUrl} viewType={viewType} />}
									{viewType == 'PPTXe' && <Pdfviewer testpdfUrl={embedBlob} viewType={viewType} />}
									{viewType == 'DOCX' && <Docviewer embedBlob={embedBlob} />}
									{viewType == 'XLSXe' && <Excelviewer embedBlob={embedBlob} />}
									{/* {(viewType == 'PPTX' || viewType == 'XLSX' || viewType == 'DOCX') && (
										<iframe
											src={`https://view.officeapps.live.com/op/embed.aspx?src=${embedBlob}`}
											width="100%"
											height="600px"></iframe>
									)} */}
									{/* {viewType == 'ERROR' && <div className="noPreview">Something went wrong. No Preview Available</div>} */}
								</div>
							)}
							{resourceView && (
								<div
									dangerouslySetInnerHTML={{ __html: viewData?.Description }}
									className="col-12 col-md-9 resource-view__description mt-md-5"
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
