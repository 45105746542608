import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import httpHelper from '../../helpers/HttpHelper';
import { PowerBIEmbed } from 'powerbi-client-react';
import { groupBsectionOrder } from '../../helpers/constants';
import Arrowdiagonal from '../../assets/images/Arrowdiagonal.png';
import { models } from 'powerbi-client';
import './GroupB.scss';
import environment from '../common/environments/environments';
import { SwitchLanguage, Reset } from '../../store/slices/userSlice';
import Spinner from '../common/spinner';
//import { useScreenType } from '../../helpers/hooks/useScreenType';

const GroupB = props => {
	//const { isSmallDevice } = useScreenType();
	const http = new httpHelper(); // eslint-disable-line
	const refreshCandidate = useRef();
	const [powerBIOneData, setPowerBIOneData] = useState(null);
	const [report, setReport] = useState();
	const [reportOne, setReportOne] = useState();
	const [reportTwo, setReportTwo] = useState();
	const [reportThree, setReportThree] = useState();

	const [enableSearch, setEnableSearch] = useState(false);
	const [enableKeyBtn, setEnableKeyBtn] = useState(null);
	const [enableSatBtn, setEnableSatBtn] = useState(null);
	const [enableOutBtn, setEnableOutBtn] = useState(null);
	const [dashboardAccess, setDashboardAccess] = useState({
		KEYSTATISTICS: false,
		CANDIDATES: false,
		LOCATION: false
	});
	const [enableKeySection, setEnableKeySection] = useState(false);
	const [enableSatSection, setEnableSatSection] = useState(false);
	const [enableOutSection, setEnableOutSection] = useState(false);
	const [allowedSections, setAllowedSections] = useState({});
	let userId = window.sessionStorage.getItem('UserId');

	/* eslint-enable */
	let myOneRef = useRef();
	let myTwoRef = useRef();
	let myThreeRef = useRef();

	useEffect(() => {
		console.log('GROUPB DASHBOARD LANGUAGE SELECTED1', props.userSelectedlanguage); // eslint-disable-line
		console.log('GROUPB EMBEDDED URL1', sessionStorage.getItem('GROUPB_EMBED_URL')); // eslint-disable-line
		getDashboardList();
		console.log('GROUP B CONFIG VALUES::', props.groupBConfig); // eslint-disable-line
	}, []);

	/* eslint-disable */
	const getDashboardList = async () => {
		let payload = {};
		await http
			.Get(environment.USERDASHBOARDLIST + '/' + userId + '/' + environment.BRAND_ID + '/userdashboardlist', payload)
			.then(res => {
				console.log(res.data); // eslint-disable-line
				let groupBsections = [];
				res.data.map(dashboardData => {
					if (dashboardData.TypeDescription === 'Group B - Key Statistics') {
						setDashboardAccess(dashboardAccess => ({ ...dashboardAccess, KEYSTATISTICS: true }));
						groupBsections.push('KEYSTATISTICS');
					} else if (dashboardData.TypeDescription === 'Group B - Candidates') {
						setDashboardAccess(dashboardAccess => ({ ...dashboardAccess, CANDIDATES: true }));
						groupBsections.push('CANDIDATES');
					} else if (dashboardData.TypeDescription === 'Group B - Location') {
						setDashboardAccess(dashboardAccess => ({ ...dashboardAccess, LOCATION: true }));
						groupBsections.push('LOCATION');
					}
				});
				let sortOrder = groupBsectionOrder;
				groupBsections.sort(function (a, b) {
					return sortOrder.indexOf(a) - sortOrder.indexOf(b);
				});
				setAllowedSections(groupBsections);

				if (groupBsections[0] === 'KEYSTATISTICS') {
					setEnableKeySection(true);
				} else if (groupBsections[0] === 'CANDIDATES') {
					setEnableSatSection(true);
				} else if (groupBsections[0] === 'LOCATION') {
					setEnableOutSection(true);
				}
			})
			.catch(error => {
				//console.log(error);
				return error;
			});
	};
	/* eslint-enable */
	useEffect(() => {
		if (dashboardAccess && props?.groupBConfig) {
			setPowerBIOneData(sessionStorage.getItem('GROUPB_REPORT_ID'));
		}
	}, [dashboardAccess, props.groupBConfig]);

	useEffect(() => {
		if (props.isLangChanged) {
			reloadPowerBI();
			props.reset();
		}
	}, [props.isLangChanged, props.userSelectedlanguage]);

	useEffect(() => {
		console.log('updateGroupBDashboard GROUPB::', localStorage.getItem('updateGroupBDashboard')); // eslint-disable-line
		if (props?.groupBConfig && localStorage.getItem('updateGroupBDashboard') == 1) {
			localStorage.setItem('updateGroupBDashboard', 0);
			reloadPowerBI();
		}
	}, [props?.groupBConfig]);

	const refresh = async () => {
		try {
			await reportTwo.refresh();
			console.log('Refreshed the Candidate Report Successfully', reportTwo); // eslint-disable-line
		} catch (error) {
			console.log('Report Two Refresh Error', error); // eslint-disable-line
		}
	};

	useEffect(() => {
		console.log('CANDIDATE_RELOAD', enableSatBtn, reportTwo); // eslint-disable-line
		if (reportTwo != undefined && enableSatBtn === true) {
			setTimeout(function () {
				refresh();
			}, 4000);
		}
	}, [enableSatBtn]);

	const reloadPowerBI = async () => {
		let payload = {};
		await http
			.Get(
				environment.POWER_BI_API +
					'/' +
					props?.groupBConfig['GROUPB'].trim() +
					'/' +
					props?.groupBConfig['WORKSPACE_ID'].trim() +
					'/' +
					props?.userSelectedlanguage.toLowerCase() +
					'/accesstoken',
				payload
			)
			.then(res => {
				setPowerBIOneData(res.data);
				sessionStorage.setItem('GROUPB_REPORT_ID', res.data.ReportId);
				sessionStorage.setItem('GROUPB_EMBED_URL', res.data.EmbedUrl);
				sessionStorage.setItem('GROUPB_ACCESS_TOKEN', res.data.AccessToken);
			})
			.catch(error => {
				console.log('ERROR IN GROUPB RESPONSE', error); // eslint-disable-line
				return error;
			});
	};

	const updateReport = async () => {
		console.log('lang', props?.userSelectedlanguage.toLowerCase()); // eslint-disable-line
		console.log('GROUPB DASHBOARD LANGUAGE SELECTED2', props.userSelectedlanguage); // eslint-disable-line
		console.log('GROUPB EMBEDDED URL2', sessionStorage.getItem('GROUPB_EMBED_URL')); // eslint-disable-line
		let selectedProgram = [];
		let selectedBunit = [];
		let selectedCountry = [];
		// Retrieve the page collection and get the visuals for the active page.
		try {
			const pages = await report.getPages();
			let pageWithSlicer = pages.filter(function (page) {
				return page.isActive;
			})[0];
			const visuals = await pageWithSlicer.getVisuals();
			console.log('GROUPB ALL FILTERS', visuals); // eslint-disable-line
			//console.log('visuals 2', visuals);
			// GET COUNTRY Retrieve the target visual.
			let slicer = visuals.filter(function (visual) {
				return visual.type === 'slicer' && visual.name === props?.groupBConfig['GROUPB_FILTER_COUNTRY'].trim();
			})[0];
			// Get the slicer state
			const state = await slicer.getSlicerState();
			//console.log(state.filters[0]);
			if (state.filters[0] && state.filters[0].values.length > 0) {
				selectedCountry.push(state.filters[0].values);
			}

			// PROGRAM Retrieve the target visual.
			let progslicer = visuals.filter(function (visual) {
				return visual.type === 'slicer' && visual.name === props?.groupBConfig['GROUPB_FILTER_PROGRAM'].trim();
			})[0];
			// Get the slicer state
			const progstate = await progslicer.getSlicerState();
			if (progstate.filters[0] && progstate.filters[0].values.length > 0) {
				selectedProgram.push(progstate.filters[0].values);
			}

			// BUSINESSUNIT Retrieve the target visual.
			let busUnitslicer = visuals.filter(function (visual) {
				return visual.type === 'slicer' && visual.name === props?.groupBConfig['GROUPB_FILTER_BUSINESSUNIT'].trim();
			})[0];
			//console.log('busUnitslicer', busUnitslicer);
			// Get the slicer state
			const busUnitstate = await busUnitslicer.getSlicerState();
			if (busUnitstate.filters[0] && busUnitstate.filters[0].values.length > 0) {
				selectedBunit.push(busUnitstate.filters[0].values);
			}
			console.log('SELECTED COUNTRY', selectedCountry); // eslint-disable-line
			console.log('SELECTED PROGRAM', selectedProgram); // eslint-disable-line
			console.log('SELECTED BUSINESSUNIT', selectedBunit); // eslint-disable-line

			// setPage will change the selected view to the page you indicate.
			// This is the actual page name not the display name.
			//console.log('selectedCountry', selectedCountry[0].length);
			if (
				selectedCountry &&
				selectedCountry?.length == 0 &&
				selectedProgram &&
				selectedProgram?.length == 0 &&
				selectedBunit &&
				selectedBunit?.length == 0
			) {
				await resetAllFilters('SEARCH');
				//await reportOne.reload();
				setTimeout(async function () {
					await updateDataByDate(visuals);
				}, 8000);
			} else {
				updateDataByDate(visuals);
				try {
					const countryFilter = {
						$schema: 'http://powerbi.com/product/schema#basic',
						target: {
							table: 'F_RT_PRF_OPR_V',
							column: 'MasterCountryName'
						},
						operator: 'In',
						values: selectedCountry[0] ? selectedCountry[0] : []
					};

					const programFilter = {
						$schema: 'http://powerbi.com/product/schema#basic',
						target: {
							table: 'F_RT_PRF_OPR_V',
							column: 'ProgramTemplate'
						},
						operator: 'In',
						values: selectedProgram[0] ? selectedProgram[0] : []
					};

					const businessUnitFilter = {
						$schema: 'http://powerbi.com/product/schema#basic',
						target: {
							table: 'F_RT_PRF_OPR_V',
							column: 'Businessunit'
						},
						operator: 'In',
						values: selectedBunit[0] ? selectedBunit[0] : []
					};

					// let's filter data translations for Spanish
					let languageToLoad = props?.userSelectedlanguage.toLowerCase();
					console.log('languageToLoad', languageToLoad); // eslint-disable-line
					// create filter object
					const langFilter = [
						{
							$schema: 'http://powerbi.com/product/schema#basic',
							target: { table: 'dim_Language', column: 'Language' },
							operator: 'In',
							values: [languageToLoad], // <- Filter based on Spanish
							filterType: models.FilterType.Basic,
							requireSingleSelection: true
						}
					];

					// Add the filter to the report's filters.
					try {
						if (selectedCountry[0] && selectedCountry[0].length > 0) {
							if (reportOne) {
								await reportOne.updateFilters(models.FiltersOperations.Replace, langFilter);
								await reportOne.updateFilters(models.FiltersOperations.Replace, [countryFilter]);
							}
							if (reportTwo) {
								await reportTwo.updateFilters(models.FiltersOperations.Replace, langFilter);
								await reportTwo.updateFilters(models.FiltersOperations.Replace, [countryFilter]);
							}
							if (reportThree) {
								await reportThree.updateFilters(models.FiltersOperations.Replace, langFilter);
								await reportThree.updateFilters(models.FiltersOperations.Replace, [countryFilter]);
							}
						}
						if (selectedProgram[0] && selectedProgram[0].length > 0) {
							if (reportOne) {
								await reportOne.updateFilters(models.FiltersOperations.Replace, langFilter);
								await reportOne.updateFilters(models.FiltersOperations.Replace, [programFilter]);
							}
							if (reportTwo) {
								await reportTwo.updateFilters(models.FiltersOperations.Replace, langFilter);
								await reportTwo.updateFilters(models.FiltersOperations.Replace, [programFilter]);
							}
							if (reportThree) {
								await reportThree.updateFilters(models.FiltersOperations.Replace, langFilter);
								await reportThree.updateFilters(models.FiltersOperations.Replace, [programFilter]);
							}
						}
						if (selectedBunit[0] && selectedBunit[0].length > 0) {
							if (reportOne) {
								await reportOne.updateFilters(models.FiltersOperations.Replace, langFilter);
								await reportOne.updateFilters(models.FiltersOperations.Replace, [businessUnitFilter]);
							}
							if (reportTwo) {
								await reportTwo.updateFilters(models.FiltersOperations.Replace, langFilter);
								await reportTwo.updateFilters(models.FiltersOperations.Replace, [businessUnitFilter]);
							}
							if (reportThree) {
								await reportThree.updateFilters(models.FiltersOperations.Replace, langFilter);
								await reportThree.updateFilters(models.FiltersOperations.Replace, [businessUnitFilter]);
							}
						}

						console.log('Report filter was added.'); // eslint-disable-line
					} catch (errors) {
						console.log(errors); // eslint-disable-line
					}
				} catch (errors) {
					console.log(errors); // eslint-disable-line
				}
			}
		} catch (errors) {
			console.log(errors); // eslint-disable-line
		}
	};
	/* eslint-disable */
	const updateDataByDate = async visuals => {
		console.log('GROUPB DATE FILTER', visuals); // eslint-disable-line
		let selectedDate = [];
		let dateslicer = visuals.filter(function (visual) {
			return visual.type === 'slicer' && visual.name === props?.groupBConfig['GROUPB_FILTER_DATE'].trim();
		})[0];

		// Get the slicer state
		const datestate = await dateslicer.getSlicerState();
		//console.log(datestate.filters[0].conditions); // eslint-disable-line
		if (datestate.filters && datestate?.filters[0]?.conditions[0]) {
			selectedDate.push(datestate.filters[0].conditions[0]);
		}
		if (datestate.filters && datestate?.filters[0]?.conditions[1]) {
			selectedDate.push(datestate.filters[0].conditions[1]);
		}
		console.log(selectedDate); // eslint-disable-line
		const datefilter = {
			$schema: 'http://powerbi.com/product/schema#advanced',
			target: {
				table: 'Dim_dates',
				column: 'Date'
			},
			filterType: models.FilterType.Advanced,
			logicalOperator: 'And',
			conditions: selectedDate
		};

		// Retrieve the page collection and get the visuals for the active page.
		try {
			if (reportOne) {
				const keyPages = await reportOne.getPages();
				// Retrieve the active page.
				let keypage = keyPages.filter(function (page) {
					return page.isActive;
				})[0];
				const visuals = await keypage.getVisuals();
				// Retrieve the target visual.
				console.log(props?.groupBConfig['GROUPB_KEY_STATISTICS_DATE'].trim(), 'ONE', visuals); // eslint-disable-line
				let slicer = visuals.filter(function (visual) {
					return visual.type === 'slicer' && visual.name === props?.groupBConfig['GROUPB_KEY_STATISTICS_DATE'].trim();
				})[0];
				// Set the slicer state which contains the slicer filters.
				if (slicer) await slicer.setSlicerState({ filters: [datefilter] });
			}
			if (reportTwo) {
				const statPages = await reportTwo.getPages();
				// Retrieve the active page.
				let statpage = statPages.filter(function (page) {
					return page.isActive;
				})[0];
				const visuals = await statpage.getVisuals();
				console.log(props?.groupBConfig['GROUPB_CANDIDATES_DATE'].trim(), 'TWO', visuals); // eslint-disable-line
				// Retrieve the target visual.
				let slicer = visuals.filter(function (visual) {
					return visual.type === 'slicer' && visual.name === props?.groupBConfig['GROUPB_CANDIDATES_DATE'].trim();
				})[0];
				// Set the slicer state which contains the slicer filters.
				if (slicer) await slicer.setSlicerState({ filters: [datefilter] });
			}
			if (reportThree) {
				const outcomePages = await reportThree.getPages();
				// Retrieve the active page.
				let outcomepage = outcomePages.filter(function (page) {
					return page.isActive;
				})[0];
				const visuals = await outcomepage.getVisuals();
				console.log(props?.groupBConfig['GROUPB_LOCATION_DATE'].trim(), 'THREE', visuals); // eslint-disable-line
				// Retrieve the target visual.
				let slicer = visuals.filter(function (visual) {
					return visual.type === 'slicer' && visual.name === props?.groupBConfig['GROUPB_LOCATION_DATE'].trim();
				})[0];
				// Set the slicer state which contains the slicer filters.
				if (slicer) await slicer.setSlicerState({ filters: [datefilter] });
			}
		} catch (errors) {
			console.log(errors); // eslint-disable-line
		}
		//await report.updateFilters(models.FiltersOperations.RemoveAll);
		//await reportOne.updateFilters(models.FiltersOperations.RemoveAll);
		//await reportOne.updateFilters(models.FiltersOperations.Add, [dateFilter]);
		//GET DATE ENDS
	};
	/* eslint-enable */
	const resetAllFilters = async action => {
		// Remove the filters currently applied to the report.
		try {
			if (action === 'RESET') {
				await report.reload();
			}
			if (reportOne) await reportOne.reload();
			if (reportTwo) await reportTwo.reload();
			if (reportThree) await reportThree.reload();

			//await updateDataByDate(visuals);
		} catch (errors) {
			console.log(errors); // eslint-disable-line
		}
	};

	/* For Performance Testing  Starts*/
	const [keycount, setKeyCount] = useState(0);
	const [satcount, setSatCount] = useState(0);
	const [outcount, setOutCount] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			if ((enableKeyBtn === null || enableKeyBtn === false) && enableKeySection === true) {
				setKeyCount(keycount + 1);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [keycount, enableKeySection]);

	useEffect(() => {
		const interval = setInterval(() => {
			if ((enableSatBtn === null || enableSatBtn === false) && enableSatSection === true) {
				setSatCount(satcount + 1);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [satcount, enableSatSection]);

	useEffect(() => {
		const interval = setInterval(() => {
			if ((enableOutBtn === null || enableOutBtn === false) && enableOutSection === true) {
				setOutCount(outcount + 1);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [outcount, enableOutSection]);
	/* For Performance Testing  Ends*/
	const enableNextSection = sectionIndex => {
		console.log('NEXT SECTION TO ENABLE', allowedSections[sectionIndex]); // eslint-disable-line
		if (allowedSections[sectionIndex] === 'KEYSTATISTICS') {
			setEnableKeySection(true);
		} else if (allowedSections[sectionIndex] === 'CANDIDATES') {
			setEnableSatSection(true);
		} else if (allowedSections[sectionIndex] === 'LOCATION') {
			setEnableOutSection(true);
		}
	};
	return (
		<>
			{props?.pagedata && (
				<div className="container-fluid container-lg mt-3 mb-3 mt-lg-0 mb-lg-0 p-0 p-sm-2 p-md-2 p-lg-3">
					<table
						style={{
							display: 'none',
							background: '#fff',
							border: '1px solid #000000',
							position: 'fixed',
							top: '250px',
							zIndex: '4000'
						}}>
						<thead>
							<tr>
								<th style={{ border: '1px solid #000000', padding: '5px' }}>KEYSTATISTICS</th>
								<th style={{ border: '1px solid #000000', padding: '5px' }}>CANDIDATES</th>
								<th style={{ border: '1px solid #000000', padding: '5px' }}>LOCATION</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{ border: '1px solid #000000', color: 'red', padding: '5px' }}>{keycount}</td>
								<td style={{ border: '1px solid #000000', color: 'blue', padding: '5px' }}>{satcount}</td>
								<td style={{ border: '1px solid #000000', color: 'green', padding: '5px' }}>{outcount}</td>
							</tr>
						</tbody>
					</table>
					<div className="row groupbContainer">
						<div className="col-12 p-0 p-sm-2 p-md-0">
							<div className="row">
								<div className="col-12 title">{props?.pagedata?.title}</div>
								<div className="col-12 desc">
									<span>{props?.pagedata?.description}</span>
								</div>
								<div className="col-9 p-0">
									<div className="col-12 jumpTo d-block">{props?.pagedata?.jumptotitle}</div>
									<div className="col-12 navigationBlock d-flex flex-column align-items-start flex-lg-row gap-2">
										{dashboardAccess.KEYSTATISTICS && enableKeyBtn && (
											<button
												type="button"
												className="btn btn-outline-secondary"
												onClick={() => window.scrollTo({ behavior: 'smooth', top: myOneRef.current.offsetTop })}>
												{props?.pagedata?.jumptolables['Dashboard.GroupB.KeyStatistics']}
												<img src={Arrowdiagonal} alt="Arrow Diagonal" />
											</button>
										)}
										{dashboardAccess.CANDIDATES && enableSatBtn && (
											<button
												type="button"
												className="btn btn-outline-secondary"
												onClick={() => window.scrollTo({ behavior: 'smooth', top: myTwoRef.current.offsetTop })}>
												{props?.pagedata?.jumptolables['Dashboard.GroupB.Candidates']}
												<img src={Arrowdiagonal} alt="Arrow Diagonal" />
											</button>
										)}
										{dashboardAccess.LOCATION && enableOutBtn && (
											<button
												type="button"
												className="btn btn-outline-secondary"
												onClick={() => window.scrollTo({ behavior: 'smooth', top: myThreeRef.current.offsetTop })}>
												{props?.pagedata?.jumptolables['Dashboard.GroupB.Location']}
												<img src={Arrowdiagonal} alt="Arrow Diagonal" />
											</button>
										)}
									</div>
								</div>
								<div className="col-3 pt-3 text-end">
									<div className="col-12">
										{/* <button
										type="button"
										className="btn btn-secondary rounded-pill filterBtn"
										onClick={() => setShowfilter(!showfilter)}>
										<img src={Filtericon} />
										Filter Dashboard
									</button> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{powerBIOneData && (
				<div>
					<PowerBIEmbed
						title="Power BI Report"
						embedConfig={{
							type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
							id: sessionStorage.getItem('GROUPB_REPORT_ID'),
							embedUrl: sessionStorage.getItem('GROUPB_EMBED_URL'),
							accessToken: sessionStorage.getItem('GROUPB_ACCESS_TOKEN'),
							tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
							pageName: props?.groupBConfig['GROUPB_FILTERS'].trim(),
							settings: {
								panes: {
									filters: {
										expanded: false,
										visible: false
									},
									pageNavigation: { visible: false }
								},
								background: models.BackgroundType.Transparent,
								layoutType: models.LayoutType.Custom,
								//layoutType: isSmallDevice ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
								customLayout: {
									displayOption: models.DisplayOption.FitToPage
								}
							}
						}}
						/* eslint-disable */
						eventHandlers={
							new Map([
								[
									'loaded',
									function () {
										console.log('Report loaded');
									}
								],
								[
									'rendered',
									function () {
										console.log('Report rendered');
										setEnableSearch(true);
									}
								],
								[
									'error',
									function (event) {
										console.log(event.detail);
									}
								],
								['visualClicked', () => console.log('visual clicked')],
								['pageChanged', event => console.log(event)]
							])
						}
						cssClassName={'groupd-filter-container'}
						getEmbeddedComponent={embeddedReport => {
							window.report = embeddedReport;
							setReport(embeddedReport);
						}}
					/>
				</div>
			)}
			{enableSearch && (
				<div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row gap-2 mb-3 mt-md-1 mb-lg-0 searchContainer">
					<button
						type="button"
						className="btn btn-outline-secondary rounded-pill filterResetBtn"
						onClick={() => resetAllFilters('RESET')}>
						{props?.tabTitles['Dashboard.Submenu.Reset']}
					</button>
					<button
						type="button"
						className="btn btn-outline-secondary rounded-pill filterSearchBtn"
						onClick={() => updateReport()}>
						{props?.tabTitles['Dashboard.Submenu.ApplyChanges']}
					</button>
					<button
						type="button"
						ref={refreshCandidate}
						className="btn btn-outline-secondary rounded-pill filterSearchBtn"
						onClick={() => refresh()}
						style={{ display: 'none' }}>
						Refresh
					</button>
				</div>
			)}
			{!enableKeyBtn && enableKeyBtn != null && <Spinner />}
			{powerBIOneData && enableKeySection && dashboardAccess.KEYSTATISTICS && (
				<div ref={myOneRef} style={{ visibility: !enableKeyBtn ? 'hidden' : '' }}>
					<PowerBIEmbed
						title="Power BI Report"
						embedConfig={{
							type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
							id: sessionStorage.getItem('GROUPB_REPORT_ID'),
							embedUrl: sessionStorage.getItem('GROUPB_EMBED_URL'),
							accessToken: sessionStorage.getItem('GROUPB_ACCESS_TOKEN'),
							tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
							pageName: props?.groupBConfig['GROUPB_KEY_STATISTICS'].trim(),
							settings: {
								panes: {
									filters: {
										expanded: false,
										visible: false
									},
									pageNavigation: { visible: false }
								},
								background: models.BackgroundType.Transparent,
								//layoutType: isSmallDevice ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
								layoutType: models.LayoutType.Custom,
								customLayout: {
									displayOption: models.DisplayOption.FitToPage
								}
							}
						}}
						/* eslint-disable */
						eventHandlers={
							new Map([
								[
									'loaded',
									function () {
										console.log('Report loaded');
										setEnableKeyBtn(false);
									}
								],
								[
									'rendered',
									function () {
										setEnableKeyBtn(true);
										if (allowedSections.includes('KEYSTATISTICS')) {
											console.log('KEYSTATISTICS', allowedSections.indexOf('KEYSTATISTICS'));
											enableNextSection(allowedSections.indexOf('KEYSTATISTICS') + 1);
										}
									}
								],
								[
									'error',
									function (event) {
										console.log(event.detail);
									}
								],
								['visualClicked', () => console.log('visual clicked')],
								['pageChanged', event => console.log(event)]
							])
						}
						cssClassName={'groupd-keystat-container'}
						getEmbeddedComponent={embeddedReport => {
							window.report = embeddedReport;
							setReportOne(embeddedReport);
						}}
					/>
				</div>
			)}
			{!enableSatBtn && enableSatBtn != null && <Spinner />}
			{powerBIOneData && enableSatSection && dashboardAccess.CANDIDATES && (
				<div ref={myTwoRef} style={{ visibility: !enableSatBtn ? 'hidden' : '' }}>
					<PowerBIEmbed
						title="Power BI Report"
						embedConfig={{
							type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
							id: sessionStorage.getItem('GROUPB_REPORT_ID'),
							embedUrl: sessionStorage.getItem('GROUPB_EMBED_URL'),
							accessToken: sessionStorage.getItem('GROUPB_ACCESS_TOKEN'),
							tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
							pageName: props?.groupBConfig['GROUPB_CANDIDATES'].trim(),
							settings: {
								panes: {
									filters: {
										expanded: false,
										visible: false
									},
									pageNavigation: { visible: false }
								},
								background: models.BackgroundType.Transparent,
								layoutType: models.LayoutType.Custom,
								//layoutType: isSmallDevice ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
								customLayout: {
									displayOption: models.DisplayOption.FitToPage
								}
							}
						}}
						/* eslint-disable */
						eventHandlers={
							new Map([
								[
									'loaded',
									function () {
										console.log('Report loaded');
										console.log('Report loaded Done1');
										setEnableSatBtn(false);
									}
								],
								[
									'rendered',
									function () {
										setEnableSatBtn(true);
										console.log('Report loaded Done2', reportTwo);
										if (allowedSections.includes('CANDIDATES')) {
											console.log('CANDIDATES', allowedSections.indexOf('CANDIDATES'));
											enableNextSection(allowedSections.indexOf('CANDIDATES') + 1);
										}
									}
								],
								[
									'error',
									function (event) {
										console.log(event.detail);
									}
								],
								['visualClicked', () => console.log('visual clicked')],
								['pageChanged', event => console.log(event)]
							])
						}
						cssClassName={'groupd-candidates-container'}
						getEmbeddedComponent={embeddedReport => {
							window.report = embeddedReport;
							setReportTwo(embeddedReport);
						}}
					/>
				</div>
			)}
			{!enableOutBtn && enableOutBtn != null && <Spinner />}
			{powerBIOneData && enableOutSection && dashboardAccess.LOCATION && (
				<div ref={myThreeRef} style={{ visibility: !enableOutBtn ? 'hidden' : '' }}>
					<PowerBIEmbed
						title="Power BI Report"
						embedConfig={{
							type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
							id: sessionStorage.getItem('GROUPB_REPORT_ID'),
							embedUrl: sessionStorage.getItem('GROUPB_EMBED_URL'),
							accessToken: sessionStorage.getItem('GROUPB_ACCESS_TOKEN'),
							tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
							pageName: props?.groupBConfig['GROUPB_LOCATION'].trim(),
							settings: {
								panes: {
									filters: {
										expanded: false,
										visible: false
									},
									pageNavigation: { visible: false }
								},
								background: models.BackgroundType.Transparent,
								layoutType: models.LayoutType.Custom,
								//layoutType: isSmallDevice ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
								customLayout: {
									displayOption: models.DisplayOption.FitToPage
								}
							}
						}}
						/* eslint-disable */
						eventHandlers={
							new Map([
								[
									'loaded',
									function () {
										console.log('Report loaded');
										setEnableOutBtn(false);
									}
								],
								[
									'rendered',
									function () {
										setEnableOutBtn(true);
										if (allowedSections.includes('LOCATION')) {
											if (reportTwo) {
												refresh(reportTwo);
											}
											console.log('LOCATION', allowedSections.indexOf('LOCATION'));
											enableNextSection(allowedSections.indexOf('LOCATION') + 1);
										}
									}
								],
								[
									'error',
									function (event) {
										console.log(event.detail);
									}
								],
								['visualClicked', () => console.log('visual clicked')],
								['pageChanged', event => console.log(event)]
							])
						}
						cssClassName={'groupd-location-container'}
						getEmbeddedComponent={embeddedReport => {
							window.report = embeddedReport;
							setReportThree(embeddedReport);
						}}
					/>
				</div>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		headerPageContent: state.userSlice.headerPageContent,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		switchLanguage: shortLang => dispatch(SwitchLanguage(shortLang)),
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupB);
