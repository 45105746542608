import { AccoutTeamContext } from '../../../helpers/contexts';
import ScheduleMeeting from '../../../components/clientTeam/scheduleMeeting';
import MeetingRequests from '../../../components/clientTeam/meetingRequests';
import AccountTeamListing from '../../../components/clientTeam/accountTeamListing';
import { meetingsAllowed } from '../../../helpers/constants';
import { useState, useEffect } from 'react';

const AccountTeam = ({ accountTeamData, accountTeamPageData }) => {
	const [permissionCode, setPermissionCode] = useState('');

	useEffect(() => {
		const tokenData = JSON.parse(sessionStorage.getItem('setIdTokenClaims'));
		// console.log(tokenData.PermissionCode);
		setPermissionCode(tokenData.PermissionCode);
	}, []);

	return (
		<AccoutTeamContext.Provider value={accountTeamPageData}>
			<div className="row">
				{meetingsAllowed.includes(permissionCode) && (
					<>
						<div className="col-12 col-lg-5">
							<ScheduleMeeting />
						</div>
						<div className="col-12 col-lg-7 mt-4 mt-lg-0">
							<MeetingRequests />
						</div>
					</>
				)}
				<div className="col-12">
					{accountTeamData ? (
						<AccountTeamListing accountTeamData={accountTeamData} permissionCode={permissionCode} />
					) : (
						<div className="text-center">
							<div className="spinner-border my-5" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					)}
				</div>
			</div>
		</AccoutTeamContext.Provider>
	);
};

export default AccountTeam;
