import './style.scss';
export default function Spinner() {
	return (
		<>
			<div className="container text-center">
				<div className="spinner-grow text-dark"></div>
				<div className="spinner-grow text-muted"></div>
				<div className="spinner-grow text-secondary"></div>
				<div className="spinner-grow text-light"></div>
			</div>
		</>
	);
}
