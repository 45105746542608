import React, { useState, useEffect, useRef } from 'react';
//import { useNavigate } from 'react-router-dom';
import HttpHelper from '../../helpers/HttpHelper';
import PageLayout from '../../components/pageLayout/pageLayout';
//import Referrals from '../../components/referrals/Referrals';
//import { ReferralsActions } from '../../components/referrals/ReferralsActions';
import ReferralTemplate from '../../components/referrals/referralTemplate';
import MySubmittedRequests from '../../components/referrals/mySubmittedRequests';
import { Reset } from '../../store/slices/userSlice';
import { connect } from 'react-redux';
import './referrals.scss';
import Loader from '../../components/common/Loader';

const Referral = props => {
	const [pagedata, setPageData] = useState(null);
	const [cmsReferralPage, setCmsReferralPage] = useState({});
	const [cmsSubmittedRequestPageContent, setCmsSubmittedRequestPageContent] = useState({}); // eslint-disable-line
	const http = new HttpHelper();
	const shouldLog = useRef(true);
	//const navigate = useNavigate();
	const payload = {
		categoryname: 'Default',
		pagename: 'Referrals',
		language: props.userSelectedlanguage.toLowerCase() || 'en'
	};
	const getData = async () => {
		await http
			.CMSpost('PageApi/GetPageDetails', payload)
			.then(res => {
				//console.log(res);
				const { data } = res;
				setPageData(data.Pages[0]);
				if (data) {
					//setPageLoader(false);
					let CmsReferralPage = JSON.parse(JSON.stringify(cmsReferralPage));
					let CmsSubmittedRequestPageContent = JSON.parse(JSON.stringify(cmsSubmittedRequestPageContent));
					data?.Pages?.map(x => {
						if (x.Key == 'CandidateReferrals') {
							CmsReferralPage['title'] = x.Components?.['Referrals.CandidateReferrals']?.Title;
							CmsReferralPage['description'] = x.Components?.['Referrals.CandidateReferrals']?.Description;
							CmsReferralPage['downloadBtnLabel'] =
								x.Components?.['Referrals.CandidateReferrals']?.LinkList[1]?.Link.Text;
							CmsReferralPage['downloadBtnUrl'] = x.Components?.['Referrals.CandidateReferrals']?.LinkList[1]?.Link.Url;
							x.Components?.['Referrals.CandidateReferrals']?.Labels.map(y => {
								CmsReferralPage[y.Key] = y.Phrase;
							});
							let templates = {};
							x.Components?.['Referrals.CandidateReferrals']?.OtherMediaFiles.map(y => {
								if (y.IsVisible) {
									templates[y.Key] = y;
								}
							});
							CmsReferralPage['templates'] = templates;
							CmsReferralPage['createnewReferralDesc'] =
								x.Components?.['Referrals.CandidateReferrals']?.RichTexts[0]?.Value;
							let createNeBtn = { label: '', link: '', alttext: '', target: '' };
							createNeBtn.label = x.Components?.['Referrals.CandidateReferrals']?.LinkList[0]?.Link?.Text;
							createNeBtn.link = '/create-referrals';
							createNeBtn.alttext = x.Components?.['Referrals.CandidateReferrals']?.LinkList[0]?.Link?.AltText;
							createNeBtn.target = x.Components?.['Referrals.CandidateReferrals']?.LinkList[0]?.Link?.Target;
							CmsReferralPage['createNewBtn'] = createNeBtn;

							let headersList = {
								'Referrals.SubmitttedReferralsList.CandidateName': '',
								'Referrals.SubmitttedReferralsList.FileType': '',
								'Referrals.SubmitttedReferralsList.AddedDate': '',
								'Referrals.SubmitttedReferralsList.Status': '',
								'Referrals.SubmitttedReferralsList.RejectionReason': '',
								'Referrals.SubmitttedReferralsList.Download': '',
								'Referrals.SubmitttedReferralsList.RejectionReasonFileName': ''
							};
							x.Components?.['Referrals.SubmitttedReferralsList']?.Labels.map(y => {
								headersList[y.Key] = y.Phrase.toUpperCase();
							});
							let statusList = {
								'Status.Rejected': '',
								'Status.Approved': '',
								'Status.Pending': ''
							};
							x.Components?.['Referrals.SubmitttedReferralsList']?.LOVList[0]?.Values.map(y => {
								statusList[y.Key] = y.Value;
							});
							CmsSubmittedRequestPageContent['GridTitle'] = x.Components?.['Referrals.SubmitttedReferralsList']?.Title;
							CmsSubmittedRequestPageContent['GridHeaders'] = headersList;
							CmsSubmittedRequestPageContent['GridStatus'] = statusList;
							setCmsSubmittedRequestPageContent(CmsSubmittedRequestPageContent);
						}
					});
					setCmsReferralPage(CmsReferralPage);
				} else {
					return [];
				}
			})
			.catch(error => {
				console.log('Referral Api Error::ReferralPage', error); // eslint-disable-line
				return error;
			});
	};

	useEffect(() => {
		document.title = 'Referrals';
		if (shouldLog.current) {
			getData();
			props.reset();
			shouldLog.current = false;
		}
	}, []);

	useEffect(() => {
		if (props.isLangChanged) {
			getData();
			props.reset();
		}
	}, [props.isLangChanged, props.userSelectedlanguage]);

	return (
		<>
			{pagedata ? (
				<PageLayout containerClass="" bannerTitle={pagedata?.Title} data={pagedata}>
					<div className="container-fluid-md container-lg  referralsContainer">
						{/* <Referrals /> */}
						<ReferralTemplate templateData={cmsReferralPage} />
						<MySubmittedRequests submittedRequestData={cmsSubmittedRequestPageContent} />
					</div>
				</PageLayout>
			) : (
				<Loader />
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		headerPageContent: state.userSlice.headerPageContent,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
