import { useState, useEffect } from 'react';
import HttpHelper from '../../../helpers/HttpHelper';

import Grid from '../../../components/common/Grid';
import Button from '../../../components/common/button';
import Modalpopup from '../../../components/common/Modal/Modalpopup';

import { removeAllowed, limitCl3 } from '../../../helpers/constants';

import './style.scss';

const RemoveUserConfirmation = ({ toggleConfirmation, userData, myTeamPageData, getClientTeamData }) => {
	const { FirstName, MiddleName, LastName, ClientId, ClientAccountId } = userData,
		name = `${FirstName}${MiddleName ? ` ${MiddleName}` : ''}${LastName ? ` ${LastName}` : ''}`,
		{ manageUser, common } = myTeamPageData;

	const modalLabel = {};
	const commonLabel = {};

	manageUser?.Labels?.forEach(element => {
		modalLabel[element.Key] = element.Phrase;
	});

	common?.Labels?.forEach(element => {
		commonLabel[element.Key] = element.Phrase;
	});

	const removeUser = async () => {
		const http = new HttpHelper(),
			{ data } = await http.post(
				`User-Profile/deleteclientteam/${ClientId}/${ClientAccountId}`,
				{},
				'application/json'
			);

		if (data) {
			toggleConfirmation();
			getClientTeamData();
		}
	};

	return (
		<div className="remove-user-confirmation text-center">
			<h2>{modalLabel?.['ClienTeam.ManageUser.RemoveUserPopupTitle']}</h2>
			<p className="mt-3 mb-4">
				{modalLabel?.['ClienTeam.ManageUser.RemoveUserPopupDesc2'].replace('{UserName}', name)}
			</p>
			<div className="mt-3">
				<Button buttonClass="button--blue" type="submit" onClickHandler={removeUser}>
					{commonLabel?.['Common.ButtonText.Confirm']}
				</Button>
				<Button
					buttonClass="button--white ms-3"
					onClickHandler={() => {
						toggleConfirmation();
					}}>
					{commonLabel?.['Common.ButtonText.Cancel']}
				</Button>
			</div>
		</div>
	);
};

const MyTeam = ({ clientTeamData, myTeamPageData, getClientTeamData }) => {
	const [displayConfirmation, setConfirmation] = useState(false);
	const [userData, setUserData] = useState(null);
	const { myTeam, manageUser } = myTeamPageData;
	const [permissionCode, setPermissionCode] = useState('');
	const [emailAddress, setEmailAddress] = useState('');

	useEffect(() => {
		const tokenData = JSON.parse(sessionStorage.getItem('setIdTokenClaims'));
		setPermissionCode(tokenData.PermissionCode);
		setEmailAddress(tokenData.EmailAddress);
	}, []);

	const columnDefs = [
		{
			headerName: `${myTeam?.Labels?.[2].Phrase}`,
			valueGetter: params => {
				const { FirstName, MiddleName, LastName } = params.data;
				return `${FirstName}${MiddleName ? ` ${MiddleName}` : ''}${LastName ? ` ${LastName}` : ''}`;
			}
		},
		{ headerName: `${myTeam?.Labels?.[1].Phrase}`, field: 'EmailAddress' },
		{ headerName: `${myTeam?.Labels?.[4].Phrase}`, field: 'JobRole' },
		{ headerName: `${myTeam?.Labels?.[3].Phrase}`, field: 'PermissionDescription' },
		{
			headerName: manageUser?.Labels?.[0].Phrase,
			cellRenderer: userData => renderRemoveButton(userData)
		}
	];

	const getRowClass = ({ data }) => {
		if (['Inactive', 'Removed', 'Pending'].includes(data.StatusTypeDescription)) {
			return 'my-team__deleted-user';
		}
	};

	const renderRemoveButton = ({ data }) => {
		const { StatusTypeDescription, PermissionDescription, EmailAddress } = data;
		return StatusTypeDescription === 'Active' &&
			removeAllowed.includes(permissionCode) &&
			checkForCL3(PermissionDescription) &&
			checkForCL1(EmailAddress) ? (
			<Button buttonClass="button__icon-button" icon="remove" onClickHandler={() => showConfirmation(data)}>
				{manageUser?.Labels?.[0].Phrase}
			</Button>
		) : (
			''
		);
	};

	const checkForCL3 = permissionDescription => {
		if (permissionCode === 'CL3') return !limitCl3.includes(permissionDescription);
		return true;
	};

	const checkForCL1 = email => {
		if (permissionCode === 'CL1') return !(email === emailAddress);
		return true;
	};

	const showConfirmation = userData => {
		setConfirmation(!displayConfirmation);
		setUserData(userData);
	};

	return (
		<div className="my-team">
			<h2>{myTeam?.Labels?.[5].Phrase}</h2>

			<Grid
				columnDefs={columnDefs}
				rowData={clientTeamData}
				gridHeight="480"
				gridMinHeight={false}
				getRowClass={getRowClass}
			/>

			<Modalpopup show={displayConfirmation} modalSize={'md'}>
				{userData && (
					<RemoveUserConfirmation
						toggleConfirmation={showConfirmation}
						userData={userData}
						myTeamPageData={myTeamPageData}
						getClientTeamData={getClientTeamData}
					/>
				)}
			</Modalpopup>
		</div>
	);
};

export default MyTeam;
