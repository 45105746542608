//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../helpers/HttpHelper';
import environments from '../common/environments/environments'; // eslint-disable-line
const http = new httpHelper(); // eslint-disable-line

export const ReferralsActions = {
	GetCMSRequestPageDetails: getCMSRequestPageDetails,
	GetRequestList: getRequestList
};

function getCMSRequestPageDetails(payload) {
	return http.CMSpost(environments.REFERRAL_PAGE_API_CMS, payload);
}

function getRequestList(payload) {
	let userId = window.sessionStorage.getItem('UserId');
	return http.Get(
		environments.REFERRAL_LIST_API +
			'?UserId=' +
			userId +
			'&BrandId=' +
			environments.BRAND_ID +
			'&PageNumber=' +
			payload.PageNumber +
			'&PageSize=' +
			payload.PageSize,
		payload
	);
}

// function getRequestList(payload) {
//   return http.CMSpost(environments.GET_REQUESTPAGE_LABELS, payload);
// }

// function getRequestList() {
//   return http.jobGet(environments.GET_APPLIED_JOBS);
// }
