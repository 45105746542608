//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../../helpers/HttpHelper';
import environments from '../environments/environments'; // eslint-disable-line
const http = new httpHelper(); // eslint-disable-line

export const NotificationActions = {
	GetNotificationList: getNotificationList,
	MarkAllasRead: markAllasRead
};

function getNotificationList(payload) {
	let userId = window.sessionStorage.getItem('UserId');
	return http.Get(
		environments.NOTIFICATIONS_LIST +
			'?UserId=' +
			userId +
			'&BrandId=' +
			environments.BRAND_ID +
			'&offset=' +
			payload.offset +
			'&limit=' +
			payload.limit +
			'&locale=' +
			payload.locale,
		payload
	);
}

function markAllasRead(payload) {
	let userId = window.sessionStorage.getItem('UserId');
	return http.post(
		environments.NOTIFICATIONS_LIST + '?UserId=' + userId + '&BrandId=' + environments.BRAND_ID,
		payload
	);
}
