//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------
import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import httpHelper from '../../../helpers/HttpHelper';
import environments from '../../common/environments/environments'; // eslint-disable-line
const http = new httpHelper(); // eslint-disable-line
import { ReportsActions } from '../ReportsActions';
import {
	customDateConverter,
	xxlDataForReport,
	xlDataForReport,
	lgDataForReport,
	normalDataForReport
} from '../../../helpers/constants';
import './reports.scss';
import DownlodIcon from '../../../assets/images/RightArrow.png';
import RightArrowPaginate from '../../../assets/images/RightArrowPaginate.png';
import LeftArrowPaginate from '../../../assets/images/LeftArrowPaginate.png';
import Grid from '../../../components/common/Grid';
//import Snackbar from '../../../components/common/snackBar/SnackBar';
//import SnackbarContext from '../../../components/common/snack';
import { useNavigate } from 'react-router-dom';
import InLineLoader from '../../common/inlineLoader';
import Button from '../../common/button';
import useScreenSize from '../../../helpers/userScreensize';
import TrackActivity from '../../../helpers/TrackActivity';
const get = require('get-value');
export default function Reports(props) {
	const screenSize = useScreenSize(); //eslint-disable-line
	//const snackbarCtx = useContext(SnackbarContext);
	const tracker = new TrackActivity();
	const repgridRef = useRef();
	const navigate = useNavigate();
	//For Paginate
	const [pageCount, setPageCount] = useState(0);
	const [reportItems, setReportItems] = useState([]);
	const [inLineLoader, setInLineLoader] = useState(false);
	const [columnDefs, setColumnDefs] = useState();
	const pageNumber = props?.pageNumber ? props?.pageNumber : 1;
	const pageSize = props?.pageSize ? props?.pageSize : 10;
	//const [loader, setLoader] = useState(false);
	const gridHeight = props?.gridHeight ? props?.gridHeight : '';

	let userId = window.sessionStorage.getItem('UserId');
	const { Components } = props.submittedRequestData;
	const sectionTitle = get(Components, 'ReportsAndResources.Reports.Title');
	const sectionDescription = get(Components, 'ReportsAndResources.Reports.Description');
	const title = props?.title ? props.title : sectionTitle;
	const gridHeaders = get(Components, 'ReportsAndResources.Reports.Labels');
	const resourceLabels = get(Components, 'ReportsAndResources.Reports.LOVList');

	const resourceTypeLabels = {};
	resourceLabels[0].Values.forEach(element => {
		resourceTypeLabels[element.Key] = element.Value;
	});
	//console.log('resourceTypeLabels', resourceTypeLabels['ReportType.Report']);
	//let gridData = [];
	useEffect(() => {
		console.log('screenSize.width', screenSize.width); //eslint-disable-line
		let widthData =
			screenSize.width >= 1920
				? xxlDataForReport
				: screenSize.width >= 1366
				? xlDataForReport
				: screenSize.width >= 800
				? lgDataForReport
				: normalDataForReport;
		//console.log('widthData', widthData);
		let headers = [
			{
				headerName: gridHeaders[1]?.Phrase,
				field: 'Title',
				colId: 'Title',
				width: props?.titleWidth ? props?.titleWidth : widthData[0].titleWidth,
				cellStyle: { fontWeight: '600' }
			},
			{
				headerName: gridHeaders[0]?.Phrase,
				field: 'Description',
				width: widthData[0].descriptionWidth,
				hide: props?.hideDesc ? props?.hideDesc : false,
				wrapText: true,
				autoHeight: true,
				cellRenderer: descriptionRender,
				cellStyle: { lineHeight: '24px' }
			},
			{
				headerName: gridHeaders[2]?.Phrase,
				field: 'ResourceType',
				width: widthData[0].resourceTypeWidth,
				hide: true,
				cellRenderer: reportTypeRender
			},
			{
				headerName: gridHeaders[3]?.Phrase,
				field: 'ReportUploadedTime',
				headerClass: 'addedOn',
				width: widthData[0].reportUploadedTimeWidth,
				wrapText: true,
				autoHeight: true,
				cellRenderer: dateRender,
				cellStyle: { lineHeight: '24px' }
			},
			{
				headerName: gridHeaders[4]?.Phrase,
				field: 'DocumentStatus',
				width: widthData[0].viewActionWidth,
				cellRenderer: viewActionRender
			}
		];
		setColumnDefs(headers);
	}, [props.submittedRequestData]);

	const defaultColDef = useMemo(() => ({
		sortable: true,
		resizable: true
		// wrapText: true,
		// autoHeight: true
		// tooltipComponent: CustomTooltip
	}));

	const dateRender = params => {
		const resDate = customDateConverter(params.value);
		let getDateAndTime = resDate.split(' ');
		return (
			<>
				<div style={{ fontWeight: '600' }}>{getDateAndTime[0]}</div>
				<span>{getDateAndTime[1]}</span>
			</>
		);
	};
	const reportTypeRender = params => {
		let tempkey = 'ReportType.' + params.value;
		//resourceTypeLabels[tempkey];
		return <div className="gridStatus">{resourceTypeLabels[tempkey]}</div>;
	};

	const descriptionRender = params => {
		if (params.value != '' && params.value.length > 5) {
			//let dummydata = 'Rejected due to Invalid data found in the sheet';
			let dummydata = params.value;
			if (dummydata.length > 200) {
				return dummydata.substring(0, 200) + '...';
			} else {
				return dummydata;
			}
		}
	};

	const viewActionRender = params => {
		if (params) {
			//console.log('params', params);
			let reportURL = params.data.Id;
			let title = params.data.Title;
			let resourceTypeId = params.data.ResourceTypeId;
			return (
				<div
					role="button"
					className="report-list__viewText"
					onClick={() => navigateToReport(reportURL, title, resourceTypeId)}
					aria-label={gridHeaders[4]?.Phrase}>
					{gridHeaders[4]?.Phrase}
					<img src={DownlodIcon} className="report-list__viewArrow" alt="View Icon" />
				</div>
			);
		}
	};

	const navigateToReport = async (reportURL, title, resourceTypeId) => {
		navigate('/reports-view/' + reportURL + '/' + resourceTypeId);
		//const title = gridData.find(x => x.Id === reportURL).Title;
		tracker.sendActivity('CientReportResourceView', userId, reportURL, title);
	};

	// Load Report From API
	useEffect(() => {
		//console.log(props);
		setInLineLoader(true);
		getReportList();
	}, []);

	const getReportList = async () => {
		let payload = {};
		payload.PageNumber = pageNumber;
		payload.PageSize = pageSize;
		await ReportsActions.GetReportsList(payload).then(
			async res => {
				if (res.status === 200 && res?.data?.reportResources?.length > 0) {
					//gridData = res?.data?.reportResources;
					paginateItems(0, res?.data?.reportResources, res?.data?.TotalCount);
				} else {
					setReportItems([]);
					setInLineLoader(false);
					//snackbarCtx.displayMsg('Something went wrong ', SnackBarMsgType.Error);
				}
			},
			err => {
				setReportItems([]);
				setInLineLoader(false);
				//snackbarCtx.displayMsg('Something went wrong: ' + err.message, SnackBarMsgType.Error);
				return err;
			}
		);
	};

	const paginateItems = (itemOffset, ReferralList, totalCount) => {
		setReportItems(ReferralList);
		const pageCount = Math.ceil(totalCount / pageSize);
		setPageCount(pageCount);
		setInLineLoader(false);
	};

	const handlePageClick = async event => {
		setInLineLoader(true);
		let payload = {};
		payload.PageNumber = event.selected + pageNumber;
		payload.PageSize = pageSize;
		await ReportsActions.GetReportsList(payload).then(
			async res => {
				if (res.status === 200 && res?.data?.reportResources?.length > 0) {
					//gridData = res?.data?.reportResources;
					paginateItems(event.selected, res?.data?.reportResources, res?.data?.TotalCount);
				} else {
					//snackbarCtx.displayMsg('Something went wrong ', SnackBarMsgType.Error);
				}
			},
			err => {
				//snackbarCtx.displayMsg('Something went wrong: ' + err.message, SnackBarMsgType.Error);
				return err;
			}
		);
	};

	return (
		<div className="report-list">
			{!inLineLoader && reportItems ? (
				<>
					<h2 className="report-list__reportTitle">{title}</h2>
					{!props?.hideDesc && <p className="report-list__reportDescription">{sectionDescription}</p>}
					<Grid
						gridRef={repgridRef}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						rowData={reportItems}
						gridHeight={gridHeight}
						gridMinHeight={true}
						fitToScreen={{ key: 'Title', minWidth: 300 }}
					/>
					{props.seeAll && (
						<Button buttonClass="button--white-small mt-3" onClickHandler={() => navigate('/reports-and-resources')}>
							{props.seeAllLabel}
						</Button>
					)}
				</>
			) : (
				<div className="ag-theme-alpine" style={{ width: '100%', height: 475, marginBottom: 20 }}>
					<InLineLoader />
				</div>
			)}
			{reportItems && pageCount > 1 && props?.pagination && (
				<ReactPaginate
					breakLabel="..."
					nextLabel={<img src={RightArrowPaginate} alt="Right Arrow Icon" />}
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					previousLabel={<img src={LeftArrowPaginate} alt="Left Arrow Icon" />}
					renderOnZeroPageCount={null}
					breakClassName={'page-item'}
					breakLinkClassName={'page-link'}
					containerClassName={'pagination'}
					pageClassName={'page-item'}
					pageLinkClassName={'page-link'}
					previousClassName={'page-item'}
					previousLinkClassName={'page-link'}
					nextClassName={'page-item'}
					nextLinkClassName={'page-link'}
					activeClassName={'active'}
				/>
			)}
			{/* <Docview /> */}
			{/* {snackbarCtx && snackbarCtx.isDisplayed && <Snackbar />} */}
		</div>
	);
}
