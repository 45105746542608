import SelectBox from '../../common/selectBox';
import Button from '../../common/button';

import './style.scss';
const get = require('get-value');

import { useRef } from 'react';

const SortSearch = ({ filterResources, sortResources, searchResource, filteredValue, pageData }) => {
	const inputRef = useRef(null),
		labels = {},
		{ Labels, LOVList } = get(pageData, 'Components.AllResources.Header');

	Labels.forEach(ele => {
		labels[ele.Key] = ele.Phrase;
	});

	const resoruceTypeOptions = [{ value: '', text: labels['AllResourcesHeader.ResourceType'] }],
		sortByOptions = [{ value: '', text: labels['AllResourcesHeader.SortBy'] }];

	LOVList.forEach(ele => {
		if (ele.Key === 'AllResourcesHeader.ResourceContentType') {
			ele.Values.forEach(ele => {
				resoruceTypeOptions.push({ value: ele.Value, text: ele.Value });
			});
		}

		if (ele.Key === 'AllResourcesHeader.SortBy') {
			ele.Values.forEach((ele, index) => {
				sortByOptions.push({ value: index, text: ele.Value });
			});
		}
	});

	return (
		<div className="sort-search">
			<div className="row align-items-end">
				<div className="col-12 col-md-12 col-lg-6 p-md-0">
					<div className="sort-search__search">
						<div className="row">
							<div className="hello col-12 col-md-9 col-lg-8 col-xl-9 d-flex align-item-center">
								<input
									type="text"
									placeholder={labels['AllResourcesHeader.SearchResources']}
									className="form-control sort-search__search-box"
									ref={inputRef}
								/>
							</div>
							<div className="selleo col-12 col-md-3 col-lg-4 col-xl-3 mt-3 mt-md-0 text-end">
								<Button
									buttonClass="button--black"
									onClickHandler={() => {
										searchResource(inputRef.current.value);
									}}>
									{labels['AllResourcesHeader.Search']}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-12 col-lg-6 p-md-0 mt-5 mt-lg-0 text-lg-end sort-search__sort">
					<SelectBox
						options={resoruceTypeOptions}
						className="roundTheme"
						onChange={filterResources}
						value={filteredValue}
					/>
					<SelectBox options={sortByOptions} className="roundTheme ms-3" onChange={sortResources} />
				</div>
			</div>
		</div>
	);
};

export default SortSearch;
