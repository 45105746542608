import React from 'react';
import Header from '../Headers/Header/Header';

export default function Login() {
	return (
		<>
			<Header />
		</>
	);
}
