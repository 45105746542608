import React from 'react';
import TwoColumnTemplateAfterOnboard from '../../components/pageLayout/twoColumnTemplateAfterOnboard';
import CreateReferrals from '../../components/createReferrals';

export default function CreateReferral() {
	return (
		<>
			<TwoColumnTemplateAfterOnboard containerClass="">
				<CreateReferrals />
			</TwoColumnTemplateAfterOnboard>
		</>
	);
}
