import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HttpHelper from '../../helpers/HttpHelper';

import PageLayout from '../../components/pageLayout/pageLayout';
import Loader from '../../components/common/Loader';
import MyAccountSummary from '../../components/myAccount/myAccountSummary';
import MyAccountForm from '../../components/myAccount/myAccountForm';

import './style.scss';

const MyAccount = () => {
	const [data, setData] = useState(null),
		userSelectedLanguage = useSelector(state => state?.userSlice?.userSelectedlanguage),
		http = new HttpHelper();

	const payload = {
		categoryname: 'Default',
		pagename: 'Account',
		language: userSelectedLanguage?.toLowerCase() || 'en'
	};

	useEffect(() => {
		document.title = 'My Account';
	}, []);

	useEffect(() => {
		getData().catch(error => {
			console.warn(error); //eslint-disable-line
		});
	}, [userSelectedLanguage]);

	const getData = async () => {
		let { data } = await http.CMSpost('PageApi/GetPageDetails', payload);
		setData({
			myAccount: data?.Pages?.[0]
		});
	};

	return (
		<>
			{data ? (
				<PageLayout containerClass="my-account" bannerTitle={data.myAccount?.Title} data={data.myAccount}>
					<MyAccountSummary data={data} />
					<MyAccountForm data={data} />
				</PageLayout>
			) : (
				<Loader />
			)}
		</>
	);
};

export default MyAccount;
