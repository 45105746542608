//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../helpers/HttpHelper';
import environments from '../common/environments/environments'; // eslint-disable-line
const http = new httpHelper(); // eslint-disable-line

export const CreateReferralsActions = {
	GetCMSRequestPageDetails: getCMSRequestPageDetails,
	CreateReferral: createReferral
};

function getCMSRequestPageDetails(payload) {
	return http.CMSpost(environments.REFERRAL_PAGE_API_CMS, payload);
}

function createReferral(payload) {
	return http.post(environments.CREATE_REFERRAL_API, payload);
}
