import React, { useMemo } from 'react';
import './Tooltip.scss';

export default function Tooltip(props) {
	const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);
	//console.log(data);
	return (
		<>
			{data.DocumentStatus == 'Rejected' ? (
				<div className="custom-tooltip" style={{ backgroundColor: props.color || 'white' }}>
					<p>
						<span>Reason: </span> {data.DocumentRejectionReason}
					</p>
					{/* <p>
        <span>{data.DocumentStatus}</span>
      </p> */}
					{/* <p>
        <span>Country: </span> {data.country}
      </p>
      <p>
        <span>Total: </span> {data.total}
      </p> */}
				</div>
			) : (
				<div className="custom-tooltip" style={{ backgroundColor: props.color || 'white' }}></div>
			)}
		</>
	);
}
