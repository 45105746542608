const InlineLoader = () => {
	return (
		<div className="text-center">
			<div className="spinner-border my-5" role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
};

export default InlineLoader;
