import BannerUser from '../../assets/images/bannerUser1.png';
import Image from '../common/image';
import './Banner.scss';

export default function Banner({ bannerTitle, bannerImageData }) {
	return (
		<>
			<header role="banner" className="container-fluid tbanner position-relative">
				<div className="container-fluid container-lg p-lg-0">
					<div className="row tbanner__content">
						<div className="col-12 col-md-12 p-0 position-relative">
							<h1 className="tbanner__summary position-absolute">{bannerTitle}</h1>
						</div>
					</div>
				</div>
				<div className="p-0 d-none d-lg-block text-end tbanner__imagecontainer position-absolute top-0 end-0">
					{bannerImageData ? (
						<Image imageData={bannerImageData} className="img-fluid" />
					) : (
						<img src={BannerUser} className="img-fluid tbanner__userimage" alt="Banner User Image"></img>
					)}
				</div>
			</header>
		</>
	);
}
