//import { useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import './style.scss';
import { useMemo, useEffect, useCallback } from 'react';

const Grid = props => {
	//const gridRef = useRef();
	const { gridRef, columnDefs, rowData, gridHeight, gridMinHeight, fitToScreen, ...restProps } = props;

	const defaultColDef = {
		flex: 1,
		sortable: true,
		resizable: true
	};

	const gridStyle = {
		height: parseInt(gridHeight)
	};
	const autoHeight = gridMinHeight ? 'autoHeight' : '';
	useEffect(() => {
		if (gridRef) {
			setTimeout(function () {
				sizeToFit();
			}, 1000);
		}
	}, [gridRef]);

	const sizeToFit = useCallback(() => {
		gridRef?.current?.api?.sizeColumnsToFit({
			defaultMinWidth: 100,
			columnLimits: [fitToScreen]
		});
	}, [gridRef]);
	const autoSizeStrategy = useMemo(() => {
		return {
			type: 'fitGridWidth'
		};
	}, []);
	return (
		<div className="ag-theme-alpine grid" style={gridStyle}>
			{/* <button onClick={sizeToFit}>Resize Columns to Fit Grid Width</button> */}
			<AgGridReact
				ref={gridRef}
				rowData={rowData} // Row Data for Rows
				columnDefs={columnDefs} // Column Defs for Columns
				defaultColDef={defaultColDef} // Default Column Properties
				animateRows={false} // Optional - set to 'true' to have rows animate when sorted
				rowSelection="multiple" // Options - allows click selection of rows
				pagination={false}
				paginationPageSize={10}
				domLayout={autoHeight}
				tooltipShowDelay={0}
				tooltipHideDelay={6000}
				autoSizeStrategy={autoSizeStrategy}
				{...restProps}
			/>
		</div>
	);
};

export default Grid;
