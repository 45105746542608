//import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ConfirmDetails from './components/OnBoarding/ConfirmDetails/ConfirmDetails';
import Login from './components/Login/Login';
import Home from './pages/home';
import Landing from './pages/landing';
import Referrals from './pages/referrals/referrals';
import CreateReferrals from './pages/createReferral/createReferral';
import Dashboard from './pages/dashboard';
import GroupADashboard from './pages/dashboard/testindex';
import ReferralSuccess from './pages/referrals/referralSuccess';
import ClientTeam from './pages/clientTeam';
import ReportsAndResources from './pages/reportsAndResources';
import ReportsAndResourcesView from './pages/reportsAndResourcesView';
import MyAccount from './pages/myAccount';
import AllResources from './pages/resources';
import ErrorHandler from './pages/errorHandler';
import { MsalProvider } from '@azure/msal-react';
import PrivateRoutes from './helpers/PrivateRoutes';
// //import { ErrorBoundary } from 'react-error-boundary';
// import useAutoLogout from './components/common/IdleTimer';
// import Modalpopup from './components/common/Modal/Modalpopup';
import PolicyCookie from './components/footer/policyCookie/PolicyCookie';
import PolicyPrivacy from './components/footer/policyPrivacy/PolicyPrivacy';
import PolicyTC from './components/footer/policyTC/PolicyTC';
import './App.scss';

function App({ msalInstance }) {
	// const [hasRouteChanged, sethasRouteChanged] = useState(false);
	// const location = useLocation();
	// const navigate = useNavigate();
	// useEffect(() => {
	// 	sethasRouteChanged(true);
	// }, [location]);
	// useEffect(() => {
	// 	console.log('Session:', sessionStorage.getItem('UserId')); //eslint-disable-line
	// 	if (sessionStorage.getItem('UserId') != '' && sessionStorage.getItem('UserId') != null) {
	// 		if (!hasRouteChanged) navigate('/');
	// 	}
	// }, []);

	return (
		<div className="App">
			<MsalProvider instance={msalInstance}>
				{/* <ErrorBoundary fallback={<ErrorHandler />}> */}
				<Routes>
					<Route element={<PrivateRoutes />}>
						<Route path="/home" element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/referrals" element={<Referrals />} />
						<Route path="/create-referrals" element={<CreateReferrals />} />
						<Route path="/referral-success" element={<ReferralSuccess />} />
						<Route path="/reports-and-resources" element={<ReportsAndResources />} />
						<Route path="/reports-view/:reportUrl/:resourceTypeId" element={<ReportsAndResourcesView />} />
						<Route path="/resource-view/:reportUrl/:resourceView" element={<ReportsAndResourcesView />} />
						<Route path="/dashboards" element={<Dashboard />} />
						<Route path="/testdashboards" element={<GroupADashboard />} />
						<Route path="/client-team" element={<ClientTeam />} />
						<Route path="/my-account" element={<MyAccount />} />
						<Route path="/all-resources" element={<AllResources />} />
						<Route path="/confirm-details" element={<ConfirmDetails />} />
					</Route>
					<Route path="/cookie-notice" element={<PolicyCookie />} />
					<Route path="/privacy-notice" element={<PolicyPrivacy />} />
					<Route path="/terms-of-use" element={<PolicyTC />} />
					<Route path="/" element={<Landing />} />
					<Route path="*" element={<ErrorHandler />} />
				</Routes>
				{/* </ErrorBoundary> */}
				{/* <Modalpopup show={modalShow} modalSize={'md'}>
					<div className="modal modal-content">
						<div className="modal-header">
							<div className="modal-title">You have been Idle for a while</div>
						</div>
						<div className="modal-body">You will be logged out soon</div>
						<div className="modal-footer">
							<button type="button" id="modalConfirmButton" onClick={logOut} className="confirmBtn">
								Log me out
							</button>
							<button type="button" id="modalCancelButton" onClick={() => setModalShow(false)} className="cancelBtn">
								Keep me signed in
							</button>
						</div>
					</div>
				</Modalpopup> */}
			</MsalProvider>
		</div>
	);
}

export default App;
