//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------
import React, { useState, useRef, useEffect, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import ReactPaginate from 'react-paginate';
import { saveAs } from 'file-saver';
import DownlodIcon from '../../../assets/images/DownlodIcon.png';
import ClockIcon from '../../../assets/images/clockIcon.png';
import CheckIcon from '../../../assets/images/checkIcon.png';
import DenyIcon from '../../../assets/images/rejectedIcon.png';
import InfoIcon from '../../../assets/images/info.png';
import RightArrowPaginate from '../../../assets/images/RightArrowPaginate.png';
import LeftArrowPaginate from '../../../assets/images/LeftArrowPaginate.png';
import { customDateConverter, xxlDataForReferral, normalDataForReferral } from '../../../helpers/constants';
import CustomTooltip from '../../../components/common/Grid/tooltip/Tooltip';
import httpHelper from '../../../helpers/HttpHelper';
import environments from '../../common/environments/environments'; // eslint-disable-line
import { ReferralsActions } from '../ReferralsActions';
import Loader from '../../common/Loader';
import InLineLoader from '../../common/inlineLoader';
import Grid from '../../../components/common/Grid';
import '../../common/Pagination/pagination.scss';
//import Snackbar from '../../../components/common/snackBar/SnackBar';
//import SnackbarContext from '../../../components/common/snack';
import useScreenSize from '../../../helpers/userScreensize';
export default function MySubmittedRequests(props) {
	const screenSize = useScreenSize(); //eslint-disable-line
	//const snackbarCtx = useContext(SnackbarContext);
	const refgridRef = useRef();
	const shouldLog = useRef(true);
	const http = new httpHelper(); // eslint-disable-line
	const [columnDefs, setColumnDefs] = useState();
	const [pageSize, setPageSize] = useState(10); /* eslint-disable-line */
	const [loader, setLoader] = useState(false);
	const [inLineLoader, setInLineLoader] = useState(false);
	const statusRender = params => {
		let bgcolor =
			params.value === 'Approved'
				? 'approvedColor'
				: params.value === 'Pending'
				? 'pendingColor'
				: params.value === 'Rejected'
				? 'rejectedColor'
				: params.value === 'In progress'
				? 'inprogressColor'
				: 'pendingColor';
		let statusImage =
			params.value === 'Approved'
				? CheckIcon
				: params.value === 'Pending'
				? ClockIcon
				: params.value === 'Rejected'
				? DenyIcon
				: params.value === 'In progress'
				? InfoIcon
				: ClockIcon;
		let statusText =
			params.value == 'Approved'
				? props?.submittedRequestData?.GridStatus?.['Status.Approved']
				: params.value === 'Pending'
				? props?.submittedRequestData?.GridStatus?.['Status.Pending']
				: params.value === 'Rejected'
				? props?.submittedRequestData?.GridStatus?.['Status.Rejected']
				: params.value === 'In progress'
				? props?.submittedRequestData?.GridStatus?.['Status.InProgress']
				: props?.submittedRequestData?.GridStatus?.['Status.Pending'];
		return (
			<div className={`gridStatus ${bgcolor}`}>
				<img src={statusImage} className="statusIcon" alt="View" />
				{statusText}
			</div>
		);
	};

	const documentNameRender = params => {
		const resDate = params.value;
		return <div>{resDate.replace('.xlsx', '')}</div>;
	};

	const dateRender = params => {
		const resDate = customDateConverter(params.value);
		let getDateAndTime = resDate.split(' ');
		return (
			<>
				<div style={{ fontWeight: '600' }}>{getDateAndTime[0]}</div>
				<span>{getDateAndTime[1]}</span>
			</>
		);
	};

	const rejectRender = params => {
		if (params.value != '' && params.value.length > 5) {
			//let dummydata = 'Rejected due to Invalid data found in the sheet';
			let dummydata = params.value;
			return dummydata.substring(0, 30) + '...';
		}
	};

	const viewActionRender = params => {
		if (params.value == 'Rejected')
			return (
				<div className="viewText">
					<img src={DownlodIcon} className="viewArrow" onClick={() => downloadTemplate()} alt="Download Icon" />
				</div>
			);
	};

	useEffect(() => {
		let widthData = screenSize.width >= 1920 ? xxlDataForReferral : normalDataForReferral;
		let headers = [
			{
				headerName: props?.submittedRequestData?.GridHeaders?.['Referrals.SubmitttedReferralsList.CandidateName'],
				field: 'DocumentName',
				colId: 'DocumentName',
				width: widthData[0].documentNameWidth,
				cellRenderer: documentNameRender,
				cellStyle: { fontWeight: '600' }
			},
			{
				headerName: props?.submittedRequestData?.GridHeaders?.['Referrals.SubmitttedReferralsList.AddedDate'],
				field: 'DocumentUploadedTimeStamp',
				headerClass: 'addedOn',
				width: widthData[0].documentUploadedTimeStampWidth,
				wrapText: true,
				autoHeight: true,
				cellRenderer: dateRender,
				cellStyle: { lineHeight: '24px' }
			},
			{
				headerName: props?.submittedRequestData?.GridHeaders?.['Referrals.SubmitttedReferralsList.Status'],
				field: 'DocumentStatus',
				width: widthData[0].documentStatus,
				cellRenderer: statusRender
			},
			{
				headerName: props?.submittedRequestData?.GridHeaders?.['Referrals.SubmitttedReferralsList.RejectionReason'],
				field: 'DocumentRejectionReason',
				width: widthData[0].documentRejectionReasonWidth,
				cellRenderer: rejectRender,
				tooltipField: 'DocumentRejectionReason',
				tooltipComponentParams: { color: '#ececec' }
			},
			{
				headerName: props?.submittedRequestData?.GridHeaders?.['Referrals.SubmitttedReferralsList.Download'],
				field: 'DocumentStatus',
				width: widthData[0].viewActionWidth,
				cellRenderer: viewActionRender
			}
		];
		setColumnDefs(headers);
	}, [props.submittedRequestData]);

	const defaultColDef = useMemo(() => ({
		sortable: true,
		resizable: true,
		tooltipComponent: CustomTooltip
	}));

	// Load Submitted Request From API
	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;
			setInLineLoader(true);
			getRequestList();
		}
	}, []);

	const getRequestList = async () => {
		let payload = {};
		payload.PageNumber = 1;
		payload.PageSize = pageSize;
		await ReferralsActions.GetRequestList(payload).then(
			async res => {
				if (res.status === 200 && res?.data?.referralUploads?.length > 0) {
					paginateItems(0, res?.data?.referralUploads, res?.data?.TotalCount);
				} else {
					setReferralItems([]);
					setInLineLoader(false);
					//snackbarCtx.displayMsg('Something went wrong ', SnackBarMsgType.Error);
				}
			},
			err => {
				return err;
			}
		);
	};

	//For Paginate
	const [pageCount, setPageCount] = useState(0);
	const [referralItems, setReferralItems] = useState([]);

	const paginateItems = (itemOffset, ReferralList, totalCount) => {
		setReferralItems(ReferralList);
		const pageCount = Math.ceil(totalCount / pageSize);
		setPageCount(pageCount);
		setInLineLoader(false);
	};

	const handlePageClick = async event => {
		setInLineLoader(true);
		let payload = {};
		payload.PageNumber = event.selected + 1;
		payload.PageSize = pageSize;
		await ReferralsActions.GetRequestList(payload).then(
			async res => {
				if (res.status === 200 && res?.data?.referralUploads?.length > 0) {
					paginateItems(event.selected, res?.data?.referralUploads, res?.data?.TotalCount);
				} else {
					//snackbarCtx.displayMsg('Something went wrong ', SnackBarMsgType.Error);
				}
			},
			err => {
				//snackbarCtx.displayMsg('Something went wrong ', SnackBarMsgType.Error);
				return err;
			}
		);
	};
	//For Paginate

	/* eslint-disable */
	const downloadTemplate = async (filePath, fileName) => {
		const selectedRows = refgridRef.current.api.getSelectedRows();
		setLoader(true);
		if (selectedRows[0].Id) {
			const result = http
				.documentsDownloadGet(
					environments.DOWNLOAD_REFERRAL_API + '/' + selectedRows[0]?.Id + '/' + environments.BRAND_ID
				)
				.then(res => {
					let contentTypeHeader = res.headers['content-type'];
					var blob = new Blob([res.data], {
						type: contentTypeHeader.toLowerCase(),
						charset: 'UTF-8'
					});
					let fileName = selectedRows[0]?.DocumentName;
					let reason = selectedRows[0]?.DocumentRejectionReason;
					console.log('blob', blob);
					saveAs(blob, `${fileName}`);
					downloadTxtFile(reason, fileName);
				})
				.catch(error => {
					console.error('DOWNLOAD ERROR', error.message);
					setLoader(false);
				});
		}
	};
	/* eslint-enable */

	const downloadTxtFile = async (reason, filename) => {
		var blob = new Blob([reason], { type: 'text/plain;charset=utf-8' });
		let NotePadFileNamePrefix =
			props?.submittedRequestData?.GridHeaders['Referrals.SubmitttedReferralsList.RejectionReasonFileName'];
		let extractedfileName = filename.replace('.xlsx', '');
		let reasonNotePadFileName = NotePadFileNamePrefix.replace('FILENAME', extractedfileName.toUpperCase());
		saveAs(blob, `${reasonNotePadFileName}.txt`);
		setLoader(false);
	};

	return (
		<div className="requestList p-sm-3 p-md-0">
			<p className="reqTitle">{props?.submittedRequestData?.GridTitle}</p>
			{loader && <Loader />}
			{!inLineLoader && referralItems ? (
				<Grid
					gridRef={refgridRef}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					rowData={referralItems}
					gridHeight=""
					gridMinHeight={true}
					fitToScreen={{ key: 'DocumentName', minWidth: 300 }}
				/>
			) : (
				<div className="ag-theme-alpine" style={{ width: '100%', height: 475, marginBottom: 20 }}>
					<InLineLoader />
				</div>
			)}
			{referralItems && (
				<ReactPaginate
					breakLabel="..."
					nextLabel={<img src={RightArrowPaginate} alt="Right Arrow Icon" />}
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					previousLabel={<img src={LeftArrowPaginate} alt="Left Arrow Icon" />}
					renderOnZeroPageCount={null}
					breakClassName={'page-item'}
					breakLinkClassName={'page-link'}
					containerClassName={'pagination'}
					pageClassName={'page-item'}
					pageLinkClassName={'page-link'}
					previousClassName={'page-item'}
					previousLinkClassName={'page-link'}
					nextClassName={'page-item'}
					nextLinkClassName={'page-link'}
					activeClassName={'active'}
				/>
			)}
			{/* {snackbarCtx && snackbarCtx.isDisplayed && <Snackbar />} */}
		</div>
	);
}
