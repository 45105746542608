import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HttpHelper from '../../helpers/HttpHelper';

import PageLayout from '../../components/pageLayout/pageLayout';
import Resources from '../../components/thoughtLeadership/resources';
import InlineLoader from '../../components/common/inlineLoader';

const AllResources = () => {
	const [pageData, setPageData] = useState(null),
		userSelectedLanguage = useSelector(state => state?.userSlice?.userSelectedlanguage),
		http = new HttpHelper();

	const pagePayload = {
		categoryname: 'Default',
		pagename: 'ReportsAndResources',
		language: userSelectedLanguage?.toLowerCase() || 'en'
	};

	useEffect(() => {
		document.title = 'Reports & Resources';
	}, []);

	useEffect(() => {
		getPageData().catch(error => {
			console.warn(error); //eslint-disable-line
		});
	}, [userSelectedLanguage]);

	const getPageData = async () => {
		let { data } = await http.CMSpost('PageApi/GetPageDetails', pagePayload);
		setPageData(data?.Pages?.[1]);
	};

	return (
		<>
			{pageData ? (
				<PageLayout containerClass="all-resources" bannerTitle={pageData?.BrowserTitle} data={pageData}>
					<div className="container-fluid container-lg p-0 vs-comp">
						<div className="row p-sm-3 p-md-3 p-lg-1">
							<Resources count={12} showLoadMore={true} showFilters={true} pageData={pageData} />
						</div>
					</div>
				</PageLayout>
			) : (
				<InlineLoader />
			)}
		</>
	);
};
export default AllResources;
