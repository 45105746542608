import { useNavigate } from 'react-router-dom';
import Button from '../../common/button';
import Resources from '../resources';
import TrackActivity from '../../../helpers/TrackActivity';

const ThoughtLeadershipResources = ({ pageData }) => {
	const navigate = useNavigate();
	const tracker = new TrackActivity();

	return (
		<div className="vs-comp">
			<div className="row align-items-end p-2 p-lg-0">
				<div className="col-12 col-md-7">
					<h2 className="my-account__summary-title">{pageData?.Title}</h2>
					<p className="m-0">{pageData?.Description}</p>
				</div>
				<div className="col-12 col-md-5 text-md-end mt-3 mt-md-0 d-none d-md-block">
					<Button
						onClickHandler={() => {
							navigate('/all-resources');
							let userId = window.sessionStorage.getItem('UserId');
							tracker.sendActivity('UserVisitThoughtLeadership', userId, userId, 'ThoughtLeadership');
						}}>
						{pageData?.Labels?.[0]?.Phrase}
					</Button>
				</div>
			</div>
			<Resources first={true} count={7} />
			<div className="d-md-none">
				<Button
					onClickHandler={() => {
						navigate('/all-resources');
						let userId = window.sessionStorage.getItem('UserId');
						tracker.sendActivity('UserVisitThoughtLeadership', userId, userId, 'ThoughtLeadership');
					}}>
					{pageData?.Labels?.[0]?.Phrase}
				</Button>
			</div>
		</div>
	);
};

export default ThoughtLeadershipResources;
