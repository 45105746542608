import { useState, useContext, useEffect } from 'react';
import { AccoutTeamContext } from '../../../helpers/contexts';
import HttpHelper from '../../../helpers/HttpHelper';
import Modalpopup from '../../common/Modal/Modalpopup';
import Button from '../../common/button';
import ScheduleMeetingForm from './scheduleMeetingForm';
import { MeetingList } from '../../../store/slices/userSlice';
import { connect } from 'react-redux';

import './style.scss';

const MeetingConfirmation = ({ toggleConfirmation, toggleScheduleMeetingForm, payload }) => {
	const { MeetingDate, PreferredTimeSlot } = payload,
		data = useContext(AccoutTeamContext),
		{ pageDataMeeting, common } = data,
		requestFormData = pageDataMeeting?.Labels,
		requestFormLabel = {},
		commonLabel = {};

	requestFormData?.forEach(element => {
		requestFormLabel[element.Key] = element.Phrase;
	});
	common?.Labels?.forEach(element => {
		commonLabel[element.Key] = element.Phrase;
	});

	return (
		<div className="meeting-confirmation text-center">
			<h2>{requestFormLabel['ClientTeam.Meeting.MeetingReqPopup']}</h2>
			<p className="mt-4">
				{requestFormLabel['ClientTeam.Meeting.MeetingReqPopupDesc1']}
				<br />
				<strong>
					{`${requestFormLabel['ClientTeam.Meeting.MeetingReqPopupPlaceholderfortime']
						.replace('{date}', MeetingDate)
						.replace('{time}', PreferredTimeSlot)}`}
				</strong>
			</p>

			<p>{requestFormLabel['ClientTeam.Meeting.MeetingReqPopupDesc2']}</p>
			<Button buttonClass="button--blue mt-2" type="submit" onClickHandler={toggleConfirmation}>
				{commonLabel['Common.ButtonText.Close']}
			</Button>
			<Button
				buttonClass="button--white mt-3"
				onClickHandler={() => {
					toggleScheduleMeetingForm();
					toggleConfirmation();
				}}>
				{requestFormLabel['ClientTeam.Meeting.RequestAMeeting']}
			</Button>
		</div>
	);
};

const ScheduleMeeting = props => {
	const data = useContext(AccoutTeamContext),
		[displaysForm, setDisplaysForm] = useState(false),
		[displayConfirmation, setConfirmation] = useState(false),
		[payload, setPayload] = useState(null),
		{
			pageDataAccountTeam: { Description, LinkList }
		} = data;
	const http = new HttpHelper();
	const [teamMemberCount, setTeamMemberCount] = useState(null);
	const showScheduleMeetingForm = () => {
		setDisplaysForm(!displaysForm);
	};

	const showConfirmation = () => {
		setConfirmation(!displayConfirmation);
	};

	const getData = async () => {
		let userId = window.sessionStorage.getItem('UserId');
		const userSelectedlanguage = props.userSelectedlanguage || 'en';
		let { data } = await http.Get('User-Profile/account/' + userId + '/' + userSelectedlanguage + '/team');
		if (data.length >= 0) {
			setTeamMemberCount(data.length);
		}
	};

	useEffect(() => {
		getData();
	}, []);
	return (
		<>
			<div className="schedule-meeting">
				<h4>{Description}</h4>

				<Button
					disableStatus={props.meetingsData?.length >= 5 || teamMemberCount == 0 ? true : false}
					onClickHandler={showScheduleMeetingForm}>
					{LinkList?.[0].Link.Text}
				</Button>
			</div>

			<Modalpopup show={displaysForm} modalSize={'md'} onClose={showScheduleMeetingForm} closeButtonVariant={true}>
				<ScheduleMeetingForm
					cancelHandler={showScheduleMeetingForm}
					toggleScheduleMeetingForm={showScheduleMeetingForm}
					toggleConfirmation={showConfirmation}
					setPayload={setPayload}
				/>
			</Modalpopup>

			<Modalpopup show={displayConfirmation} modalSize={'md'}>
				<MeetingConfirmation
					toggleScheduleMeetingForm={showScheduleMeetingForm}
					toggleConfirmation={showConfirmation}
					payload={payload}
				/>
			</Modalpopup>
		</>
	);
};
const mapStateToProps = state => {
	return {
		meetingsData: state.userSlice.meetingsData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		meetingList: () => dispatch(MeetingList())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleMeeting);
