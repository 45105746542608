import { useEffect, useState } from 'react';
const Image = ({ imageData, tileHeight, styleclass, ...restProps }) => {
	const { smallImage, mediumImage, largeImage } = imageData;
	const [height, setHeight] = useState('100%');
	const [width, setWidth] = useState('100%'); //eslint-disable-line
	//console.log(imageData, tileHeight);
	useEffect(() => {
		if (tileHeight) {
			setHeight(tileHeight);
		}
	}, [tileHeight]);
	return (
		<picture {...restProps}>
			<source media="(min-width:992px)" srcSet={largeImage.ImageUrl} />
			<source media="(min-width:768px)" srcSet={mediumImage.ImageUrl} />
			<img
				src={smallImage.ImageUrl}
				alt={smallImage.AltText ? smallImage.AltText : 'Thought Leadership Image'}
				height={height}
				width={width}
				className={styleclass}
			/>
		</picture>
	);
};

export default Image;
