import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HttpHelper from '../../helpers/HttpHelper';
import Image from '../common/image';
import htmlParser from 'html-react-parser';

import './footer.scss';
const get = require('get-value');

const secondaryLogos = ['Footer.ExperissLogo', 'Footer.ManpowerLogo', 'Footer.TalentSolutionLogo'];

const Footer = () => {
	//const navigate = useNavigate();
	const location = useLocation();
	const [showNav, setShowNav] = useState(false),
		[pageData, setPageData] = useState(null),
		userSelectedLanguage = useSelector(state => state?.userSlice?.userSelectedlanguage),
		isLangChanged = useSelector(state => state?.userSlice?.isLangChanged);

	const http = new HttpHelper(),
		payload = { categoryname: 'Default', pagename: 'HeaderFooter', language: userSelectedLanguage || 'en' };

	const getData = async () => {
		const { data } = await http.CMSpost('PageApi/GetPageDetails', payload);
		setPageData(get(data, 'Pages.1.Components.HeaderFooter.Footer'));
	};

	useEffect(() => {
		if (userSelectedLanguage) {
			getData().catch(error => {
				console.warn(error); //eslint-disable-line
			});
		}
	}, [isLangChanged, userSelectedLanguage]);

	return (
		pageData && (
			<footer
				role="contentinfo"
				className={location.pathname === '/home' ? 'container-fluid footer' : 'container-fluid footer vs-comp'}>
				<div className="container">
					<div className="row">
						{pageData?.MediaList?.length > 0 && (
							<>
								{pageData?.MediaList.map(media => {
									if (media.Key === 'Footer.ManpowerGroupLogo') {
										const imageData = {
											smallImage: media.SmallImage,
											mediumImage: media.MediumImage,
											largeImage: media.LargeImage
										};

										const { Url, AltText = {}, Target = '_self' } = media.Link;

										return (
											<div className="d-flex justify-content-center mb-3" key={media.Key}>
												<a href={Url} title={AltText} target={Target}>
													<Image imageData={imageData} styleclass={media.Key.replace('.', '')} />
												</a>
											</div>
										);
									} else {
										return null;
									}
								})}

								<div className="d-none d-md-flex justify-content-center gap-5 mb-4">
									{pageData?.MediaList.map(media => {
										if (secondaryLogos.includes(media.Key)) {
											const imageData = {
												smallImage: media.SmallImage,
												mediumImage: media.MediumImage,
												largeImage: media.LargeImage
											};

											const { Url, AltText = {}, Target = '_self' } = media.Link;

											return (
												<a href={Url} title={AltText} target={Target} key={media.Key}>
													<Image imageData={imageData} styleclass={media.Key.replace('.', '')} />
												</a>
											);
										} else {
											return null;
										}
									})}
								</div>
							</>
						)}

						<div className="d-none d-lg-block col-12">
							<p className="content">{htmlParser(pageData?.RichTexts?.[0]?.Value)}</p>
						</div>
						<div className="col-12">
							<p className="copyright mb-0 mb-md-4">
								{pageData?.Labels?.[0]?.Phrase}
								<button
									className={`btn footer-link-toggle px-0 ${showNav}`}
									type="button"
									onClick={() => setShowNav(showNav == 'show' ? '' : 'show')}></button>
							</p>
						</div>
						<div className={`col-12 mobViewlogo ${showNav}`}>
							<div className="d-md-none justify-content-center gap-5 mb-4">
								{pageData?.MediaList.map(media => {
									if (secondaryLogos.includes(media.Key)) {
										const imageData = {
											smallImage: media.SmallImage,
											mediumImage: media.MediumImage,
											largeImage: media.LargeImage
										};

										const { Url, AltText = {}, Target = '_self' } = media.Link;

										return (
											<a href={Url} title={AltText} target={Target} key={media.Key}>
												<Image imageData={imageData} styleclass={media.Key.replace('.', '')} />
											</a>
										);
									} else {
										return null;
									}
								})}
							</div>
							<p className="d-md-none content">{htmlParser(pageData?.RichTexts?.[0]?.Value)}</p>
						</div>
						<div className="col-12">
							<ul role="list" className={`footer__nav p-0 ${showNav}`}>
								{pageData.LinkList.map(({ Link: Lnk }, index) => {
									const { Text, Url, AltText } = Lnk;
									return (
										<li role="listitem" key={`footerNav${index}`}>
											<Link to={Url} target="_blank" title={AltText} role="link">
												{Text}
											</Link>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</footer>
		)
	);
};

export default Footer;
