// import { useEffect, useState } from 'react';
// export default function pdfViewer({ embedBlob, testpdfUrl }) {
// 	const [embedFile, setEmbedFile] = useState();
// 	const [embedFileType, setEmbedFileType] = useState();
// 	useEffect(() => {
// 		console.log('testpdfUrl', testpdfUrl); // eslint-disable-line
// 		let contentTypeHeader = embedBlob.headers['content-type'];
// 		var blob = new Blob([embedBlob.data], {
// 			type: contentTypeHeader.toLowerCase(),
// 			charset: 'UTF-8'
// 		});
// 		console.log('contentTypeHeader', contentTypeHeader.toLowerCase()); // eslint-disable-line
// 		var reader = new FileReader();
// 		reader.readAsDataURL(blob);
// 		reader.onloadend = function () {
// 			if (contentTypeHeader === 'application/pdf') {
// 				let base64data = reader.result + '#toolbar=0&page=1&view=FitH&zoom=100';
// 				setEmbedFile('data:application/pdf;base64,' + base64data.replace('data:', '').replace(/^.+,/, ''));
// 				setEmbedFileType('application/pdf');
// 			}
// 		};
// 	}, [embedBlob]);
// 	return (
// 		<>
// 			{embedFileType === 'application/pdf' && (
// 				<embed src={embedFile} type={embedFileType} width="100%" className="pdfViewer" />
// 			)}
// 		</>
// 	);
// }
// import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

// import { useState } from 'react';
// import { Document, Page } from 'react-pdf';

// export default function pdfViewer({ testpdfUrl }) {
// 	const [numPages, setNumPages] = useState(null);
// 	const [pageNumber, setPageNumber] = useState(1);

// 	function onDocumentLoadSuccess({ numPages }) {
// 		setNumPages(numPages);
// 		setPageNumber(1);
// 	}

// 	function changePage(offSet) {
// 		setPageNumber(prevPageNumber => prevPageNumber + offSet);
// 	}

// 	function changePageBack() {
// 		changePage(-1);
// 	}

// 	function changePageNext() {
// 		changePage(+1);
// 	}

// 	return (
// 		<div>
// 			<header className="App-header">
// 				<Document file={testpdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
// 					<Page height="600" pageNumber={pageNumber} />
// 				</Document>
// 				<p>
// 					Page {pageNumber} of {numPages}
// 				</p>
// 				{pageNumber > 1 && <button onClick={changePageBack}>Previous Page</button>}
// 				{pageNumber < numPages && <button onClick={changePageNext}>Next Page</button>}
// 			</header>
// 			{/* <center>
// 				<div>
// 					<Document file={testpdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
// 						{Array.from(new Array(numPages), (el, index) => (
// 							<Page key={`page_${index + 1}`} pageNumber={index + 1} />
// 						))}
// 					</Document>
// 				</div>
// 			</center> */}
// 		</div>
// 	);
// }

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import './pdfViewer.scss';
export default function pdfViewer({ testpdfUrl, viewType }) {
	const docs = [
		{ uri: testpdfUrl, fileType: viewType.toLowerCase() } // Remote file
	];

	let accessToken = sessionStorage.getItem('AccessToken');

	const headers = {
		Authorization: `Bearer ${accessToken}`,
		Tokentype: 'ADB2C'
	};

	return (
		<>
			<DocViewer
				style={{ height: '120vh', width: '100%' }}
				documents={docs}
				pluginRenderers={DocViewerRenderers}
				config={{
					header: {
						disableHeader: true,
						disableFileName: true,
						retainURLParams: true
					},
					csvDelimiter: ',', // "," as default,
					pdfZoom: {
						defaultZoom: 1.1, // 1 as default,
						zoomJump: 0.2 // 0.1 as default,
					},
					pdfVerticalScrollByDefault: true // false as default
				}}
				theme={{
					primary: '#5c4bb9',
					secondary: '#ffffff',
					tertiary: '#5c4bb9',
					textPrimary: '#ffffff',
					textSecondary: '#efefef',
					textTertiary: '#00000099',
					disableThemeScrollbar: false
				}}
				requestHeaders={headers}
			/>
		</>
	);
}
