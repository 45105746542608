import React, { useRef, useState, useEffect } from 'react';
import HttpHelper from '../../helpers/HttpHelper';
//import GroupA from '../../components/dashboards/GroupA';
//import GroupB from '../../components/dashboards/GroupB';
import GroupA from '../../components/dashboards/GroupA';
import environment from '../../components/common/environments/environments';
import { Reset } from '../../store/slices/userSlice';
import { connect } from 'react-redux';
import './style.scss';

//const get = require('get-value');

const TestDashboard = props => {
	//const [tabTitles, setTabTitles] = useState(null);
	//const [groupAPageData, setGroupAPageData] = useState(null);

	const [groupAConfig, setGroupAConfig] = useState(null);

	const http = new HttpHelper();
	const shouldLog = useRef(true);
	// const payload = {
	// 	categoryname: 'Default',
	// 	pagename: 'Dashboard',
	// 	language: props.userSelectedlanguage.toLowerCase() || 'en'
	// };
	// const getData = async () => {
	// 	await http
	// 		.CMSpost('PageApi/GetPageDetails', payload)
	// 		.then(res => {
	// 			setPageData({
	// 				dashboard: res.data?.Pages?.[0]
	// 			});
	// 			let tabLabels = [];
	// 			get(res.data?.Pages?.[0], 'Components.Dashboards.Submenu.Labels').forEach(ele => {
	// 				tabLabels[ele.Key] = ele.Phrase;
	// 			});
	// 			setTabTitles(tabLabels);

	// 			let groupAData = [];
	// 			groupAData['title'] = get(res.data?.Pages?.[0], 'Components.Dashboards.GroupA.Title');
	// 			groupAData['description'] = get(res.data?.Pages?.[0], 'Components.Dashboards.GroupA.Description');
	// 			let groupAJumptoLabels = [];
	// 			get(res.data?.Pages?.[0], 'Components.Dashboards.GroupA.Labels').forEach(ele => {
	// 				groupAJumptoLabels[ele.Key] = ele.Phrase;
	// 			});
	// 			groupAData['jumptolables'] = groupAJumptoLabels;
	// 			groupAData['jumptotitle'] = Object.keys(tabLabels).length > 0 ? tabLabels['Dashboard.Common.JumpTo'] : '';
	// 			setGroupAPageData(groupAData);

	// 			let groupBData = [];
	// 			groupBData['title'] = get(res.data?.Pages?.[0], 'Components.Dashboards.GroupB.Title');
	// 			groupBData['description'] = get(res.data?.Pages?.[0], 'Components.Dashboards.GroupB.Description');
	// 			let groupBJumptoLabels = [];
	// 			get(res.data?.Pages?.[0], 'Components.Dashboards.GroupB.Labels').forEach(ele => {
	// 				groupBJumptoLabels[ele.Key] = ele.Phrase;
	// 			});
	// 			groupBData['jumptolables'] = groupBJumptoLabels;
	// 			groupBData['jumptotitle'] = Object.keys(tabLabels).length > 0 ? tabLabels['Dashboard.Common.JumpTo'] : '';
	// 			//console.log('groupBData', groupBData);
	// 			setGroupBPageData(groupBData);
	// 		})
	// 		.catch(error => {
	// 			return error;
	// 		});
	// };
	const getDashboardConfig = async () => {
		let payload = {};
		await http
			.Get(environment.DASHBOARD_CONFIG + '/' + environment.BRAND_ID + '/dashboardconfig', payload)
			.then(res => {
				//console.log('res', res.data);
				if (res.data) {
					let groupAData = [];
					let groupBData = [];

					res.data.map(configData => {
						if (configData.Name.includes('GROUPA')) {
							groupAData[configData.Name] = configData.TypeId;
						} else if (configData.Name.includes('GROUPB')) {
							groupBData[configData.Name] = configData.TypeId;
						} else if (configData.Name.includes('WORKSPACE')) {
							groupAData['WORKSPACE_ID'] = configData.TypeId;
							groupBData['WORKSPACE_ID'] = configData.TypeId;
						}
					});
					//console.log('groupAData', groupAData);
					setGroupAConfig(groupAData);
					//setGroupBConfig(groupBData);
				}
			})
			.catch(error => {
				return error;
			});
	};
	useEffect(() => {
		document.title = 'Dashboard';
		if (shouldLog.current) {
			getDashboardConfig();
			props.reset();
			shouldLog.current = false;
		}
	}, []);

	useEffect(() => {
		if (props.isLangChanged) {
			props.reset();
		}
	}, [props.isLangChanged, props.userSelectedlanguage]);
	return <>{groupAConfig && <GroupA groupAConfig={groupAConfig} />}</>;
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		headerPageContent: state.userSlice.headerPageContent,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TestDashboard);
