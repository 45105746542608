//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------
import React from 'react';
import { useEffect, useState } from 'react';
import './DragDropFile.scss';
import FileIcon from '../../../assets/images/FileIcon.png';

export default function DragDropFile(props) {
	const [dragActive, setDragActive] = useState(false);
	const [currentFileName, setCurrentFileName] = useState('');
	/* eslint-disable */
	const [errors, setErrors] = useState([]);
	//const [errorInFile, setErrorInFile] = useState('OFF');
	/* eslint-enable */

	const inputRef = React.useRef(null);

	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	const handleDrop = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0] && validate(e.dataTransfer.files)) {
			setCurrentFileName(e.dataTransfer.files[0]);
			props.uploadedFile(e.dataTransfer.files[0]);
			props.errorInFile('');
		} else {
			//setErrorInFile('ON');
			props.uploadedFile('');
			props.errorInFile('Invalid File Format');
		}
	};

	const handleChange = function (e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0] && validate(e.target.files)) {
			//console.log('asdfasd');
			props.uploadedFile(e.target.files[0]);
			setCurrentFileName(e.target.files[0]);
			props.errorInFile('');
		} else {
			//setErrorInFile('ON');
			props.uploadedFile('');
			props.errorInFile('Invalid File Format');
		}
	};

	const onButtonClick = () => {
		inputRef.current.click();
	};

	const validate = files => {
		const selectedFile = files[0];
		const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
		let isValid = true;
		let errors = {};
		const MAX_FILE_SIZE = 200000;

		if (!allowedTypes.includes(selectedFile?.type)) {
			isValid = false;
			errors['fileFormat'] = 'Invalid File Format';
		}

		const fileSizeKiloBytes = selectedFile.size / 1024;
		if (fileSizeKiloBytes > MAX_FILE_SIZE) {
			errors['fileSize'] = 'File size is greater than maximum limit.';
		}
		setErrors(errors);

		return isValid;
	};

	useEffect(() => {
		if (props.resetAttachment == true) {
			inputRef.current.value = null;
			setCurrentFileName('');
			props.uploadedFile('');
		}
	}, [props.resetAttachment]);

	return (
		<form id="form-file-upload" onDragEnter={handleDrag} onSubmit={e => e.preventDefault()}>
			<input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
			<label
				id="label-file-upload"
				htmlFor="input-file-upload"
				className={dragActive ? 'drag-active' : '' || currentFileName?.name ? 'disableFileUpload' : ''}>
				<div>
					<img className="img-fluid" src={FileIcon} alt="File Icon" />
					<p>{props.cmsLabels?.draganddroptitle}</p>
					<button className="upload-button" onClick={onButtonClick}>
						{props.showError ? 'Re-upload file' : props.cmsLabels?.['Referrals.CreateReferral.Upload']}
					</button>
				</div>
			</label>
			{dragActive && (
				<div
					id="drag-file-element"
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}></div>
			)}
		</form>
	);
}
