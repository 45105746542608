import React from 'react';
import { useState, useEffect, useMemo, useRef } from 'react';
import PageLayout from '../../components/pageLayout/pageLayout';
import ProgramDescriptor from '../../components/programDescriptor/';
import ReportsAndResources from '../../components/reportsAndResources/reports';
import ThoughtLeadershipResources from '../../components/thoughtLeadership/thoughtLeadershipResources';
import { ReportsActions } from './ReportsActions';
import { Reset } from '../../store/slices/userSlice';
import { connect } from 'react-redux';
import { reportsNotAllowed } from '../../helpers/constants';

const get = require('get-value');

const Reports = props => {
	const [permissionCode, setPermissionCode] = useState('');
	const [cmsReportPage, setCmsReportPage] = useState(null);
	const [cmsSubmittedRequestPageContent, setCmsSubmittedRequestPageContent] = useState({}); // eslint-disable-line
	const [pageLoader, setPageLoader] = useState(false);
	const shouldLog = useRef(true);
	let cmsReportsPagepayload = {
		categoryname: 'Default',
		pagename: 'ReportsAndResources',
		language: props.userSelectedlanguage.toLowerCase() || 'en'
	};

	useEffect(() => {
		if (shouldLog.current) {
			getReportsPageContent();
			props.reset();
			shouldLog.current = false;
		}
	}, []);

	useEffect(() => {
		document.title = 'Reports & Resources';
		const tokenData = JSON.parse(sessionStorage.getItem('setIdTokenClaims'));
		// console.log(tokenData.PermissionCode);
		setPermissionCode(tokenData.PermissionCode);
	}, []);

	useEffect(() => {
		if (props.isLangChanged) {
			getReportsPageContent();
			props.reset();
		}
	}, [props.isLangChanged, props.userSelectedlanguage]);

	const getReportsPageContent = async () => {
		try {
			setPageLoader(true);
			const res = await ReportsActions.GetCMSRequestPageDetails(cmsReportsPagepayload);
			if (res.status === 200) {
				setPageLoader(false);
				const data = res?.data?.Pages[0];
				setCmsReportPage(data);
			} else {
				return [];
			}
		} catch (error) {
			//console.log(error);
			return [];
		}
	};

	const thoughtLeadersipData = useMemo(
		() =>
			cmsReportPage
				? {
						...get(cmsReportPage, 'Components.ReportsAndResources.ThoughtLeadersip')
				  }
				: null,
		[cmsReportPage]
	);

	const programDescriptorData = useMemo(
		() =>
			cmsReportPage
				? {
						...get(cmsReportPage, 'Components.ClientResources.Component')
				  }
				: null,
		[cmsReportPage]
	);
	//console.log('programDescriptorData', programDescriptorData);
	return (
		<>
			{pageLoader && (
				<div className="overlay">
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			)}
			{!pageLoader && cmsReportPage && (
				<PageLayout containerClass="" bannerTitle={cmsReportPage.Title} data={cmsReportPage}>
					<div className="container-fluid-md container-lg p-xl-0">
						{Object.keys(programDescriptorData).length != 0 && <ProgramDescriptor pageData={programDescriptorData} />}
						{!reportsNotAllowed.includes(permissionCode) && (
							<ReportsAndResources pagination={true} submittedRequestData={cmsReportPage} />
						)}
						<ThoughtLeadershipResources pageData={thoughtLeadersipData} />
					</div>
				</PageLayout>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		switchLanguage: shortLang => dispatch(SwitchLanguage(shortLang)),
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
