import { useEffect } from 'react';
export default function docxViewer({ embedBlob }) {
	useEffect(() => {
		let contentTypeHeader = embedBlob.headers['content-type'];
		var blob = new Blob([embedBlob.data], {
			type: contentTypeHeader.toLowerCase(),
			charset: 'UTF-8'
		});
		let docx = require('docx-preview');
		docx.renderAsync(blob, document.getElementById('word-container')).then(x => {
			return x;
		});
	}, [embedBlob]);
	return <div id="word-container" className=""></div>;
}
