export const timeZones = [
	{ text: 'Select Time Zone', value: '' },
	{ text: '(UTC-12:00) International Date Line West', value: '(UTC-12:00) International Date Line West' },
	{ text: '(UTC-11:00) Coordinated Universal Time-11', value: '(UTC-11:00) Coordinated Universal Time-11' },
	{ text: '(UTC-10:00) Hawaii', value: '(UTC-10:00) Hawaii' },
	{ text: '(UTC-09:00) Alaska', value: '(UTC-09:00) Alaska' },
	{ text: '(UTC-08:00) Baja California', value: '(UTC-08:00) Baja California' },
	{ text: '(UTC-08:00) Pacific Time (US and Canada)', value: '(UTC-08:00) Pacific Time (US and Canada)' },
	{ text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan', value: '(UTC-07:00) Chihuahua, La Paz, Mazatlan' },
	{ text: '(UTC-07:00) Arizona', value: '(UTC-07:00) Arizona' },
	{ text: '(UTC-07:00) Mountain Time (US and Canada)', value: '(UTC-07:00) Mountain Time (US and Canada)' },
	{ text: '(UTC-06:00) Central America', value: '(UTC-06:00) Central America' },
	{ text: '(UTC-06:00) Central Time (US and Canada)', value: '(UTC-06:00) Central Time (US and Canada)' },
	{ text: '(UTC-06:00) Saskatchewan', value: '(UTC-06:00) Saskatchewan' },
	{ text: '(UTC-06:00) Guadalajara, Mexico City, Monterey', value: '(UTC-06:00) Guadalajara, Mexico City, Monterey' },
	{ text: '(UTC-05:00) Bogota, Lima, Quito', value: '(UTC-05:00) Bogota, Lima, Quito' },
	{ text: '(UTC-05:00) Indiana (East)', value: '(UTC-05:00) Indiana (East)' },
	{ text: '(UTC-05:00) Eastern Time (US and Canada)', value: '(UTC-05:00) Eastern Time (US and Canada)' },
	{ text: '(UTC-04:30) Caracas', value: '(UTC-04:30) Caracas' },
	{ text: '(UTC-04:00) Atlantic Time (Canada)', value: '(UTC-04:00) Atlantic Time (Canada)' },
	{ text: '(UTC-04:00) Asuncion', value: '(UTC-04:00) Asuncion' },
	{
		text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
		value: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'
	},
	{ text: '(UTC-04:00) Cuiaba', value: '(UTC-04:00) Cuiaba' },
	{ text: '(UTC-04:00) Santiago', value: '(UTC-04:00) Santiago' },
	{ text: '(UTC-03:30) Newfoundland', value: '(UTC-03:30) Newfoundland' },
	{ text: '(UTC-03:00) Brasilia', value: '(UTC-03:00) Brasilia' },
	{ text: '(UTC-03:00) Greenland', value: '(UTC-03:00) Greenland' },
	{ text: '(UTC-03:00) Cayenne, Fortaleza', value: '(UTC-03:00) Cayenne, Fortaleza' },
	{ text: '(UTC-03:00) Buenos Aires', value: '(UTC-03:00) Buenos Aires' },
	{ text: '(UTC-03:00) Montevideo', value: '(UTC-03:00) Montevideo' },
	{ text: '(UTC-02:00) Coordinated Universal Time-2', value: '(UTC-02:00) Coordinated Universal Time-2' },
	{ text: '(UTC-01:00) Cape Verde', value: '(UTC-01:00) Cape Verde' },
	{ text: '(UTC-01:00) Azores', value: '(UTC-01:00) Azores' },
	{ text: '(UTC+00:00) Casablanca', value: '(UTC+00:00) Casablanca' },
	{ text: '(UTC+00:00) Monrovia, Reykjavik', value: '(UTC+00:00) Monrovia, Reykjavik' },
	{ text: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London', value: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London' },
	{ text: '(UTC+00:00) Coordinated Universal Time', value: '(UTC+00:00) Coordinated Universal Time' },
	{
		text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
		value: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
	},
	{
		text: '(UTC +01:00) Brussels, Copenhagen, Madrid, Paris',
		value: '(UTC +01:00) Brussels, Copenhagen, Madrid, Paris'
	},
	{
		text: '(UTC +01:00) West Central Africa',
		value: '(UTC +01:00) West Central Africa'
	},
	{
		text: '(UTC +01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
		value: '(UTC +01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
	},
	{
		text: '(UTC +01:00) Sarajevo, Skopje, Warsaw, Zagreb',
		value: '(UTC +01:00) Sarajevo, Skopje, Warsaw, Zagreb'
	},
	{
		text: '(UTC +01:00) Windhoek',
		value: '(UTC +01:00) Windhoek'
	},
	{
		text: '(UTC +02:00) Athens, Bucharest, Istanbul',
		value: '(UTC +02:00) Athens, Bucharest, Istanbul'
	},
	{
		text: '(UTC +02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
		value: '(UTC +02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
	},
	{
		text: '(UTC +02:00) Cairo',
		value: '(UTC +02:00) Cairo'
	},
	{
		text: '(UTC +02:00) Damascus',
		value: '(UTC +02:00) Damascus'
	},
	{
		text: '(UTC +02:00) Amman',
		value: '(UTC +02:00) Amman'
	},
	{
		text: '(UTC +02:00) Harare, Pretoria',
		value: '(UTC +02:00) Harare, Pretoria'
	},
	{
		text: '(UTC +02:00) Jerusalem',
		value: '(UTC +02:00) Jerusalem'
	},
	{
		text: '(UTC +02:00) Beirut',
		value: '(UTC +02:00) Beirut'
	},
	{
		text: '(UTC +03:00) Baghdad',
		value: '(UTC +03:00) Baghdad'
	},
	{
		text: '(UTC +03:00) Minsk',
		value: '(UTC +03:00) Minsk'
	},
	{
		text: '(UTC +03:00) Kuwait, Riyadh',
		value: '(UTC +03:00) Kuwait, Riyadh'
	},
	{
		text: '(UTC +03:00) Nairobi',
		value: '(UTC +03:00) Nairobi'
	},
	{
		text: '(UTC +03: 30) Tehran',
		value: '(UTC +03: 30) Tehran'
	},
	{
		text: '(UTC +04:00) Moscow, St.Petersburg, Volgograd',
		value: '(UTC +04:00) Moscow, St.Petersburg, Volgograd'
	},
	{
		text: '(UTC +04:00) Tbilisi',
		value: '(UTC +04:00) Tbilisi'
	},
	{
		text: '(UTC +04:00) Yerevan',
		value: '(UTC +04:00) Yerevan'
	},
	{
		text: '(UTC +04:00) Abu Dhabi, Muscat',
		value: '(UTC +04:00) Abu Dhabi, Muscat'
	},
	{
		text: '(UTC +04:00) Baku',
		value: '(UTC +04:00) Baku'
	},
	{
		text: '(UTC +04:00) Port Louis',
		value: '(UTC +04:00) Port Louis'
	},
	{
		text: '(UTC +04: 30) Kabul',
		value: '(UTC +04: 30) Kabul'
	},
	{
		text: '(UTC +05:00) Tashkent',
		value: '(UTC +05:00) Tashkent'
	},
	{
		text: '(UTC +05:00) Islamabad, Karachi',
		value: '(UTC +05:00) Islamabad, Karachi'
	},
	{
		text: '(UTC +05: 30) Sri Jayewardenepura Kotte',
		value: '(UTC +05: 30) Sri Jayewardenepura Kotte'
	},
	{
		text: '(UTC +05: 30) Chennai, Kolkata, Mumbai, New Delhi',
		value: '(UTC +05: 30) Chennai, Kolkata, Mumbai, New Delhi'
	},
	{
		text: '(UTC +05: 45) Kathmandu',
		value: '(UTC +05: 45) Kathmandu'
	},
	{
		text: '(UTC +06:00) Astana',
		value: '(UTC +06:00) Astana'
	},
	{
		text: '(UTC +06:00) Dhaka',
		value: '(UTC +06:00) Dhaka'
	},
	{
		text: '(UTC +06:00) Yekaterinburg',
		value: '(UTC +06:00) Yekaterinburg'
	},
	{
		text: '(UTC +06: 30) Yangon',
		value: '(UTC +06: 30) Yangon'
	},
	{
		text: '(UTC +07:00) Bangkok, Hanoi, Jakarta',
		value: '(UTC +07:00) Bangkok, Hanoi, Jakarta'
	},
	{
		text: '(UTC +07:00) Novosibirsk',
		value: '(UTC +07:00) Novosibirsk'
	},
	{
		text: '(UTC +08:00) Krasnoyarsk',
		value: '(UTC +08:00) Krasnoyarsk'
	},
	{
		text: '(UTC +08:00) Ulaanbaatar',
		value: '(UTC +08:00) Ulaanbaatar'
	},
	{
		text: '(UTC +08:00) Beijing, Chongqing, Hong Kong, Urumqi',
		value: '(UTC +08:00) Beijing, Chongqing, Hong Kong, Urumqi'
	},
	{
		text: '(UTC +08:00) Perth',
		value: '(UTC +08:00) Perth'
	},
	{
		text: '(UTC +08:00) Kuala Lumpur, Singapore',
		value: '(UTC +08:00) Kuala Lumpur, Singapore'
	},
	{
		text: '(UTC +08:00) Taipei',
		value: '(UTC +08:00) Taipei'
	},
	{
		text: '(UTC +09:00) Irkutsk',
		value: '(UTC +09:00) Irkutsk'
	},
	{
		text: '(UTC +09:00) Seoul',
		value: '(UTC +09:00) Seoul'
	},
	{
		text: '(UTC +09:00) Osaka, Sapporo, Tokyo',
		value: '(UTC +09:00) Osaka, Sapporo, Tokyo'
	},
	{
		text: '(UTC +09: 30) Darwin',
		value: '(UTC +09: 30) Darwin'
	},
	{
		text: '(UTC +09: 30) Adelaide',
		value: '(UTC +09: 30) Adelaide'
	},
	{
		text: '(UTC + 10:00) Hobart',
		value: '(UTC + 10:00) Hobart'
	},
	{
		text: '(UTC + 10:00) Yakutsk',
		value: '(UTC + 10:00) Yakutsk'
	},
	{
		text: '(UTC + 10:00) Brisbane',
		value: '(UTC + 10:00) Brisbane'
	},
	{
		text: '(UTC + 10:00) Guam, Port Moresby',
		value: '(UTC + 10:00) Guam, Port Moresby'
	},
	{
		text: '(UTC + 10:00) Canberra, Melbourne, Sydney',
		value: '(UTC + 10:00) Canberra, Melbourne, Sydney'
	},
	{
		text: '(UTC + 11:00) Vladivostok',
		value: '(UTC + 11:00) Vladivostok'
	},
	{
		text: '(UTC + 11:00) Solomon Islands, New Caledonia',
		value: '(UTC + 11:00) Solomon Islands, New Caledonia'
	},
	{
		text: '(UTC + 12:00) Coordinated Universal Time + 12',
		value: '(UTC + 12:00) Coordinated Universal Time + 12'
	},
	{
		text: '(UTC + 12:00) Fiji, Marshall Islands',
		value: '(UTC + 12:00) Fiji, Marshall Islands'
	},
	{
		text: '(UTC + 12:00) Magadan',
		value: '(UTC + 12:00) Magadan'
	},
	{
		text: '(UTC + 12:00) Auckland, Wellington',
		value: '(UTC + 12:00) Auckland, Wellington'
	},
	{
		text: "(UTC+13:00) Nuku'alofa",
		value: "(UTC+13:00) Nuku'alofa"
	},
	{
		text: '(UTC + 13:00) Samoa',
		value: '(UTC + 13:00) Samoa'
	}
];
