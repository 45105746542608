import React, { useState, useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { connect } from 'react-redux';
import AccountIcon from '../../../assets/images/AccountIcon.png';
import NotificationIcon from '../../../assets/images/NotificationIcon.png';
import LogoutIcon from '../../../assets/images/icon/logout.png';
import { useNavigate } from 'react-router-dom';
import { pageUrls, pageNavigations, tempFullLanguage } from '../../../helpers/constants';
import { SwitchLanguage, GetHeader } from '../../../store/slices/userSlice';
import HttpHelper from '../../../helpers/HttpHelper';
import './AccountHeader.scss';
const get = require('get-value');

const AccountHeader = props => {
	const navigate = useNavigate();
	const { instance } = useMsal();
	const http = new HttpHelper();
	const shouldLog = useRef(true);
	const [languages, setLanguages] = useState('');
	const [cmsLanguages, setCmsLanguages] = useState('');
	const [profileLanguages, setProfileLanguages] = useState('');
	const [displayLang, setDisplayLang] = useState('');
	const [headerNav, setHeaderNav] = useState('');
	let UserId = window.sessionStorage.getItem('UserId');

	const getData = async () => {
		const { Components } = props.headerPageContent.Pages[0];
		const headerNavigation = get(Components, 'HeaderFooter.Header.Labels');
		const languages = get(Components, 'Header.Language.LabelWithTitle');
		const notificationLabels = [];
		headerNavigation.map(notificationItems => {
			if (pageNavigations.includes(notificationItems.Key))
				//notificationLabels.push(notificationItems);
				notificationLabels[notificationItems.Key] = notificationItems.Phrase;
		});
		// let sortOrder = pageNavigations;
		// notificationLabels.sort(function (a, b) {
		// 	return sortOrder.indexOf(a.Key) - sortOrder.indexOf(b.Key);
		// });
		setHeaderNav(notificationLabels);
		setCmsLanguages(languages);
		if (languages?.length > 0) {
			let filteredLang = languages.filter(lang => lang.Title === props.userSelectedlanguage.toUpperCase());
			filteredLang.length > 0 ? setDisplayLang(filteredLang[0]['Phrase']) : setDisplayLang(tempFullLanguage);
		}
	};

	const userLanguageHandler = (phrase, title) => {
		localStorage.removeItem('adminMessages');
		setDisplayLang(phrase);
		console.log('userselectedlanguage', title); // eslint-disable-line
		localStorage.setItem('userLanguage', title);
		props.switchLanguage(title.toLowerCase());
	};

	const getLanguages = async () => {
		await http
			.Get('User-Profile/client/' + UserId + '/language')
			.then(res => {
				setProfileLanguages(res.data);
			})
			.catch(error => {
				return error;
			});

		// setLanguages(data);
		// let filteredLang = data.filter(
		// 	lang => lang.LanguageCode.toLowerCase() === props.userSelectedlanguage.toLowerCase()
		// );
		// setDisplayLang(filteredLang[0].LanguageDescription);
	};

	const getDifference = (array1, array2) => {
		return array1.filter(object1 => {
			return array2.some(object2 => {
				return object1.Title.toLowerCase() === object2.LanguageCode.toLowerCase();
			});
		});
	};

	useEffect(() => {
		//console.log('profileLanguages', profileLanguages);
		//console.log('languages', languages);
		if (cmsLanguages.length > 0 && profileLanguages.length > 0) {
			//console.log('test2', getDifference(profileLanguages, languages));
			let filteredLang = getDifference(cmsLanguages, profileLanguages);
			//console.log('filteredLang', filteredLang);
			setLanguages(filteredLang);
			//console.log('filteredLang', filteredLang, 'props.userSelectedlanguage', props.userSelectedlanguage);
			let selectedLanguageData = filteredLang.filter(
				lang => lang.Title.toLowerCase() === props.userSelectedlanguage.toLowerCase()
			);
			setDisplayLang(selectedLanguageData[0].Phrase);
			let preferredLangData = profileLanguages.filter(lang => lang.LanguageID === sessionStorage.getItem('UserLang'));
			if (preferredLangData) {
				//console.log('seess', sessionStorage.getItem('UserLang'));
				//console.log('preferredLangData', preferredLangData);
				//props.switchLanguage(preferredLangData[0].LanguageCode.toLowerCase());
			}
		}
	}, [cmsLanguages, profileLanguages]);

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;
			props.getHeader(props?.userSelectedlanguage);
		} else if (props.headerPageContent) {
			getData();
			getLanguages();
		}
	}, [props.headerPageContent]);

	const logOut = () => {
		localStorage.removeItem('adminMessages');
		localStorage.removeItem('userLanguage');
		localStorage.clear();
		sessionStorage.clear();
		instance.logoutRedirect({
			postLogoutRedirectUri: '/'
		});
	};

	return (
		<>
			{headerNav && (
				<div className="container-fluid container-lg accountHeaderNav ps-sm-3 ps-md-2 ms-2 ms-sm-3 ms-md-4 m-lg-auto">
					<div className="row">
						<div className="d-none d-xl-block col-4"></div>
						<div className="d-none d-xl-block col-2"></div>
						<div className="col-12 col-xl-6 d-lg-flex justify-content-lg-end gap-4 p-0">
							<div className="d-lg-flex justify-content-lg-end mb-2 mb-lg-0">
								<button
									type="button"
									className="myAccountBtn"
									onClick={() => navigate(pageUrls[0]['Header.MyAccount'])}
									aria-label="My Account">
									<img src={AccountIcon} alt="Profile Icon" />
									{headerNav['Header.MyAccount']}
								</button>
							</div>
							<div className="d-lg-flex justify-content-lg-end mb-2 mb-lg-0">
								<button
									type="button"
									className="notificationBtn"
									onClick={() => props.notification()}
									aria-label="Notifications">
									{props?.unreadNotificationsCount > 0 && <span className="redDot"></span>}
									<img src={NotificationIcon} alt="Notification Icon" />
									{headerNav['Header.Notifications']}
								</button>
							</div>
							<div className="dropdown languageSwitch d-lg-flex mb-2 mb-lg-0 justify-content-lg-end">
								<button
									tabIndex={0}
									className="btn btn-secondary dropdown-toggle"
									type="button"
									id="dropdownMenuButton1"
									data-bs-toggle="dropdown"
									aria-expanded="false">
									{displayLang}
								</button>
								<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
									{/* {languages &&
										Object.values(languages).map((language, index) => (
											<li key={index}>
												<span
													onClick={() =>
														userLanguageHandler(language.LanguageDescription, language.LanguageCode.toLowerCase())
													}
													className="dropdown-item">
													{language.LanguageDescription}
												</span>
											</li>
										))} */}
									{languages &&
										Object.values(languages).map((language, index) => (
											<li key={index} role="listitem">
												<span
													tabIndex={0}
													onKeyDown={e => {
														if (e.key === 'Enter') userLanguageHandler(language.Phrase, language.Title.toLowerCase());
													}}
													onClick={() => userLanguageHandler(language.Phrase, language.Title.toLowerCase())}
													className="dropdown-item">
													{language.Phrase}
												</span>
											</li>
										))}
								</ul>
							</div>
							<div className="d-lg-flex justify-content-lg-end mb-2 mb-lg-0">
								<button
									type="button"
									aria-label={headerNav['Header.Logout']}
									className="headerLogOutBtn"
									onClick={() => logOut()}>
									<img src={LogoutIcon} alt="Logout Icon" />
									{headerNav['Header.Logout']}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		headerPageContent: state.userSlice.headerPageContent,
		unreadNotificationsCount: state.notificationSlice.unreadNotificationsCount
	};
};

const mapDispatchToProps = dispatch => {
	return {
		switchLanguage: shortLang => dispatch(SwitchLanguage(shortLang)),
		getHeader: shortLang => dispatch(GetHeader(shortLang))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHeader);
