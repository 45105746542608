//COMPONENTS
import { useState } from 'react';
import Header from '../Headers/Header/Header';
import AccountHeader from '../Headers/AcccountHeader/AccountHeader';
import Footer from '../footer/footer';
import Banner from '../Headers/Banner';

const PageLayout = ({ containerClass, children, bannerTitle, data, showFooter = true }) => {
	const bannerImageData = data
		? {
				smallImage: data.SmallImage,
				mediumImage: data.MediumImage,
				largeImage: data.LargeImage
		  }
		: null;
	const [showNotification, setShowNotification] = useState(false);
	const handleNotification = () => {
		setShowNotification(!showNotification);
	};

	return (
		<div className={containerClass}>
			<div className="container-fluid accountHeader d-none d-lg-block">
				<AccountHeader notification={() => handleNotification()} />
			</div>
			<div className="container-fluid container-lg p-lg-0">
				<Header showNotification={showNotification} closeNotification={() => handleNotification()} />
			</div>
			{bannerTitle && <Banner bannerTitle={bannerTitle} bannerImageData={bannerImageData} />}
			<main role="main">{children}</main>
			{showFooter && <Footer />}
		</div>
	);
};

export default PageLayout;
