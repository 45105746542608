import { thunk } from './middleware';
import { configureStore } from '@reduxjs/toolkit';
import UserSlice from './slices/userSlice';
import NotificationSlice from './slices/notification';

const reducer = {
	userSlice: UserSlice,
	notificationSlice: NotificationSlice
};

export function createStore(initialState) {
	const store = configureStore({
		reducer: reducer,
		middleware: [thunk],
		preloadedState: initialState
	});
	return store;
}
