import './button.scss';

const Button = props => {
	const { buttonClass, type, children, onClickHandler, icon, iconPosition, linkUrl, disableStatus, ...restProps } =
		props;
	const classes = `btn button rounded-pill ${buttonClass ? buttonClass : 'button--blue'} ${icon ? icon : ''} ${
		icon ? (iconPosition ? iconPosition : 'left') : ''
	}`;

	return (
		<>
			{linkUrl ? (
				<a href={linkUrl} className={classes} {...restProps}>
					{children}
				</a>
			) : (
				<button
					disabled={disableStatus}
					type={type ? type : 'button'}
					className={classes}
					onClick={onClickHandler}
					{...restProps}>
					{children}
				</button>
			)}
		</>
	);
};

export default Button;
