import { useState, useContext } from 'react';
import { AccoutTeamContext } from '../../../helpers/contexts';
import { meetingsAllowed } from '../../../helpers/constants';
import TeamMemberTile from '../../teamMemberTile';
import Modalpopup from '../../common/Modal/Modalpopup';
import defaultImg from '../../../assets/images/default-member-img.png';

import './style.scss';

const MemberBio = ({ memberData }) => {
	const { ProfilePhotoUrl, FirstName, MiddleName, LastName, EmailAddress, ContactNo, AccountUserBio } = memberData,
		name = `${FirstName}${MiddleName ? ` ${MiddleName}` : ''}${LastName ? ` ${LastName}` : ''}`,
		data = useContext(AccoutTeamContext),
		{ pageDataAccountTeam } = data;

	//const bioDescription = 'Something something\n\nSomething something';

	return (
		<div className="member-bio">
			<div className="row">
				<div className="col-12 col-md-5 mb-4 mb-md-0">
					<img className="member-bio__img" src={ProfilePhotoUrl ? ProfilePhotoUrl : defaultImg} alt={name} />
				</div>
				<div className="col-12 col-md-7">
					<div className="member-bio__name">{name}</div>
					{EmailAddress && (
						<>
							<div className="member-bio__email-title">{pageDataAccountTeam?.Labels?.[2]?.Phrase}</div>
							<div className="member-bio__email">{EmailAddress}</div>
						</>
					)}
					{ContactNo && (
						<>
							<div className="member-bio__phone-title">{pageDataAccountTeam?.Labels?.[6]?.Phrase}</div>
							<div className="member-bio__phone">{ContactNo}</div>
						</>
					)}
				</div>
			</div>
			<hr />
			<div className="member-bio__bio-title">{pageDataAccountTeam?.Labels?.[0]?.Phrase}</div>
			{AccountUserBio != '' && AccountUserBio != undefined && (
				<div
					className="member-bio__bio"
					dangerouslySetInnerHTML={{ __html: AccountUserBio.replace(/\n/g, '<br />') }}
				/>
			)}
			{/* {AccountUserBio} */}
			{/* </div> */}
		</div>
	);
};

const AccountTeamListing = ({ accountTeamData, permissionCode }) => {
	const [displayBio, setDisplayBio] = useState(false),
		[memberData, setMemberData] = useState(''),
		data = useContext(AccoutTeamContext),
		{ pageDataAccountTeam } = data;

	const showBio = memberInfo => {
		setMemberData(memberInfo);
		setDisplayBio(!displayBio);
	};

	const className = `account-team-listing ${meetingsAllowed.includes(permissionCode) ? 'vs-comp' : ''}`;
	return (
		<div className={className}>
			<h2 className="col-12 col-md-6">{pageDataAccountTeam?.Labels?.[1]?.Phrase}</h2>
			<hr className="d-none d-md-block" />

			{accountTeamData && (
				<div className="row gy-md-3">
					{accountTeamData.map((memberInfo, index) => {
						const { ClientId, ProfilePhotoUrl, FirstName, MiddleName, LastName, JobPosition } = memberInfo,
							name = `${FirstName}${MiddleName ? ` ${MiddleName}` : ''}${LastName ? ` ${LastName}` : ''}`;

						return (
							<div className="col-12 col-md-2 p-sm-3 p-0" key={`${ClientId}${index}`}>
								<hr className="d-md-none" />
								<TeamMemberTile
									image={ProfilePhotoUrl}
									name={name}
									position={JobPosition}
									onClickHandler={() => {
										showBio(memberInfo);
									}}
								/>
							</div>
						);
					})}
				</div>
			)}

			<Modalpopup
				show={displayBio}
				modalSize={'lg'}
				onClose={() => setDisplayBio(!displayBio)}
				closeButtonVariant={true}>
				<MemberBio memberData={memberData} />
			</Modalpopup>
		</div>
	);
};

export default AccountTeamListing;
