import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReferralsActions } from '../../referrals/ReferralsActions';
import { customDateConverter } from '../../../helpers/constants';
import Button from '../../common/button';

import './yourReferrals.scss';
const get = require('get-value');

const YourReferrals = ({ data, refCmsData }) => {
	const navigate = useNavigate(),
		shouldLog = useRef(true),
		[referralsList, setReferralsList] = useState([]);
	const labels = get(data, 'Components.LandingPage.Home.Labels');

	const getRequestList = async () => {
		let payload = {};
		payload.PageNumber = 1;
		payload.PageSize = 2;
		const res = await ReferralsActions.GetRequestList(payload);
		if (res.status === 200 && res?.data?.referralUploads?.length > 0) {
			setReferralsList(res?.data?.referralUploads);
		}
	};

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;
			getRequestList();
		}
	}, []);

	return (
		<div className="your-referrals">
			<p className="your-referrals__refTitle">{labels?.[3]?.Phrase}</p>
			{referralsList.length > 0 ? (
				referralsList.map((items, index) => (
					<div className="refListSection" key={index}>
						<span className="your-referrals__refName">{items.DocumentName}</span>
						<span className="your-referrals__refDate">Submitted: {customDateConverter(items.CreatedTimeStamp)}</span>
						{items.DocumentStatus.toLowerCase() === 'approved' && (
							<span className="your-referrals__status approved">{refCmsData['Status.Approved']}</span>
						)}
						{items.DocumentStatus.toLowerCase() === 'pending' && (
							<span className="your-referrals__status pending">{refCmsData['Status.Pending']}</span>
						)}
						{items.DocumentStatus.toLowerCase() === 'rejected' && (
							<span className="your-referrals__status rejected">{refCmsData['Status.Rejected']}</span>
						)}
						{items.DocumentStatus.toLowerCase() === 'in progress' && (
							<span className="your-referrals__status inprogress">{refCmsData['Status.InProgress']}</span>
						)}
						{/* <hr className="horizontal-rule"></hr> */}
					</div>
				))
			) : (
				<div className="noRecords">No Records</div>
			)}
			{referralsList.length > 0 && (
				<Button
					buttonClass="button--white-small"
					data-testid="cancelReferralBtn"
					name="cancelReferral"
					id="cancelReferralButton"
					onClickHandler={() => navigate('/referrals')}>
					{labels?.[4]?.Phrase}
				</Button>
			)}
		</div>
	);
};

export default YourReferrals;
