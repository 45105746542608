import React from 'react';
import { useEffect, useState } from 'react';
import htmlParser from 'html-react-parser';
import { FooterActions } from '../FooterActions';
import Header from '../header/Header';
import Footer from '../footer';
import Loader from '../../common/Loader';
import './policyCookie.scss';

const PolicyCookie = () => {
	const [data, setData] = useState();
	const [screenLoader, setScreenLoader] = useState(true);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const languagecode = params.get('languagecode');

	useEffect(() => {
		//console.log('languagecode', languagecode);
		document.title = 'Cookie Notice';
		//const userLangAndCountry = JSON.parse(window.sessionStorage.getItem('Language'));
		window.scrollTo(0, 0);
		getPolicyData();
		window.OneTrust?.initializeCookiePolicyHtml();
		//window.OneTrust?.changeLanguage(userLangAndCountry?.selectedLanguageISOCode);
	}, []);

	let auth = {
		token: sessionStorage.getItem('setIdTokenClaims') ? true : false
	};

	const getPolicyData = async () => {
		await FooterActions.GetCookieNPolicies({
			categoryname: 'Default',
			pagename: 'CookiePolicy',
			language: localStorage.getItem('userLanguage') || languagecode || 'en'
		}).then(
			res => {
				// console.log(res.data.Pages[0]);
				if (res.status == 200) {
					setData(res?.data && res?.data?.Pages[0]);
					setScreenLoader(false);
				} else {
					setScreenLoader(false);
				}
			} //,
			// err => {
			// 	console.log(err);
			// 	setScreenLoader(false);
			// }
		);
	};

	const replaceCookieBtn = () => {
		let textArr = document.querySelectorAll('span');
		let btn = document.getElementById('ot-sdk-btn');
		textArr.forEach(txt => {
			if (txt?.firstChild?.textContent == '#ONETRUST_BUTTON#') {
				txt.parentNode.replaceChild(btn, txt);
			}
		});
	};

	const replaceCookieTable = () => {
		let textArr = document.querySelectorAll('p');
		let tabl = document.getElementById('ot-sdk-cookie-policy');
		textArr.forEach(txt => {
			if (txt?.firstChild?.textContent == '#ONETRUST_TABLE#') {
				txt.parentNode.replaceChild(tabl, txt);
			}
		});
	};

	useEffect(() => {
		replaceCookieTable();
		replaceCookieBtn();
	});

	return (
		<>
			<div className="cookie-policy">
				{screenLoader === true ? (
					<Loader />
				) : (
					<>
						<Header />
						<div className="cookie-content">
							<h3>{data && data?.Title}</h3>
							{htmlParser(data ? data?.Description : '')}
						</div>
					</>
				)}
				{auth.token ? <Footer isLoggedIn={true} /> : <Footer isLoggedIn={false} />}
			</div>
			<div style={{ display: 'none' }}>
				<div id="ot-sdk-cookie-policy"></div>
				<button id="ot-sdk-btn" className="ot-sdk-show-settings" onClick={() => window.OneTrust.ToggleInfoDisplay()}>
					Cookie Settings
				</button>
			</div>
		</>
	);
};

export default PolicyCookie;
