//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------
import React from 'react';
import { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReferralsActions } from '../../components/referrals/ReferralsActions';
import { Reset } from '../../store/slices/userSlice';
//import TwoColumnTemplateOnboard from '../../components/pageLayout/twoColumnTemplateOnboard';
import ReferralSuccessPage from '../../components/referralSuccess/ReferralSuccess';
import LoginHeader from '../../components/Headers/LoginHeader';
import Banner from '../../components/Headers/Banner';
import OnBoardBG from '../../assets/images/OnBoarding/onboardBg.png';
import './style.scss';
const ReferralSuccess = props => {
	const [cmsCreateReferralPage, setCmsCreateReferralPage] = useState({});
	const [pageLoader, setPageLoader] = useState(false);
	const get = require('get-value');

	//const navigate = useNavigate();
	useEffect(() => {
		getReferralsPageContent();
	}, []);

	const getReferralsPageContent = async () => {
		try {
			setPageLoader(true);
			let CmsCreateReferralPage = JSON.parse(JSON.stringify(cmsCreateReferralPage));
			let cmsReferralsPagepayload = {
				categoryname: 'Default',
				pagename: 'Referrals',
				language: props.userSelectedlanguage.toLowerCase() || 'en'
			};
			const res = await ReferralsActions.GetCMSRequestPageDetails(cmsReferralsPagepayload);
			if (res.status === 200) {
				//if (res) {
				setPageLoader(false);
				res?.data?.Pages?.map(x => {
					//res?.Pages?.map(x => {
					if (x.Key == 'CreateReferrals') {
						CmsCreateReferralPage['Title'] = x.Title;
						x.Components?.['Referrals.CreateReferral'].LabelWithTitle.map(y => {
							CmsCreateReferralPage[y.Key] = y;
						});
						CmsCreateReferralPage['createNewBtn'] = x.PageUrl;
						//const btnlabels = [];
						get(x.Components['Common.Component'], 'Labels').forEach(element => {
							CmsCreateReferralPage[element.Key] = element.Phrase;
						});
						//CmsCreateReferralPage['btnLabels'] = btnlabels;
						//console.log('Components', CmsCreateReferralPage);
					}
				});
				setCmsCreateReferralPage(CmsCreateReferralPage);
			} else {
				return [];
			}
		} catch (error) {
			return [];
		}
	};
	return (
		<>
			{/* <TwoColumnTemplateOnboard>
				<ReferralSuccessPage />
			</TwoColumnTemplateOnboard> */}
			{pageLoader && (
				<div className="overlay">
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			)}
			{cmsCreateReferralPage && (
				<div className="container-fluid referral-success">
					<div className="row">
						<div className="col-md-12 col-lg-5 col-xl-6 p-0 p-md-0 p-lg-2">
							<LoginHeader backButton={cmsCreateReferralPage['Common.ButtonText.Back']} />
							<div className="d-lg-none">
								<Banner
									bannerTitle={
										cmsCreateReferralPage['Referrals.CreateReferral.ReferralSuccessfullSubmissionText']?.Title
									}
								/>
							</div>
							<div className="d-none d-md-block mt-lg-5 mt-xl-0 mt-xxl-0 p-md-5 p-lg-4 p-xl-5">
								{!pageLoader && <ReferralSuccessPage cmsCreateReferralPage={cmsCreateReferralPage} />}
							</div>
						</div>
						<div className="col-md-5 col-lg-7 col-xl-6 pe-0 d-none d-lg-block">
							<img className="img-fluid imt float-end" src={OnBoardBG} alt="OnBoard BG" />
						</div>
						<div className="d-md-none mt-5">
							{!pageLoader && <ReferralSuccessPage cmsCreateReferralPage={cmsCreateReferralPage} />}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralSuccess);
