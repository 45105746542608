import { createSlice } from '@reduxjs/toolkit';
import HttpHelper from '../../helpers/HttpHelper';

const http = new HttpHelper();

const initialState = {
	userSelectedlanguage: localStorage.getItem('userLanguage'),
	headerPageContent: undefined,
	isLoading: false,
	isRefreshToken: false,
	error: undefined,
	isLangChanged: false,
	meetingsData: null
};

//reducers
const userSlice = createSlice({
	name: 'userSlice',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		languageSwitch: (state, action) => {
			state.userSelectedlanguage = action.payload;
			state.isLoading = false;
			state.error = undefined;
		},
		headerCmsContent: (state, action) => {
			state.headerPageContent = action.payload;
			state.isLoading = false;
			state.error = undefined;
		},
		meetingListData: (state, action) => {
			state.meetingsData = action.payload;
			state.isLoading = false;
			state.error = undefined;
		},
		reset: (state, action) => {
			state.isLangChanged = action.payload;
		}
	}
});

const { reducer, actions } = userSlice;

const { setLoading, languageSwitch, headerCmsContent, meetingListData, reset } = actions;
export default reducer;

//actions
export const GetHeader = lang => async dispatch => {
	dispatch(setLoading(true));
	try {
		const payload = {
			categoryname: 'Default',
			pagename: 'HeaderFooter',
			language: lang.toLowerCase()
		};
		let { data } = await http.CMSpost('PageApi/GetPageDetails', payload);
		dispatch(languageSwitch(lang));
		dispatch(headerCmsContent(data));
		dispatch(reset(false));
	} catch (error) {
		dispatch(languageSwitch(error));
	}
};
export const SwitchLanguage = lang => async dispatch => {
	console.log('IN SLICE', lang); // eslint-disable-line
	dispatch(setLoading(true));
	try {
		const payload = {
			categoryname: 'Default',
			pagename: 'HeaderFooter',
			language: lang.toLowerCase()
		};
		let { data } = await http.CMSpost('PageApi/GetPageDetails', payload);
		dispatch(languageSwitch(lang));
		dispatch(headerCmsContent(data));
		dispatch(reset(true));
	} catch (error) {
		dispatch(languageSwitch(error));
	}
};

export const Reset = () => dispatch => {
	dispatch(reset(false));
};

export const MeetingList = () => async dispatch => {
	dispatch(setLoading(true));
	try {
		let userId = window.sessionStorage.getItem('UserId');
		const { data } = await http.Get('User-Profile/mymeeting/' + userId);
		dispatch(meetingListData(data));
	} catch (error) {
		dispatch(meetingListData(error));
	}
};
