import { useEffect, useState, useRef, useContext } from 'react';
import { AccoutTeamContext } from '../../../helpers/contexts';
import HttpHelper from '../../../helpers/HttpHelper';
import Button from '../../common/button';
import InputBox from '../../common/inputBox';
import SelectBox from '../../common/selectBox';
import TextArea from '../../common/textArea';
import DatePickerInput from '../../common/datePickerInput';
import Loader from '../../common/Loader';
//import { BRAND_ID } from '../../../helpers/constants';
import environments from '../../common/environments/environments'; // eslint-disable-line
import { timeZones } from '../../../data/timeZones';
import { MeetingList } from '../../../store/slices/userSlice';
import { connect } from 'react-redux';

//const ScheduleMeetingForm = ({ cancelHandler, toggleScheduleMeetingForm, toggleConfirmation, setPayload }) => {
const ScheduleMeetingForm = props => {
	const inputRef = useRef(null);
	const [data, setData] = useState(null);
	const http = new HttpHelper();

	const { pageDataMeeting, common } = useContext(AccoutTeamContext),
		fieldData = pageDataMeeting?.LOVList,
		requestFormData = pageDataMeeting?.Labels,
		requestFormLabel = {},
		commonLabel = {},
		locationOptions = [],
		meetingLengthOptions = [],
		availableTimeOptions = [];
	requestFormData.forEach(element => {
		requestFormLabel[element.Key] = element.Phrase;
	});
	common?.Labels?.forEach(element => {
		commonLabel[element.Key] = element.Phrase;
	});

	fieldData.forEach(element => {
		if (element.Key === 'ClientTeam.Meeting.Locations') {
			element.Values.forEach(ele => {
				locationOptions.push({
					text: ele.Value,
					value: ele.Value
				});
			});
		}

		if (element.Key === 'ClientTeam.Meeting.MeetingDurations') {
			element.Values.forEach(ele => {
				meetingLengthOptions.push({
					text: ele.Value,
					value: ele.Value
				});
			});
		}

		if (element.Key === 'ClientTeam.Meeting.TimeSlots') {
			element.Values.forEach(ele => {
				availableTimeOptions.push({
					text: ele.Value,
					value: ele.Value
				});
			});
		}
	});

	const getData = async () => {
		//let { data } = await http.Get('User-Profile/allaccountUsers');
		let userId = window.sessionStorage.getItem('UserId');
		const userSelectedlanguage = props.userSelectedlanguage || 'en';
		let { data } = await http.Get('User-Profile/account/' + userId + '/' + userSelectedlanguage + '/team');

		if (data) {
			const requestorData = data.map(ele => {
				const { FirstName, MiddleName, LastName, Id } = ele;

				return {
					text: `${FirstName}${MiddleName ? ` ${MiddleName}` : ''}${LastName ? ` ${LastName}` : ''}`,
					value: Id
				};
			});
			setData(requestorData);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const handleSubmit = e => {
		e.preventDefault();

		if (e.target.checkValidity()) {
			const formData = new FormData(e.target);
			let userId = window.sessionStorage.getItem('UserId');
			let userEmail = window.sessionStorage.getItem('UserEmail');
			const payload = {
				BrandID: environments.BRAND_ID,
				RequestorId: userId,
				RequestorEmailAddress: userEmail
			};

			for (const pair of formData.entries()) {
				payload[pair[0]] = pair[1];
			}

			makeMeetingRequest(payload);
		} else {
			e.target.classList.add('was-validated');
			e.target.querySelectorAll(`:invalid`)[0].focus();
		}
	};

	const makeMeetingRequest = async payload => {
		const { data } = await http.post('User-Profile/client/requestmeeting', payload, 'application/json');

		if (data) {
			props.toggleScheduleMeetingForm();
			props.toggleConfirmation();
			props.setPayload(payload);
			props.meetingList();
		}
	};

	return (
		<>
			{data ? (
				<div className="meeting-form">
					<form onSubmit={handleSubmit} className="needs-validation" id="meetingForm" noValidate>
						<h2>{pageDataMeeting?.Title}</h2>
						<InputBox
							labelText={requestFormLabel['ClientTeam.Meeting.Title']}
							placeholderText={requestFormLabel['ClientTeam.Meeting.Title']}
							name="Title"
							errorMessage="Please enter title"
							required
							ref={inputRef}
						/>
						<SelectBox
							labelText={requestFormLabel['ClientTeam.MeetingRequestedWith']}
							options={data}
							name="MeetingRequestee"
						/>
						<SelectBox
							labelText={requestFormLabel['ClientTeam.Meeting.Length']}
							options={meetingLengthOptions}
							name="Duration"
						/>
						<SelectBox
							labelText={requestFormLabel['ClientTeam.Meeting.Timezone']}
							options={timeZones}
							name="TimeZone"
							required
						/>
						<SelectBox
							labelText={requestFormLabel['ClienTeam.Meeting.Location']}
							options={locationOptions}
							name="Location"
						/>
						<DatePickerInput labelText={requestFormLabel['ClientTeam.Meeting.Date']} name="MeetingDate" />
						<SelectBox
							labelText={requestFormLabel['ClientTeam.Meeting.AvailableTimes']}
							options={availableTimeOptions}
							name="PreferredTimeSlot"
						/>
						<small className="form-text text-muted">{requestFormLabel['ClientTeam.Meeting.Timedescription']}</small>
						<TextArea labelText={requestFormLabel['ClientTeam.Meeting.Notes']} name="Notes" />
						<div className="meeting-form__buttons">
							<Button buttonClass="button--blue me-md-3" type="submit">
								{requestFormLabel['ClientTeam.Meeting.RequestMeeting']}
							</Button>
							<Button buttonClass="button--white mt-3 mt-md-0" onClickHandler={props.cancelHandler}>
								{commonLabel['Common.ButtonText.Cancel']}
							</Button>
						</div>
					</form>
				</div>
			) : (
				<Loader />
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		meetingsData: state.userSlice.meetingsData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		meetingList: () => dispatch(MeetingList())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleMeetingForm);
