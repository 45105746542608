import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
//import * as XLSX from 'xlsx';
import AfterOnBoardBg from '../../assets/images/AfterOnBoarding/AfterOnBoardBg.png';
//import MobileReferral from '../../assets/images/MobileReferral.png';
import Banner from '../Headers/Banner';
import CloseIcon from '../../assets/images/CloseIcon.png';
import RemoveIcon from '../../assets/images/icon/remove.png';
import DragDropFile from '../common/DragandDrop/DragDropFile';
import ErrorBox from '../common/ErrorBox/ErrorBox';
import Modalpopup from '../common/Modal/Modalpopup';
import { CreateReferralsActions } from './createReferralsActions';
import environments from '../common/environments/environments'; // eslint-disable-line
import TrackActivity from '../../helpers/TrackActivity';
import httpHelper from '../../helpers/HttpHelper';
import Loader from '../common/Loader';

import './createReferral.scss';

const CreateReferral = props => {
	const navigate = useNavigate();
	const http = new httpHelper(); // eslint-disable-line
	const tracker = new TrackActivity();
	const [attachedFile, setAttachedFile] = useState('');
	const [resetAttachment, setResetAttachment] = useState(false);
	const [submitBtnActive, setSubmitBtnActive] = useState('disabled');
	const [showError, setShowError] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [loader, setLoader] = useState(false);
	const [checked, setChecked] = useState(false);
	const [cmsCreateReferralPage, setCmsCreateReferralPage] = useState({});
	/* eslint-disable */
	const [definedHeader, setDefinedHeader] = useState([
		'First Name',
		'Last Name',
		'Notification Date',
		'Release/Exit Date',
		'Candidate Eligible Immediately?',
		'Candidate Eligible Date\r\nN/A if not Eligible',
		'Program',
		'Employee ID (if any)',
		'Contact Number (work)',
		'Contact Number (personal)',
		'Email address (work)',
		'Email address (personal)',
		'Position/Title',
		'Gender: (male/female/other/unknown)',
		'Country of Residence',
		'State/Province or Nearest City of Residence'
	]);
	/* eslint-enable */
	const [modalType, setModalType] = useState('');
	const [modalTitle, setModalTitle] = useState('');
	const [modalBody, setModalBody] = useState('');
	const [errorContent, setErrorContent] = useState('');
	let userId = window.sessionStorage.getItem('UserId');

	const confirmReferral = async modalType => {
		if (modalType == 'SUBMIT') {
			setModalShow(false);
			setLoader(true);
			const data = new FormData();
			data.append('FileNames', attachedFile);
			data.append('UserId', userId);
			data.append('UserType', 'Client');
			data.append('IsUSResident', 0);
			data.append('BrandId', environments.BRAND_ID);
			if (checked) {
				data.append('IsReferralTemplate', 0);
			} else {
				data.append('IsReferralTemplate', 1);
			}
			const res = await CreateReferralsActions.CreateReferral(data);
			if (res.status == 200 && res.data[0].IsSuccess) {
				setLoader(false);
				tracker.sendActivity('ReferralCreate', userId, '', attachedFile.name);
				navigate('/referral-success');
			}
		} else {
			navigate('/referrals');
		}
	};

	const submitReferral = () => {
		setModalType('SUBMIT');
		setModalTitle(cmsCreateReferralPage['Referrals.CreateReferral.SubmitReferralPopUp']?.Title);
		setModalBody(cmsCreateReferralPage['Referrals.CreateReferral.SubmitReferralPopUp']?.Phrase);
		setModalShow(true);
	};

	const cancelReferral = () => {
		setModalType('CANCEL');
		setModalTitle(cmsCreateReferralPage['Referrals.CreateReferral.CanceReferralPopUp']?.Title);
		setModalBody(cmsCreateReferralPage['Referrals.CreateReferral.CanceReferralPopUp']?.Phrase);
		setModalShow(true);
	};

	const handleAttachment = data => {
		if (data != '') {
			checkDuplicateFileName(data);
		} else {
			setResetAttachment(false);
			setAttachedFile(data);
		}
	};

	const errorInFile = data => {
		if (data != '') {
			let errorContent = {
				Title: cmsCreateReferralPage['Referrals.CreateReferral.FileFormatErrorMsg']?.Title,
				Phrase: cmsCreateReferralPage['Referrals.CreateReferral.FileFormatErrorMsg']?.Phrase
			};
			setErrorContent(errorContent);
			setShowError(true);
		} else {
			setShowError(false);
			setErrorContent('');
		}
	};

	useEffect(() => {
		if (attachedFile?.name && attachedFile?.name != undefined) {
			//checkDuplicateFileName(attachedFile);
			//handleExcelFile(attachedFile);
			if (isFormValid()) {
				setSubmitBtnActive('');
			}
		} else {
			isFormValid();
			setSubmitBtnActive('disabled');
		}
	}, [attachedFile]);

	const isFormValid = () => {
		//const { usResident, nonUsResident } = userInputs;
		let fileAvailable = false;
		if (attachedFile) {
			fileAvailable = true;
		}
		//return usResident && nonUsResident && fileAvailable;
		return fileAvailable;
	};

	const checkDuplicateFileName = async attachedFileData => {
		//console.log('attachedFileName', attachedFileData);
		setLoader(true);
		const data = new FormData();
		let userId = window.sessionStorage.getItem('UserId');
		data.append('FileNames', attachedFileData);
		data.append('UserId', userId);
		data.append('BrandId', environments.BRAND_ID);
		let errorContent = {
			Title: cmsCreateReferralPage['Referrals.CreateReferral.DuplicateFileNameErrorMsg']?.Title,
			Phrase: cmsCreateReferralPage['Referrals.CreateReferral.DuplicateFileNameErrorMsg']?.Phrase
		};

		await http
			.post(environments.CREATE_REFERRAL_DUPLICATE_FILE_API, data, 'multipart/form-data')
			.then(res => {
				//console.log('reser', res);
				setLoader(false);
				if (res.data) {
					setShowError(true);
					setErrorContent(errorContent);
					setSubmitBtnActive('disabled');
					setResetAttachment(true);
				} else {
					setShowError(false);
					setErrorContent('');
					setSubmitBtnActive('');
					setAttachedFile(attachedFileData);
				}
			})
			.catch(error => {
				return error;
				//console.log(error);
			});
	};

	// const handleExcelFile = files => {
	// 	let f = files;
	// 	const reader = new FileReader();
	// 	reader.onload = files => {
	// 		const bstr = files.target.result;
	// 		const wb = XLSX.read(bstr, { type: 'binary' });
	// 		const wsname = wb.SheetNames[0];
	// 		const ws = wb.Sheets[wsname];
	// 		const dataExceljson = XLSX.utils.sheet_to_json(ws);
	// 		if (dataExceljson.length >= 8) {
	// 			let firstRow = dataExceljson[7];
	// 			let isHeader = 0;
	// 			Object.values(firstRow).map(headers => {
	// 				if (isNaN(headers)) {
	// 					if (!definedHeader.includes(headers.trim())) {
	// 						isHeader = isHeader + 1;
	// 						//console.log();
	// 					}
	// 				}
	// 			});
	// 			//console.log('isHeader', isHeader);
	// 			if (isHeader == 0) {
	// 				setShowError(false);
	// 				setSubmitBtnActive('');
	// 			} else {
	// 				if (checked) {
	// 					setShowError(false);
	// 					setSubmitBtnActive('');
	// 				} else {
	// 					setShowError(true);
	// 					setSubmitBtnActive('disabled');
	// 					let errorContent = {
	// 						Title: cmsCreateReferralPage['Referrals.CreateReferral.IncorrectDataErrorMsg']?.Title,
	// 						Phrase: cmsCreateReferralPage['Referrals.CreateReferral.IncorrectDataErrorMsg']?.Phrase
	// 					};
	// 					setErrorContent(errorContent);
	// 					setResetAttachment(true);
	// 				}
	// 			}
	// 		} else {
	// 			setShowError(true);
	// 			setSubmitBtnActive('disabled');
	// 			let errorContent = {
	// 				Title: cmsCreateReferralPage['Referrals.CandidateReferrals.FileDataEmptyErrorMsg']?.Title,
	// 				Phrase: cmsCreateReferralPage['Referrals.CandidateReferrals.FileDataEmptyErrorMsg']?.Phrase
	// 			};
	// 			setErrorContent(errorContent);
	// 			setResetAttachment(true);
	// 		}
	// 	};
	// 	reader.readAsBinaryString(f);
	// };

	useEffect(() => {
		getReferralsPageContent();
	}, []);

	const getReferralsPageContent = async () => {
		try {
			setLoader(true);
			let CmsCreateReferralPage = JSON.parse(JSON.stringify(cmsCreateReferralPage));
			let cmsCreateReferralPagepayload = {
				categoryname: 'Default',
				pagename: 'Referrals',
				language: props.userSelectedlanguage
			};
			const res = await CreateReferralsActions.GetCMSRequestPageDetails(cmsCreateReferralPagepayload);

			if (res.status === 200) {
				setLoader(false);
				res?.data?.Pages?.map(x => {
					if (x.Key == 'CreateReferrals') {
						CmsCreateReferralPage['title'] = x.Title;
						CmsCreateReferralPage['description'] = x.Description;
						CmsCreateReferralPage['draganddroptitle'] = x.Components?.['Referrals.CreateReferral']?.Title;
						CmsCreateReferralPage['pleasemakesure'] = x.Components?.['Referrals.CreateReferral']?.Description;
						CmsCreateReferralPage['testtitle'] = 'TestTitle';

						x.Components?.['Referrals.CreateReferral']?.Labels.map(x => {
							CmsCreateReferralPage[x.Key] = x.Phrase;
						});

						x.Components?.['Referrals.CreateReferral'].LinkList.map(y => {
							CmsCreateReferralPage[y.Key] = y.Link;
						});
						x.Components?.['Referrals.CreateReferral'].LabelWithTitle.map(y => {
							CmsCreateReferralPage[y.Key] = y;
						});

						x.Components?.['Common.Component']?.Labels.map(x => {
							CmsCreateReferralPage[x.Key] = x.Phrase;
						});

						let templates = {};
						x.Components?.['Referrals.CreateReferral']?.OtherMediaFiles.map(y => {
							if (y.IsVisible) {
								templates[y.Key] = y;
							}
						});
						CmsCreateReferralPage['templates'] = templates;
					}
				});

				setCmsCreateReferralPage(CmsCreateReferralPage);
			}
		} catch (err) {
			return err;
		}
	};
	const downloadTemplate = (templatePath, templateName) => {
		setLoader(true);
		http
			.templateDownloadGet(templatePath)
			.then(res => {
				setLoader(false);
				let contentTypeHeader = res.headers['content-type'];
				var blob = new Blob([res.data], {
					type: contentTypeHeader.toLowerCase(),
					charset: 'UTF-8'
				});
				let fileName = templateName.replace(' ', '_');
				saveAs(blob, `${fileName}.xlsx`);
			})
			.catch(error => {
				setLoader(false);
				return error;
			});
	};
	const downloadAllTemplate = cmsCreateReferralPage => {
		setLoader(true);
		// Object.keys(cmsCreateReferralPage.templates).map(key => {
		// 	downloadTemplate(cmsCreateReferralPage.templates[key].OtherMediaUrl, cmsCreateReferralPage.templates[key].Phrase);
		// });
		downloadTemplate(
			cmsCreateReferralPage.templates['Referrals.CreateReferrals.GlobalTempalte'].OtherMediaUrl,
			cmsCreateReferralPage.templates['Referrals.CreateReferrals.GlobalTempalte'].Phrase
		);
	};
	const handleChange = () => {
		setChecked(!checked);
	};
	return (
		<>
			{loader && <Loader />}
			{cmsCreateReferralPage && (
				<main className="row create-referral">
					<div className="create-referral__left col-xs-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-centered p-0">
						<img className="d-none d-lg-block img-fluid leftimg" src={AfterOnBoardBg} alt="After Onboard Bg" />
						{/* <img className="d-lg-none img-fluid" src={MobileReferral} /> */}
						<div className="d-lg-none">
							{cmsCreateReferralPage?.title && <Banner bannerTitle={cmsCreateReferralPage?.title} />}
						</div>
						<div className="createReferralContent">
							<div className="d-none d-lg-block col-4 mx-auto col-md-12 mt-4 ">
								<button
									type="button"
									data-testid="cancelReferralBtn"
									name="cancelReferral"
									id="cancelReferralButton"
									className="btn btn-primary create-referral__cancel-btn rounded-pill"
									onClick={() => cancelReferral()}>
									<img className="img-fluid closeIcon" src={CloseIcon} alt="Close Icon" />
									{cmsCreateReferralPage['Referrals.CreateReferral.CancelReferral']}
								</button>
							</div>
							<div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-0 mt-2 p-4 p-lg-0">
								<p className="pageTitle d-none d-lg-block">{cmsCreateReferralPage?.title}</p>
								<p className="pageDescription">{cmsCreateReferralPage?.description}</p>
								<p className="pageDownloadContent m-0">
									{cmsCreateReferralPage?.pleasemakesure}&nbsp;
									<span
										tabIndex={0}
										className="downloadLink"
										id="downloadTemplateLink"
										onClick={() => downloadAllTemplate(cmsCreateReferralPage)}>
										{cmsCreateReferralPage['Referrals.CreateReferral.Donwloadthetemplate']}
									</span>
								</p>
							</div>
						</div>
					</div>
					<div className="create-referral__right col-xs-12 col-sm-12 col-md-12 col-lg-6 col-centered p-4 p-lg-0">
						<div className="col-12 col-lg-10 col-xl-10 mt-md-2 mt-lg-5 mb-lg-5">
							<DragDropFile
								cmsLabels={cmsCreateReferralPage}
								uploadedFile={handleAttachment}
								resetAttachment={resetAttachment}
								errorInFile={errorInFile}
								showError={showError}
							/>
						</div>

						{showError && (
							<ErrorBox
								sizing={'col-12 col-md-12 col-lg-10 col-xl-10 mt-4 mt-lg-0 mb-3 errorBox'}
								errorTitle={errorContent?.Title}
								enableBtn={true}
								btnLabel={'Download referral template'}
								errorDesc={errorContent.Phrase}>
								<div className="dropdown template-dropdown">
									<button
										className="btn btn-primary errbutton rounded-pill rounded-pill"
										type="button"
										id="downloadReferralDropdown"
										aria-expanded="false"
										onClick={() =>
											downloadTemplate(
												cmsCreateReferralPage.templates['Referrals.CreateReferrals.GlobalTempalte'].OtherMediaUrl,
												cmsCreateReferralPage.templates['Referrals.CreateReferrals.GlobalTempalte'].Phrase
											)
										}>
										{cmsCreateReferralPage['Referrals.CreateReferral.DownloadReferralTemplate']}
									</button>
									{/* <ul className="dropdown-menu" aria-labelledby="downloadReferralDropdown">
										{Object.keys(cmsCreateReferralPage.templates).map(key => (
											<li key={key}>
												<a
													className="dropdown-item"
													id={cmsCreateReferralPage.templates[key].Phrase.replaceAll(' ', '_')}
													onClick={() =>
														downloadTemplate(
															cmsCreateReferralPage.templates[key].OtherMediaUrl,
															cmsCreateReferralPage.templates[key].Phrase
														)
													}>
													{cmsCreateReferralPage.templates[key].Phrase}
												</a>
											</li>
										))}
									</ul> */}
								</div>
							</ErrorBox>
						)}
						{!showError && attachedFile && (
							<div className="col-12 col-lg-10 mt-5 p-2 create-referral__uploaded-files">
								<p>{cmsCreateReferralPage['Referrals.CreateReferral.UploadedFiles']}</p>
								<ul className="p-0" role="list">
									<li className="listOfFiles" role="listitem">
										{attachedFile.name}
										<img
											className="img-fluid removeAttachment"
											src={RemoveIcon}
											onClick={() => setResetAttachment(true)}
										/>
									</li>
								</ul>
							</div>
						)}
						<div className="col-12 col-lg-10 checkBoxSection mt-4 mt-md-2 mt-lg-5">
							<form>
								<label
									className="checkBoxLable"
									aria-label={cmsCreateReferralPage['Referrals.CreateReferral.ReferralWithoutTemplate']}>
									<input type="checkbox" checked={checked} className="cbInput" onChange={handleChange} />
									{cmsCreateReferralPage['Referrals.CreateReferral.ReferralWithoutTemplate']}
								</label>
								<div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-3">
									<p className="assistanceContent">
										{cmsCreateReferralPage['Referrals.CreateReferral.Assistance']}&nbsp;
										{/* <a
											href="#"
											onClick={() => navigate(cmsCreateReferralPage['Referrals.CreateReferral.GetInTouch']?.Url)}
											className="assistanceLink"
											id="getInTouchLink"
											target={cmsCreateReferralPage['Referrals.CreateReferral.GetInTouch']?.Target}
											alt={cmsCreateReferralPage['Referrals.CreateReferral.GetInTouch']?.AltText}>
											{cmsCreateReferralPage['Referrals.CreateReferral.GetInTouch']?.Text}
										</a> */}
										<Link
											className="assistanceLink"
											id="getInTouchLink"
											to={cmsCreateReferralPage['Referrals.CreateReferral.GetInTouch']?.Url}
											target={cmsCreateReferralPage['Referrals.CreateReferral.GetInTouch']?.Target}
											title={cmsCreateReferralPage['Referrals.CreateReferral.GetInTouch']?.AltText}
											alt={cmsCreateReferralPage['Referrals.CreateReferral.GetInTouch']?.AltText}
											role="link">
											{cmsCreateReferralPage['Referrals.CreateReferral.GetInTouch']?.Text}
										</Link>
									</p>
								</div>
								<div className="d-grid d-lg-block mb-2">
									<button
										type="button"
										tabIndex={0}
										data-testid="submitReferralBtn"
										name="submitReferral"
										id="submitReferralButton"
										className={`btn btn-primary confirmBtn rounded-pill ${submitBtnActive}`}
										onClick={() => submitReferral()}>
										{cmsCreateReferralPage['Common.ButtonText.Submit']}
									</button>
								</div>
								<div className="d-grid d-lg-none mb-2">
									<button
										type="button"
										data-testid="cancelReferralBtn"
										name="cancelReferral"
										id="cancelReferralMobButton"
										className={`btn btn-primary create-referral__cancel-btn rounded-pill`}
										onClick={() => cancelReferral()}>
										{cmsCreateReferralPage['Referrals.CreateReferral.CancelReferral']}
									</button>
								</div>
							</form>
						</div>
					</div>
				</main>
			)}
			<Modalpopup show={modalShow} modalSize={'md'}>
				<div className="modal modal-content">
					<div className="modal-header">
						<div className="modal-title">{modalTitle}</div>
					</div>
					<div className="modal-body">{modalBody}</div>
					<div className="modal-footer">
						<button
							type="button"
							id="modalConfirmButton"
							onClick={() => confirmReferral(modalType)}
							className="confirmBtn">
							{cmsCreateReferralPage['Common.ButtonText.Yes']}
						</button>
						<button type="button" id="modalCancelButton" onClick={() => setModalShow(false)} className="cancelBtn">
							{cmsCreateReferralPage['Common.ButtonText.No']}
						</button>
					</div>
				</div>
			</Modalpopup>
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage
	};
};

const mapDispatchToProps = dispatch => {
	return {
		switchLanguage: shortLang => dispatch(SwitchLanguage(shortLang))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReferral);
