//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

module.exports = {
	REF_USER_ID: '48CC4E81-8C62-4516-FAF8-08DA22A34165',
	REP_USER_ID: '5643D09A-2442-EE11-B8F0-0022488625B5',
	BRAND_ID: '83B1E45D-425B-475D-AD90-AE9533EAC21C',
	REFERRAL_PAGE_API_CMS: 'PageApi/GetPageDetails',
	REFERRAL_LIST_API: 'client-referral/referral/referrals',
	CREATE_REFERRAL_API: 'client-referral/referrals',
	CREATE_REFERRAL_DUPLICATE_FILE_API: 'client-referral/checkreferral',
	DOWNLOAD_REFERRAL_API: 'client-referral/referral/details',
	REPORTS_LIST_API: 'Reports-Resources/report/reportresource',
	DETAILS_REPORT_API: 'Reports-Resources/report/reportresource/details',
	DOWNLOAD_REPORT_API: 'Reports-Resources/report/reportresource/download',
	DOWNLOAD_PROGRAMDESCRIPTOR_API: 'client-referral/referral/details/DownloadFileData',
	NOTIFICATIONS_LIST: 'client-notification/notification/notifications',
	USERDASHBOARDLIST: 'User-Profile/clientAcccount',
	DASHBOARD_CONFIG: 'User-Profile/client',
	POWER_BI_API: 'Reports-Resources/powerbi',
	POWER_BI_WORKSPACE_ID: '30715c1e-8650-485c-9139-0ff7e5d03df8',
	GROUPA_REPORTID: 'f569a432-28f5-48b3-8b00-3476dcd12c45',
	GROUPB_REPORTID: '5abc0e72-282a-4d39-8853-184fcfd8cd46',
	HOME_REPORTID: 'd73ab2ce-c80e-491d-aa12-34123bba2c2c',
	ADD_ACTIVITY_API: 'client-notification/notification/addactivity',
	PROGRAM_DESCRIPTOR_API: 'Reports-Resources/report/getClientAccountResourceFolderById'
};
