import environments from '../components/common/environments/environments';
import HttpHelper from './HttpHelper';
class TrackActivity {
	constructor() {}
	async sendActivity(Type, CreatedBy, ItemId = '', Value) {
		const http = new HttpHelper();
		const payload = {
			Type,
			CreatedBy,
			ItemId,
			Value
		};
		const { data } = await http.post(environments.ADD_ACTIVITY_API, payload, 'application/json');
		return data;
	}
}

export default TrackActivity;
