import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HttpHelper from '../../../helpers/HttpHelper';
import environments from '../../common/environments/environments';
import InlineLoader from '../../common/inlineLoader';
import ResourceTile from '../resourceTile';
import Button from '../../common/button';
import SortSearch from '../sortSearch';
import './style.scss';
import TrackActivity from '../../../helpers/TrackActivity';
const get = require('get-value');
const Resources = ({ first, count, showLoadMore, showFilters, pageData }) => {
	const [resourceData, setResourceData] = useState(null),
		[searchedValue, setSearchedValue] = useState(null),
		[filteredValue, setFilteredValue] = useState(null),
		sortValue = useRef(null),
		resourceDataBackup = useRef(null),
		searchedDataBackup = useRef(null),
		[displayCount, setDisplayCount] = useState(count),
		userSelectedLanguage = useSelector(state => state?.userSlice?.userSelectedlanguage),
		http = new HttpHelper(),
		tracker = new TrackActivity(),
		navigate = useNavigate();
	let userId = window.sessionStorage.getItem('UserId');
	const payload = { categoryname: 'Default', pagename: 'Resources', language: userSelectedLanguage || 'en' };
	//console.log('pageData', pageData);
	const pagelabels = {};
	let labels = get(pageData, 'Components.AllResources.Header.Labels');
	//console.log('labels', labels);
	labels?.forEach(element => {
		pagelabels[element.Key] = element.Phrase;
	});
	//console.log('pagelabels', pagelabels);
	useEffect(() => {
		getData().catch(error => {
			console.warn(error); //eslint-disable-line
		});
	}, [userSelectedLanguage]);

	const getData = async () => {
		const { data } = await http.post(
			`Reports-Resources/thoughtleadershipresources/${userId}/${environments.BRAND_ID}/view`,
			payload,
			'application/json'
		);

		resourceDataBackup.current = data?.Responsemodel;
		setResourceData(data?.Responsemodel);
	};

	const filterResources = e => {
		if (e.target.value === '') {
			searchedValue ? searchResource(searchedValue) : setResourceData(resourceDataBackup.current);
			setDisplayCount(count);
			setFilteredValue(e.target.value);
			return;
		}

		const tempData = searchedValue ? [...searchedDataBackup.current] : [...resourceDataBackup.current];

		const filteredData = tempData.filter(
			resource => resource.ResourceType.toLowerCase() === e.target.value.toLowerCase()
		);
		setResourceData(filteredData);
		setDisplayCount(count);
		setFilteredValue(e.target.value);
	};

	const sortResources = e => {
		if (e.target.value === '') {
			sortValue.current = null;
			return;
		}
		const tempData = [...resourceData];
		const filteredData = sort(e.target.value, tempData);
		sortValue.current = e.target.value;
		setResourceData(filteredData);
	};

	const sort = (val, data) => {
		return val === '0'
			? data.sort((a, b) => Number(new Date(b.CreatedDate)) - Number(new Date(a.CreatedDate)))
			: data.sort((a, b) => Number(new Date(a.CreatedDate)) - Number(new Date(b.CreatedDate)));
	};

	const searchResource = searchTerm => {
		if (searchTerm === '') {
			setResourceData(resourceDataBackup.current);
			setSearchedValue(null);
			setFilteredValue('');
			return;
		}

		let filteredData = resourceDataBackup.current.filter(
			resource =>
				resource.Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
				resource.ShortDescription.toLowerCase().includes(searchTerm.toLowerCase())
		);
		filteredData = sortValue.current ? sort(sortValue.current, filteredData) : filteredData;
		setResourceData(filteredData);
		setDisplayCount(count);
		setSearchedValue(searchTerm);
		setFilteredValue('');
		searchedDataBackup.current = filteredData;
	};

	const navigateToResource = async resource => {
		tracker.sendActivity('ClientThoughtLeadView', userId, resource.Id, resource.Title);
		navigate('/resource-view/' + resource.Id + '/resource');
	};

	return (
		<>
			{resourceData ? (
				<>
					{showFilters && pageData && (
						<SortSearch
							filterResources={filterResources}
							sortResources={sortResources}
							searchResource={searchResource}
							filteredValue={filteredValue}
							pageData={pageData}
						/>
					)}
					{searchedValue && (
						<>
							<div className="filterd-resources-summary">
								{`${pagelabels['AllResourcesHeader.SearchResults'].replace('{number}', resourceData.length)}`}
								{<strong>{` '${searchedValue}'`}</strong>}
								{/* {resourceData.length} results for <strong>{`'${searchedValue}'`}</strong> */}
								<hr />
							</div>
						</>
					)}
					<div className="resources">
						<div className="row gx-5">
							{resourceData.slice(0, displayCount).map((resource, index) => {
								return first && index === 0 ? (
									<div className="col-12 mb-4 mb-md-0" key={`resource${index}`}>
										<ResourceTile resource={resource} first={first} onClick={() => navigateToResource(resource)} />
									</div>
								) : (
									<div
										className="col-12 col-md-12 col-lg-6 mb-4 mb-md-0"
										key={`resource${index}`}
										onClick={() => navigateToResource(resource)}>
										<ResourceTile resource={resource} />
									</div>
								);
							})}
						</div>
						{resourceData.length > displayCount && showLoadMore && (
							<div className="text-center mt-4 resources__load-more">
								<Button
									onClickHandler={() => {
										setDisplayCount(displayCount + 10);
									}}>
									Load More
								</Button>
							</div>
						)}
					</div>
				</>
			) : (
				<InlineLoader />
			)}
		</>
	);
};
export default Resources;
