//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import axios from 'axios';
import { handleError } from './constants';
class Interceptor {
	constructor() {}

	getInstanse = () => {
		let instanse = axios.create();
		instanse.interceptors.request.use(
			function (_request) {
				let accessToken = window.sessionStorage.getItem('AccessToken');
				if (accessToken !== '') {
					_request.headers['Authorization'] = `Bearer ${accessToken}`;
				}
				_request.headers['Content-Type'] = 'application/json';
				_request.headers['TokenType'] = 'ADB2C';
				// _request.headers['Cache-Control']='No-Store';
				return _request;
			},
			function (error) {
				return Promise.reject(error);
			}
		);

		instanse.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				handleError(error);
				return Promise.reject(error);
			}
		);

		return instanse;
	};
}

export default Interceptor;
