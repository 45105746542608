//-----------------------------------------------------------------------
// Copyright © 2023 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------
import React from 'react';
//import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
//import { ReferralsActions } from '../referrals/ReferralsActions';
import { Reset } from '../../store/slices/userSlice';
const ReferralSuccess = props => {
	//const [cmsCreateReferralPage, setCmsCreateReferralPage] = useState({});
	//const [pageLoader, setPageLoader] = useState(false);
	const navigate = useNavigate();
	// useEffect(() => {
	// 	//getReferralsPageContent();
	// }, []);

	// const getReferralsPageContent = async () => {
	// 	try {
	// 		setPageLoader(true);
	// 		let CmsCreateReferralPage = JSON.parse(JSON.stringify(cmsCreateReferralPage));
	// 		let cmsReferralsPagepayload = {
	// 			categoryname: 'Default',
	// 			pagename: 'Referrals',
	// 			language: props.userSelectedlanguage.toLowerCase() || 'en'
	// 		};
	// 		const res = await ReferralsActions.GetCMSRequestPageDetails(cmsReferralsPagepayload);
	// 		if (res.status === 200) {
	// 			//if (res) {
	// 			setPageLoader(false);
	// 			res?.data?.Pages?.map(x => {
	// 				//res?.Pages?.map(x => {
	// 				if (x.Key == 'CreateReferrals') {
	// 					CmsCreateReferralPage['Title'] = x.Title;
	// 					x.Components?.['Referrals.CreateReferral'].LabelWithTitle.map(y => {
	// 						CmsCreateReferralPage[y.Key] = y;
	// 					});
	// 					CmsCreateReferralPage['createNewBtn'] = x.PageUrl;
	// 				}
	// 			});
	// 			setCmsCreateReferralPage(CmsCreateReferralPage);
	// 		} else {
	// 			return [];
	// 		}
	// 	} catch (error) {
	// 		return [];
	// 	}
	// };
	return (
		<>
			{/* {pageLoader && (
				<div className="overlay">
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			)} */}
			{props.cmsCreateReferralPage && (
				<div className="col-md-12 mt-5">
					<div className="col-12 d-none d-lg-block">
						<h1 className="pageLabel" data-testid="ConfirmPage">
							{props?.cmsCreateReferralPage['Referrals.CreateReferral.ReferralSuccessfullSubmissionText']?.Title}
						</h1>
					</div>
					<div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-3 text-center text-lg-start">
						<p className="pageDescription">
							{props?.cmsCreateReferralPage['Referrals.CreateReferral.ReferralSuccessfullSubmissionText']?.Phrase}
						</p>
					</div>
					<div className="col-12">
						<div className="d-grid d-lg-block">
							<button
								type="button"
								data-testid="confirmEmailBtn"
								name="confirmEmail"
								className="btn btn-primary confirmBtn rounded-pill me-2 mb-2"
								onClick={() => navigate('/referrals')}>
								{props?.cmsCreateReferralPage['Common.ButtonText.Close']}
							</button>
							<button
								type="button"
								data-testid="confirmEmailBtn"
								name="confirmEmail"
								className="btn btn-primary defaultBtn rounded-pill mb-2"
								onClick={() => navigate('/create-referrals')}>
								{props?.cmsCreateReferralPage['Title']}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		isLangChanged: state.userSlice.isLangChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reset: () => dispatch(Reset())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralSuccess);
