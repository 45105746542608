import './style.scss';

const Label = ({ children, ...rest }) => {
	return (
		<label className="label" {...rest}>
			{children}
		</label>
	);
};

export default Label;
